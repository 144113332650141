import { ShippingRateTableArraySchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { DeliveryProfileSchema } from "@redotech/redo-model/integration/shopify/delivery-profile";
import { z } from "zod";

export const getRateTablesFromDeliveryProfile = {
  input: z.object({
    deliveryProfile: DeliveryProfileSchema,
    hasPackageProtection: z.boolean(),
    hasReturnCoverage: z.boolean(),
    hasCarrierServices: z.boolean(),
  }),
  output: ShippingRateTableArraySchema,
};
