import { Currency, getCurrencySymbol } from "@redotech/money/currencies";
import { useHandler } from "@redotech/react-util/hook";
import { DEFAULT_PRODUCT_TITLE } from "@redotech/redo-model/extended-warranty";
import {
  CollectionSettings,
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyOffering,
  ExtendedWarrantyPdpExperience,
  Team,
} from "@redotech/redo-model/team";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Card, CollapseSubsection } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput, LabelTheme } from "@redotech/redo-web/labeled-input";
import {
  minMaxValidator,
  Pricing,
  pricingDefault,
  pricingFormValues,
  pricingRulesConvertTeamToValue,
  pricingRulesConvertValueToTeam,
} from "@redotech/redo-web/settings-elements/dynamic-pricing";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputLines,
  InputTheme,
} from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider, listInput } from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import classNames from "classnames";
import { Fragment, memo, useContext } from "react";
import { TeamContext } from "../../team";
import { CollectionAutocomplete } from "../returns-package-protection/collections-autocomplete";
import {
  experienceDesignDefault,
  experienceDesignForm,
  experienceDesignTeamToFormValue,
} from "./experience-design";

export const extendedWarrantyOfferingForm = groupInput(
  {
    timeId: input<string>(),
    timeTitle: input<string>(),
    daysExtended: input<string>(),
    ...pricingFormValues,
  },
  { validator: minMaxValidator },
);

export const extendedWarrantyCollectionForm = groupInput({
  collectionHandle: input<string>(),
  collectionName: input<string>(),
  validOfferings: input<string[]>({ equal: arrayEqual(stringEqual) }),
  offerings: listInput(
    () => extendedWarrantyOfferingForm,
    () => ({
      timeId: "",
      timeTitle: "",
      daysExtended: "0",
      collectionHandle: "",
      collectionName: "",
      ...pricingDefault,
    }),
    (_, index) => index,
  ),
});

export const extendedWarrantySettingsForm = groupInput({
  enabled: input<boolean>(),
  variantSelector: input<string>(),
  validCollections: input<string[]>({ equal: arrayEqual(stringEqual) }),
  productTitle: input<string>(),
  extendedWarrantyRevSharePercentage: input<string>(),
  customPdpCss: input<string>(),
  customToggleCss: input<string>(),
  customModalCss: input<string>(),
  warrantyActivationDelay: input<string>(),
  experienceDesign: experienceDesignForm,
  collectionSettings: listInput(
    () => extendedWarrantyCollectionForm,
    () => ({
      collectionHandle: "",
      collectionName: "",
      validOfferings: [],
      offerings: [],
    }),
    (_, index) => index,
  ),
});

export type ExtendedWarrantyCollectionValue = InputProvider.Value<
  typeof extendedWarrantyCollectionForm
>;
export type ExtendedWarrantyCollectionForm = InputProvider.Form<
  typeof extendedWarrantyCollectionForm
>;

export type ExtendedWarrantySettingsForm = InputProvider.Form<
  typeof extendedWarrantySettingsForm
>;

export type ExtendedWarrantySettingsValue = InputProvider.Value<
  typeof extendedWarrantySettingsForm
>;

export const extendedWarrantySettingsDefault = {
  enabled: false,
  extendedWarrantyRevSharePercentage: "0",
  variantSelector: "",
  validCollections: [],
  productTitle: "",
  collectionSettings: [],
  customPdpCss: "",
  customToggleCss: "",
  customModalCss: "",
  warrantyActivationDelay: "0",
  experienceDesign: experienceDesignDefault,
};

export function extendedWarrantyTeamToFormValue(
  team?: Team,
): ExtendedWarrantySettingsValue {
  return {
    enabled: team?.settings.extendedWarranties?.enabled || false,
    variantSelector: team?.settings.extendedWarranties?.variantSelector || "",
    validCollections: team?.settings.extendedWarranties?.validCollections || [],
    productTitle: team?.settings.extendedWarranties?.productTitle || "",
    customPdpCss: team?.settings.extendedWarranties?.customPdpCss || "",
    customToggleCss: team?.settings.extendedWarranties?.customToggleCss || "",
    customModalCss: team?.settings.extendedWarranties?.customModalCss || "",
    extendedWarrantyRevSharePercentage: team?.settings.extendedWarranties
      ?.revSharePercentage
      ? String(team?.settings.extendedWarranties?.revSharePercentage * 100)
      : "0",
    warrantyActivationDelay: String(
      team?.settings.extendedWarranties?.warrantyActivationDelay || 0,
    ),
    experienceDesign: experienceDesignTeamToFormValue(team),
    collectionSettings:
      team?.settings.extendedWarranties?.collectionSettings?.map(
        (collectionSettings) => ({
          collectionHandle: collectionSettings.collectionHandle,
          collectionName: collectionSettings.collectionName || "",
          validOfferings: collectionSettings.validOfferings || [],
          offerings: collectionSettings.offerings.map((offering) => ({
            timeId: offering.timeId,
            daysExtended: offering.daysExtended
              ? String(offering.daysExtended)
              : "0",
            timeTitle: offering.timeTitle,
            minPrice: offering.minPrice,
            maxPrice: offering.maxPrice,
            percentage: offering.percentage,
            internationalPricingEnabled: offering.internationalPricingEnabled,
            pricingRuleSet: pricingRulesConvertTeamToValue([
              offering.pricingRuleSet,
            ]),
          })),
        }),
      ) || [],
  };
}

const LIFETIME_DAYS = 36500;

export function extendedWarrantyFormValueToTeam(
  extendedWarranties: ExtendedWarrantySettingsValue,
) {
  return {
    enabled: extendedWarranties.enabled,
    variantSelector: extendedWarranties.variantSelector || undefined,
    validCollections: extendedWarranties.validCollections,
    productTitle: extendedWarranties.productTitle || undefined,
    warrantyActivationDelay: Number(extendedWarranties.warrantyActivationDelay),
    revSharePercentage:
      Number(extendedWarranties.extendedWarrantyRevSharePercentage) / 100,
    collectionSettings: extendedWarranties.collectionSettings.map(
      (collectionSettings) => ({
        ...collectionSettings,
        offerings: collectionSettings.offerings.map((offering) => ({
          ...offering,
          daysExtended:
            offering.timeId === "lifetime"
              ? LIFETIME_DAYS
              : parseInt(offering.daysExtended),
          pricingRuleSet: pricingRulesConvertValueToTeam(
            offering.pricingRuleSet,
          )?.[0],
        })) as ExtendedWarrantyOffering[],
      }),
    ) as CollectionSettings[],
    customPdpCss: extendedWarranties.customPdpCss || undefined,
    customToggleCss: extendedWarranties.customToggleCss || undefined,
    customModalCss: extendedWarranties.customModalCss || undefined,
    logo: extendedWarranties.experienceDesign.logo
      ? extendedWarranties.experienceDesign.logo.toString()
      : "",
    sideImage: extendedWarranties.experienceDesign.sideImage
      ? extendedWarranties.experienceDesign.sideImage.toString()
      : "",
    modalTitle: extendedWarranties.experienceDesign.modalTitle || undefined,
    modalCoverageHeader:
      extendedWarranties.experienceDesign.modalCoverageHeader || undefined,
    modalDescription:
      extendedWarranties.experienceDesign.modalDescription || undefined,
    modalBulletPoints: extendedWarranties.experienceDesign.modalBulletPoints
      ? extendedWarranties.experienceDesign.modalBulletPoints.split("\n")
      : undefined,
    modalPlanHeader:
      extendedWarranties.experienceDesign.modalPlanHeader || undefined,
    pdpExperience: extendedWarranties.experienceDesign
      .pdpExperience as ExtendedWarrantyPdpExperience,
    pdpTitle: extendedWarranties.experienceDesign.pdpTitle || undefined,
    pdpLabel: extendedWarranties.experienceDesign.pdpLabel || undefined,
    pdpDescription:
      extendedWarranties.experienceDesign.pdpDescription || undefined,
    pdpAddToCartDescription:
      extendedWarranties.experienceDesign.pdpAddToCartDescription || undefined,
    pdpAddedToCartTitle:
      extendedWarranties.experienceDesign.pdpAddedToCartTitle || undefined,
    pdpAddedToCartDescription:
      extendedWarranties.experienceDesign.pdpAddedToCartDescription ||
      undefined,
    cartToggleExperience: extendedWarranties.experienceDesign
      .cartToggleExperience as ExtendedWarrantyCartToggleExperience,
    cartToggleModalNotAddedDescription:
      extendedWarranties.experienceDesign.cartToggleModalNotAddedDescription ||
      undefined,
    cartToggleModalAddedDescription:
      extendedWarranties.experienceDesign.cartToggleModalAddedDescription ||
      undefined,
    cartToggleAddedDescription:
      extendedWarranties.experienceDesign.cartToggleAddedDescription ||
      undefined,
    cartToggleNotAddedDescription:
      extendedWarranties.experienceDesign.cartToggleNotAddedDescription ||
      undefined,
  };
}

export const ExtendedWarranties = memo(function ExtendedWarranties({
  input,
}: {
  input: ExtendedWarrantySettingsForm;
}) {
  const team = useContext(TeamContext);
  const hasSufficientAccess = team?.accessScope.includes(
    "write_cart_transforms",
  );

  const {
    enabled,
    extendedWarrantyRevSharePercentage,
    validCollections,
    collectionSettings,
    warrantyActivationDelay,
    customModalCss,
    variantSelector,
    customPdpCss,
    customToggleCss,
    productTitle,
  } = input.inputs;

  const setValue = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      input.inputs.validCollections.setValue(
        collections.map((collection) => collection.handle),
      );
      input.inputs.collectionSettings.setValue(
        collections.map((collection) => ({
          collectionHandle: collection.handle,
          collectionName: collection.title,
          validOfferings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.validOfferings || [],
          offerings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.offerings || [],
        })),
      );
    },
  );

  return (
    <Card title="Extended Warranties">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            disabled={!hasSufficientAccess}
            input={enabled}
            label="Coverage Enabled"
          >
            Enable customers to buy extended warranties
          </FormSwitch>
          {!hasSufficientAccess && (
            <Text fontSize="sm" pt="sm">
              The team does not have sufficient access to enable extended
              warranties. Please ask an admin to update the team's permissions
              through the merchant portal.
            </Text>
          )}
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormTextInput
            description="Percentage of total extended warranty purchase price that Redo keeps. Don't touch this unless you have talked to Dan."
            input={extendedWarrantyRevSharePercentage}
            label="Extended warranty rev share percentage"
            max={100}
            min={0}
            step={1}
            suffix="%"
            type="number"
          />
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormTextInput
            description="This is the title of the extended warranty product that will be created on the store."
            input={productTitle}
            label="Product title"
            placeholder={DEFAULT_PRODUCT_TITLE}
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Coverage will be offered for these Shopify collections. The first 100 collections are shown by default. To access more, search by title."
            label="Included collections"
          >
            <CollectionAutocomplete
              keyFn={(collection) => collection.handle}
              setValueCollections={setValue}
              value={validCollections.value}
            />
          </LabeledInput>
        </div>
        <div className={gridCss.span12}>
          <CollapseSubsection title="Collection settings">
            <FormTextInput
              description="The number of days that the manufacturer's warranty lasts."
              input={warrantyActivationDelay}
              label="Manufacturer's warranty length"
              min={0}
              step={1}
              suffix="days"
              type="number"
            />
            {collectionSettings.value.map((collectionSettings, index) => (
              <ExtendedWarrantiesCollection
                input={input.inputs.collectionSettings.inputs[index]}
                key={collectionSettings.collectionHandle}
              />
            ))}
          </CollapseSubsection>
        </div>
        <div className={gridCss.span12}>
          <CollapseSubsection title="Advanced options">
            <FormTextInput
              description="You can use %variant_id% as placeholder."
              input={variantSelector}
              label="Variant selector for the extended warranty toggle."
            />
            <FormTextInput
              input={customPdpCss}
              label="Custom PDP CSS"
              lines={InputLines.MULTI}
              theme={InputTheme.FORM}
            />
            <FormTextInput
              input={customToggleCss}
              label="Custom Toggle CSS"
              lines={InputLines.MULTI}
              theme={InputTheme.FORM}
            />
            <FormTextInput
              input={customModalCss}
              label="Custom Modal CSS"
              lines={InputLines.MULTI}
              theme={InputTheme.FORM}
            />
          </CollapseSubsection>
        </div>
      </section>
    </Card>
  );
});

export const ExtendedWarrantiesCollection = memo(
  function ExtendedWarrantiesCollection({
    input,
  }: {
    input: ExtendedWarrantyCollectionForm;
  }) {
    const tileOptions: { id: string; title: string; handle: string }[] = [];
    for (let i = 1; i <= 10; i++) {
      tileOptions.push({
        id: i.toString(),
        title: `${i} Year`,
        handle: i.toString(),
      });
    }
    tileOptions.push({ id: "lifetime", title: "Lifetime", handle: "lifetime" });
    const team = useContext(TeamContext);
    const selectedTileOptions = tileOptions.filter((option) =>
      input.value.offerings.some((offering) => offering.timeId === option.id),
    );

    const handleValueChange = useHandler(
      (tileOptions: { id: string; title: string; handle: string }[]) => {
        input.inputs.validOfferings.setValue(
          tileOptions.map((option) => option.id),
        );
        input.inputs.offerings.setValue(
          tileOptions.map((option, index) => ({
            timeId: option.id,
            daysExtended:
              option.id === "lifetime"
                ? String(LIFETIME_DAYS)
                : String(parseInt(option.id) * 365),
            timeTitle: option.title,
            collectionHandle: input.value.collectionHandle,
            collectionName: input.value.collectionName,
            ...(input.inputs.offerings.value.some(
              (offering) => offering.timeId === option.id,
            )
              ? input.inputs.offerings.inputs[index].value
              : pricingDefault),
          })),
        );
      },
    );

    return (
      <>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>{input.value.collectionName}</strong>
        </div>

        <div className={gridCss.span12}>
          <LabeledInput
            description="These are the tiles offered to customers."
            label={`Warranty offerings for ${input.value.collectionName}`}
          >
            <Autocomplete
              getLabel={(option) => option.title}
              keyFn={(option) => option.handle}
              multiple
              options={tileOptions}
              value={selectedTileOptions}
              valueChange={handleValueChange}
            >
              {(option) => option.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        {input.value.offerings.map((offering, index) => (
          <Fragment key={offering.timeTitle}>
            <div className={gridCss.span12}>
              <Divider />
            </div>
            <div className={gridCss.span12}>
              <strong>{offering.timeTitle}</strong>
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                disabled={offering.timeId === "lifetime"}
                input={input.inputs.offerings.inputs[index].inputs.daysExtended}
                label="Days extended"
                labelTheme={LabelTheme.THIN}
                min={0}
                step={1}
                type="number"
              />
            </div>
            <div className={gridCss.span12}>
              <Pricing
                adminApp
                canEditSettings
                currencyDisplay={getCurrencySymbol(
                  team?._shopify.currency || Currency.USD,
                )}
                input={input.inputs.offerings.inputs[index]}
              />
            </div>
          </Fragment>
        ))}
      </>
    );
  },
);
