// extracted by mini-css-extract-plugin
export var active = "_1z-a";
export var activeIndicator = "_1z-9";
export var disabled = "_1z-b";
export var fullWidth = "_1z-2";
export var lg = "_1z-7";
export var md = "_1z-6";
export var redoButtonTabGroup = "_1z-0";
export var sm = "_1z-5";
export var tabButton = "_1z-4";
export var tabButtonsContainer = "_1z-1";
export var themeDestructive = "_1z-d";
export var themeNormal = "_1z-c";
export var themeSuccess = "_1z-e";
export var xl = "_1z-8";
export var xs = "_1z-3";