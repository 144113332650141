var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PropTypes from 'prop-types';
import { CALENDAR_TYPES, DEPRECATED_CALENDAR_TYPES } from './const.js';
var calendarTypes = Object.values(CALENDAR_TYPES);
var deprecatedCalendarTypes = Object.values(DEPRECATED_CALENDAR_TYPES);
var allViews = ['century', 'decade', 'year', 'month'];
export var isCalendarType = PropTypes.oneOf(__spreadArray(__spreadArray([], calendarTypes, true), deprecatedCalendarTypes, true));
export var isClassName = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
]);
export var isMinDate = function isMinDate(props, propName, componentName) {
    var _a = props, _b = propName, minDate = _a[_b];
    if (!minDate) {
        return null;
    }
    if (!(minDate instanceof Date)) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof minDate, "` supplied to `").concat(componentName, "`, expected instance of `Date`."));
    }
    var maxDate = props.maxDate;
    if (maxDate && minDate > maxDate) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof minDate, "` supplied to `").concat(componentName, "`, minDate cannot be larger than maxDate."));
    }
    return null;
};
export var isMaxDate = function isMaxDate(props, propName, componentName) {
    var _a = props, _b = propName, maxDate = _a[_b];
    if (!maxDate) {
        return null;
    }
    if (!(maxDate instanceof Date)) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof maxDate, "` supplied to `").concat(componentName, "`, expected instance of `Date`."));
    }
    var minDate = props.minDate;
    if (minDate && maxDate < minDate) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof maxDate, "` supplied to `").concat(componentName, "`, maxDate cannot be smaller than minDate."));
    }
    return null;
};
export var isRef = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.exact({
        current: PropTypes.any,
    }),
]);
var isRange = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.oneOf([null])]).isRequired);
export var isValue = PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null]),
    isRange,
]);
export var isViews = PropTypes.arrayOf(PropTypes.oneOf(allViews));
export var isView = function isView(props, propName, componentName) {
    var _a = props, _b = propName, view = _a[_b];
    if (view !== undefined && (typeof view !== 'string' || allViews.indexOf(view) === -1)) {
        return new Error("Invalid prop `".concat(propName, "` of value `").concat(view, "` supplied to `").concat(componentName, "`, expected one of [").concat(allViews
            .map(function (a) { return "\"".concat(a, "\""); })
            .join(', '), "]."));
    }
    // Everything is fine
    return null;
};
isView.isRequired = function isViewIsRequired(props, propName, componentName, location, propFullName) {
    var _a = props, _b = propName, view = _a[_b];
    if (!view) {
        return new Error("The prop `".concat(propName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(view, "`."));
    }
    return isView(props, propName, componentName, location, propFullName);
};
export var rangeOf = function (type) {
    return PropTypes.arrayOf(type);
};
export var tileGroupProps = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    hover: PropTypes.instanceOf(Date),
    locale: PropTypes.string,
    maxDate: isMaxDate,
    minDate: isMinDate,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    tileClassName: PropTypes.oneOfType([PropTypes.func, isClassName]),
    tileContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    value: isValue,
    valueType: PropTypes.oneOf(['century', 'decade', 'year', 'month', 'day']).isRequired,
};
export var tileProps = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    classes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string,
    maxDate: isMaxDate,
    minDate: isMinDate,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    tileClassName: PropTypes.oneOfType([PropTypes.func, isClassName]),
    tileContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    tileDisabled: PropTypes.func,
};
