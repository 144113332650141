type ReplacementMap = { [key: string]: RegExp };

interface PasteHelpers {
  expression: RegExp;
  extract(regexMatches: string[]): string;
}

export interface InputMask {
  mask: string;
  replacementMap: ReplacementMap;
  pasteHelpers?: PasteHelpers[];
}

export function getMaskedOutput({
  newInput,
  mask,
}: {
  newInput: string;
  mask: InputMask | undefined;
}): string {
  if (!mask) {
    return newInput;
  }
  let valueToMask = newInput;
  if (mask.pasteHelpers) {
    const pasteMatch = checkForPasteMatch(valueToMask, mask.pasteHelpers);
    if (pasteMatch) {
      valueToMask = pasteMatch;
    }
  }
  return runMask(valueToMask, mask);
}

function runMask(value: string, { mask, replacementMap }: InputMask) {
  let maskedValue = "";
  let maskIndex = 0;
  let valueIndex = 0;

  while (maskIndex < mask.length && valueIndex < value.length) {
    if (mask[maskIndex]! in replacementMap) {
      if (replacementMap[mask[maskIndex]!]!.test(value[valueIndex]!)) {
        maskedValue += value[valueIndex];
        valueIndex++;
      } else {
        break;
      }
    } else {
      maskedValue += mask[maskIndex];
      if (mask[maskIndex] === value[valueIndex]) {
        valueIndex++;
      }
    }
    maskIndex++;
  }
  return maskedValue;
}

function checkForPasteMatch(
  value: string,
  pasteHelpers: PasteHelpers[],
): string | undefined {
  for (const helper of pasteHelpers) {
    const matches = value.match(helper.expression);
    if (matches) {
      return helper.extract(matches);
    }
  }
  return undefined;
}
