import { Currency } from "@redotech/money/currencies";

export const DEFAULT_CURRENCY = Currency.USD;

export const CURRENCY_FORMAT = (currencyCode?: string | null) => {
  if (!currencyCode) {
    currencyCode = DEFAULT_CURRENCY;
  }
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
  });
};
