import { BalanceTypes } from "@redotech/redo-model/balance/balances";
import { z } from "zod";

export const addBalanceCredit = {
  input: z.object({
    amount: z.number(),
    account: z.nativeEnum(BalanceTypes),
    teamId: z.string(),
  }),
  output: z.object({ balance: z.string() }),
};
