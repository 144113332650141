// extracted by mini-css-extract-plugin
export var box = "_1x-0";
export var check = "_1x-1";
export var checkboxButton = "_1x-6";
export var checkboxGroup = "_1x-5";
export var disabled = "_1x-7";
export var ghost = "_1x-9";
export var input = "_1x-2";
export var label = "_1x-4";
export var normal = "_1x-8";
export var uncheck = "_1x-3";