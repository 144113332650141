import * as classNames from "classnames";
import { LabelHTMLAttributes, memo, ReactNode } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as redoTextInputCss from "./base-redo-input.module.css";
import {
  RedoInputSize,
  RedoInputState,
  sizeStyles,
  sizeToDescriptorTextProps,
  sizeToPx,
} from "./base-redo-text-input";

export interface BaseRedoInputContainerProps {
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: ReactNode;
  className?: string;
  children: ReactNode | ReactNode[];
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  fullWidth?: boolean;
  dynamicHeight?: boolean;
}

export const BaseRedoInputContainer = memo(function BaseRedoInputContainer({
  size = RedoInputSize.SMALL,
  state = RedoInputState.DEFAULT,
  label,
  description,
  className,
  labelProps,
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
  children,
  fullWidth,
  dynamicHeight,
}: BaseRedoInputContainerProps) {
  const descriptorFontSize = sizeToDescriptorTextProps[size];

  const error = state === RedoInputState.ERROR;
  const disabled = state === RedoInputState.DISABLED;
  const readonly = state === RedoInputState.READONLY;

  return (
    <Flex dir="column" gap="sm" minW="none" w={fullWidth ? "full" : undefined}>
      {label && (
        <Text
          fontSize={descriptorFontSize}
          fontWeight="medium"
          textColor="secondary"
        >
          {label}
        </Text>
      )}
      <Flex
        align="center"
        as="label"
        bgColor="primary"
        className={classNames(
          disabled && redoTextInputCss.disabled,
          redoTextInputCss.inputWrapper,
          error && redoTextInputCss.error,
          !dynamicHeight && sizeStyles[size],
          readonly && redoTextInputCss.readonly,
          className,
        )}
        labelProps={labelProps}
        px={readonly ? undefined : sizeToPx[size]}
        style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
        textColor="primary"
      >
        {children}
      </Flex>
      {description && (
        <Text
          fontSize={descriptorFontSize}
          textColor={error ? "error" : "tertiary"}
        >
          {description}
        </Text>
      )}
    </Flex>
  );
});
