// extracted by mini-css-extract-plugin
export var container = "_s-0";
export var error = "_s-9";
export var label = "_s-5";
export var light = "_s-6";
export var md = "_s-3";
export var sm = "_s-2";
export var thin = "_s-8";
export var thinBold = "_s-7";
export var vertical = "_s-4";
export var xs = "_s-1";