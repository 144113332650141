// extracted by mini-css-extract-plugin
export var box = "_x-1";
export var checked = "_x-5";
export var descriptionSpacer = "_x-9";
export var disabled = "_x-4";
export var icon = "_x-6";
export var input = "_x-0";
export var labelSpacer = "_x-8";
export var medium = "_x-3";
export var small = "_x-2";
export var text = "_x-7";