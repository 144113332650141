import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { getKey } from "@redotech/react-util/key";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  AxisKind,
  defaultNewReturnsRateTable,
  isReturnsWeightByZoneRateTable,
  RateTable,
  RateTableType,
  RateTableValueMode,
  ReturnsWeightByZoneRateTable,
  WeightUnit,
} from "@redotech/redo-model/rate-table/rate-table";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { memo, useEffect, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../../team";
import { LabelRateTableForm } from "../../common/rate-tables/rate-table";
import {
  rateCardForm,
  RateCardValue,
  rateTableToTableData,
  tableDataToMinMaxValues,
  tableDataToRateData,
  tableDataToZones,
} from "../../common/rate-tables/rate-table-utils";

export const LabelRatesCard = memo(function LabelRatesCard() {
  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoAdminRpcClientContext);
  // This is to load the rate table in the backend and transform it into the schema needed for the form
  const [rateTableLoad, triggerRateTableLoad] = useTriggerLoad<
    RateCardValue | undefined
  >(async () => {
    const tables = await client.getRateTables({ teamId: team._id });
    const filtered: ReturnsWeightByZoneRateTable | undefined =
      tables.rateTables.find((table: RateTable) =>
        isReturnsWeightByZoneRateTable(table),
      );
    return filtered
      ? { ...filtered, data: rateTableToTableData(filtered) }
      : undefined;
  });
  useEffect(() => {
    triggerRateTableLoad();
  }, [team, client]);

  return (
    <LabelRatesCardInternal
      key={getKey(rateTableLoad.value)}
      rateTableInDb={rateTableLoad.value}
      triggerReload={triggerRateTableLoad}
    />
  );
});

const LabelRatesCardInternal = memo(function LabelRatesCardInternal({
  rateTableInDb,
  triggerReload,
}: {
  rateTableInDb: RateCardValue | undefined;
  triggerReload: () => void;
}) {
  const [savePending, setSavePending] = useState(false);

  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoAdminRpcClientContext);

  const rateTableDataInput = useInput(
    rateCardForm,
    rateTableInDb ?? {
      ...defaultNewReturnsRateTable,
      data: rateTableToTableData(defaultNewReturnsRateTable),
    },
  );

  const addBottomRow = () => {
    const bottomRow =
      rateTableDataInput.value.data.at(-1)?.map((_) => "0") ?? [];
    rateTableDataInput.inputs.data.setValue([
      ...rateTableDataInput.value.data,
      bottomRow,
    ]);
  };

  const addRightColumn = () => {
    const newColumn =
      rateTableDataInput.value.data.map((row) => row.at(-1)).map((_) => "0") ??
      [];
    const newCharges = rateTableDataInput.value.data.map((row, index) => [
      ...row,
      newColumn.at(index) ?? "0",
    ]);
    rateTableDataInput.inputs.data.setValue(newCharges);
  };

  const deleteRow = (index: number) => {
    rateTableDataInput.inputs.data.setValue(
      rateTableDataInput.value.data.filter((_, i) => i !== index),
    );
  };

  const deleteColumn = (columnIndex: number) => {
    rateTableDataInput.inputs.data.setValue(
      rateTableDataInput.value.data.map((row) =>
        row.filter((_, i) => i !== columnIndex),
      ),
    );
  };

  const copyTable = () => {
    const data = rateTableDataInput.value.data.map((row) => [...row]);
    // Make sure we copy a blank cell for the upper left cell, it's useless
    data[0][0] = "";
    const excelData = data.map((row) => row.join("\t")).join("\n");
    void navigator.clipboard.writeText(excelData);
  };

  const save = async () => {
    setSavePending(true);
    const updatedRateTable: RateTable = {
      rateTableType: RateTableType.RETURNS_WEIGHT_BY_ZONE,
      valueMode: RateTableValueMode.Spread,
      data: tableDataToRateData(rateTableDataInput.value.data),
      xAxis: {
        values: tableDataToZones(rateTableDataInput.value.data),
        kind: AxisKind.ZONE,
      },
      yAxis: {
        values: tableDataToMinMaxValues(rateTableDataInput.value.data),
        kind: AxisKind.WEIGHT,
        units: WeightUnit.LB,
      },
      defaultValue: rateTableDataInput.value.defaultValue,
    };

    try {
      await client.saveRateTable({
        rateTable: updatedRateTable,
        teamId: team._id,
      });
    } catch (error) {
      toast("Failed to save rate table", { variant: "error" });
      console.error(error);
    } finally {
      setSavePending(false);
      triggerReload();
    }
  };

  return (
    <Card title="Merchant return label up-charge setting">
      <Flex justify="space-between" w="full">
        {rateTableDataInput.changed && (
          <RedoButton
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={save}
            pending={savePending}
            size={RedoButtonSize.LARGE}
            text="Save table"
            theme={RedoButtonTheme.NORMAL}
          />
        )}
        <RedoButton
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconLeading={CopyIcon}
          onClick={copyTable}
          size={RedoButtonSize.LARGE}
          text="Copy table"
          theme={RedoButtonTheme.NORMAL}
        />
        <RedoButton
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconLeading={PlusIcon}
          onClick={addRightColumn}
          size={RedoButtonSize.LARGE}
          text="Add column"
          theme={RedoButtonTheme.NORMAL}
        />
        <RedoButton
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconLeading={PlusIcon}
          onClick={addBottomRow}
          size={RedoButtonSize.LARGE}
          text="Add row"
          theme={RedoButtonTheme.NORMAL}
        />
      </Flex>
      <Flex dir="column">
        <Flex dir="row">
          <LabelRateTableForm
            deleteColumn={deleteColumn}
            deleteRow={deleteRow}
            input={rateTableDataInput}
          />
        </Flex>
        <Flex dir="column" pt="md" w="xs">
          <FormTextInput
            input={rateTableDataInput.inputs.defaultValue}
            label="Default value (used if no match is found in the table)"
            min={0}
            type="number"
          />
        </Flex>
      </Flex>
    </Card>
  );
});
