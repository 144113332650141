import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { useLayoutUpdateSubject } from "@redotech/react-util/observable";
import * as classnames from "classnames";
import * as classNames from "classnames";
import { useObservableState } from "observable-hooks";
import {
  createContext,
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useContext,
  useMemo,
} from "react";
import { Link, useMatches } from "react-router-dom";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import * as breadcrumbCss from "./breadcrumb.module.css";
import { Flex } from "./flex";
import ChevronDown from "./icon-old/chevron-down.svg";
import { Text } from "./text";

export interface Breadcrumb {
  id: string;
  name: string;
  url: string | null;
}

export type BreadcrumbOverriders = Map<
  string,
  (breadcrumb: Breadcrumb) => Breadcrumb
>;
export const BreadcrumbOverridersContext = createContext<
  [BreadcrumbOverriders, Dispatch<SetStateAction<BreadcrumbOverriders>>]
>([new Map(), () => {}]);

const BREADCRUMB_DEFAULT = "";

export function breadcrumbSlot(): BreadcrumbSlot {
  return new BehaviorSubject(BREADCRUMB_DEFAULT);
}

export type BreadcrumbSlot = Subject<string>;

export function useBreadcrumb(
  breadcrumb: BreadcrumbSlot,
  value: string | undefined,
) {
  useLayoutUpdateSubject(
    breadcrumb,
    value || BREADCRUMB_DEFAULT,
    BREADCRUMB_DEFAULT,
  );
}

export function useBreadcrumbs(): Breadcrumb[] | undefined {
  const matches = useMatches();
  const transitionState = useContext(OverlapTransitionStateContext);
  const [breadcrumbOverriders] = useContext(BreadcrumbOverridersContext);
  const breadcrumbs = useMemo(() => {
    const observables: Observable<Breadcrumb>[] = [];
    for (const match of matches) {
      const handle: any = match.handle;
      if (handle?.breadcrumb === undefined) {
        continue;
      }
      const name: Observable<string> =
        typeof handle.breadcrumb === "string"
          ? of(handle.breadcrumb)
          : handle.breadcrumb;
      const url =
        handle.breadcrumbNavigate !== false
          ? match.pathname.replace(/\/$/, "")
          : null;
      const id = match.id;

      observables.push(
        name.pipe(
          map((name) => {
            let breadcrumb = { id, name, url };
            breadcrumbOverriders?.forEach((overrider) => {
              breadcrumb = overrider(breadcrumb);
            });
            return breadcrumb;
          }),
        ),
      );
    }

    return combineLatest(observables);
  }, [matches, breadcrumbOverriders]);

  const observable = useObservableState(breadcrumbs);

  if (transitionState === OverlapTransitionState.EXIT) {
    return undefined;
  }

  return observable;
}

export interface BreadcrumbsProps {
  size?: "small" | "large";
}

export const Breadcrumbs = memo(function Breadcrumbs({
  size = "large",
}: BreadcrumbsProps) {
  const breadcrumbs = useBreadcrumbs();
  if (!breadcrumbs) {
    return null;
  }

  const className = classnames(breadcrumbCss.breadcrumbs, {
    [breadcrumbCss.small]: size === "small",
  });

  return (
    <Flex className={className} gap={size === "small" ? "none" : "xs"}>
      {breadcrumbs.map((breadcrumb, i) => (
        <Fragment key={breadcrumb.id}>
          {0 < i && <ChevronDown className={breadcrumbCss.breadcrumbArrow} />}
          {breadcrumb.url ? (
            <Link
              onClick={(e) => (e.target as HTMLElement).blur()}
              to={breadcrumb.url}
            >
              <BreadcrumbText
                isLast={i < breadcrumbs.length - 1}
                isLink
                text={breadcrumb.name}
              />
            </Link>
          ) : (
            <BreadcrumbText
              isLast={i < breadcrumbs.length - 1}
              text={breadcrumb.name}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
});

const BreadcrumbText = memo(function BreadcrumbText({
  text,
  isLast,
  isLink,
}: {
  text: string;
  isLast: boolean;
  isLink?: boolean;
}) {
  return (
    <Text
      className={classNames(breadcrumbCss.breadcrumb, {
        [breadcrumbCss.link]: isLink,
      })}
      fontSize="lg"
      {...(isLast
        ? { fontWeight: "medium", textColor: "placeholder" }
        : { fontWeight: "semibold", textColor: "primary" })}
    >
      {text}
    </Text>
  );
});
