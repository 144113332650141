import {
  DynamicReturnPricing,
  DynamicReturnTypes,
  PricingRuleSet,
} from "@redotech/redo-model/team";

// TODO: Refactor and combine with getPackageProtectionPrice
export function getFinalSaleReturnsPrice(
  cartTotal: number,
  pricingRuleSets: PricingRuleSet[] | undefined,
  country: string | undefined | null,
  maxPrice: number,
  backupPercentage: number,
) {
  if (
    country === undefined ||
    !maxPrice ||
    (!backupPercentage && !pricingRuleSets?.length) ||
    cartTotal === 0
  ) {
    return 0;
  }

  if (!pricingRuleSets?.length) {
    return Math.min(cartTotal * backupPercentage, maxPrice);
  }

  const ruleset = determineRuleSet(pricingRuleSets, country);
  if (ruleset) {
    const rule = getApplicableRule(ruleset, cartTotal);
    const price =
      ruleset.type === "percentage" ? rule.value * cartTotal : rule.value;
    return Math.min(price, maxPrice);
  }

  return parseFloat(
    Math.min(cartTotal * backupPercentage, maxPrice).toFixed(2),
  );
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function getPackageProtectionPrice(
  cartTotal: number,
  pricingRuleSets: PricingRuleSet[] | undefined,
  country: string | undefined | null,
  minPrice: number,
  maxPrice: number,
  backupPercentage: number,
) {
  if (
    country === undefined ||
    !maxPrice ||
    (!backupPercentage && !pricingRuleSets?.length)
  ) {
    return 0;
  }

  if (!pricingRuleSets?.length) {
    return clamp(cartTotal * backupPercentage, minPrice, maxPrice);
  }

  const ruleset = determineRuleSet(pricingRuleSets, country);
  if (ruleset) {
    const rule = getApplicableRule(ruleset, cartTotal);
    const price =
      ruleset.type === "percentage" ? rule.value * cartTotal : rule.value;
    return clamp(price, minPrice, maxPrice);
  }

  return parseFloat(
    clamp(cartTotal * backupPercentage, minPrice, maxPrice).toFixed(2),
  );
}

function determineRuleSet(
  pricingRuleSets: PricingRuleSet[],
  country: string | null,
) {
  for (const priceSet of pricingRuleSets) {
    if (
      country === null ||
      priceSet.countries.includes(country) ||
      priceSet.countries.length == 0
    ) {
      return priceSet;
    }
  }
  return null;
}

function getApplicableRule(ruleSet: PricingRuleSet, cartTotal: number) {
  if (!cartTotal) {
    return ruleSet.priceBrackets[0];
  }
  for (let i = 0; i < ruleSet.priceBrackets.length; i++) {
    if (i === ruleSet.priceBrackets.length - 1) {
      return ruleSet.priceBrackets[i];
    }

    if (
      cartTotal > ruleSet.priceBrackets[i].pricePoint &&
      cartTotal <= ruleSet.priceBrackets[i + 1].pricePoint
    ) {
      return ruleSet.priceBrackets[i];
    }
  }
  return ruleSet.priceBrackets[ruleSet.priceBrackets.length - 1];
}

export function calculateDynamicReturnsPrice(
  dynamicReturns: DynamicReturnPricing,
  basePrice: number,
  numItems: number,
  cartTotal: number,
) {
  if (numItems === 0) {
    return 0;
  }

  switch (dynamicReturns.type) {
    case DynamicReturnTypes.ITEM_COUNT:
      return parseFloat(
        Math.min(
          basePrice + dynamicReturns.pricePerAdditonalItem * (numItems - 1),
          dynamicReturns.maxPrice,
        ).toFixed(2),
      );
    case DynamicReturnTypes.CART_VALUE:
      return parseFloat(
        getPackageProtectionPrice(
          cartTotal,
          [dynamicReturns.pricingRuleSet],
          null,
          basePrice,
          dynamicReturns.maxPrice,
          0,
        ).toFixed(2),
      );
  }
}
