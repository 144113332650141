import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { REDO_OUTBOUND_CARRIER_IDS } from "@redotech/redo-model/rate-table/redo-carriers";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../app/auth";
import { RedoAdminRpcClientContext } from "../../app/redo-admin-rpc-client-provider";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { AddBalanceCreditCard } from "./outbound-labels/add-balance-credit";
import {
  BlockCarrierAccountsCard,
  carrierAccountDefault,
  carrierAccountForm,
} from "./outbound-labels/block-carrier-accounts";
import {
  CheckoutOutboundRatesCard,
  checkoutOutboundRatesDefault,
  checkoutOutboundRatesForm,
} from "./outbound-labels/checkout-outbound-rates";
import { OutboundLabelsGeneral } from "./outbound-labels/general";
import { OutboundLabelRateCardCard } from "./outbound-labels/rate-tables/rate-table-card";

export const OutboundLabelsPage = memo(function OutboundLabelsPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const settingsLoad = useLoad<OutboundLabelValue | undefined>(async () => {
    if (!team) {
      return;
    }

    const checkoutOutboundRates =
      await rpcClient.getCheckoutOutboundRatesSettings({ teamId });

    const blockedCarrierAccounts =
      team.settings.outboundLabels?.blockedCarrierAccounts ?? [];
    const carrierAccounts = {
      fedex: blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.FEDEX),
      fedexSmartpost: blockedCarrierAccounts.includes(
        REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST,
      ),
      ups: blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.REDO_UPS),
      easypostUSPS: blockedCarrierAccounts.includes(
        REDO_OUTBOUND_CARRIER_IDS.EASYPOST_USPS,
      ),
    };
    return {
      ...outboundLabelDefault,
      newOmsEnabled: team.settings.outboundLabels?.newOmsEnabled ?? false,
      blockedCarrierAccounts: carrierAccounts,
      checkoutOutboundRates: {
        checkoutOutboundRatesEnabled: checkoutOutboundRates.enabled,
        fulfillmentDelayDays:
          checkoutOutboundRates.fulfillmentDelayDays?.toString() ??
          checkoutOutboundRatesDefault.fulfillmentDelayDays,
      },
    };
  }, [team]);

  return (
    <OutboundLabelsForm
      id={teamId}
      initialValue={settingsLoad.value}
      key={+!team}
      onSave={handleSave}
    />
  );
});

export const outboundLabelForm = groupInput({
  newOmsEnabled: input<boolean>(),
  blockedCarrierAccounts: carrierAccountForm,
  checkoutOutboundRates: checkoutOutboundRatesForm,
});

export type OutboundLabelForm = InputProvider.Form<typeof outboundLabelForm>;

export type OutboundLabelValue = InputProvider.Value<typeof outboundLabelForm>;

export const outboundLabelDefault: OutboundLabelValue = {
  newOmsEnabled: false,
  blockedCarrierAccounts: carrierAccountDefault,
  checkoutOutboundRates: checkoutOutboundRatesDefault,
};

const OutboundLabelsForm = memo(function OutboundLabelsForm({
  onSave,
  id,
  initialValue,
}: {
  id: string;
  initialValue: OutboundLabelValue | undefined;
  onSave?: (value: OutboundLabelValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input = useInput(
    outboundLabelForm,
    initialValue || outboundLabelDefault,
  );
  const auth = useRequiredContext(AuthContext);
  const team = useRequiredContext(TeamContext);
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const carrierServicesLoad = useLoad(
    async (signal) => {
      return await rpcClient.hasCarrierServices({ teamId: id }, { signal });
    },
    [id],
  );

  const userCanSeeRateTables = useMemo(() => {
    const usersAllowedToSeeRateTables = [
      "joe@getredo.com",
      "paul@getredo.com",
      "jaden.sterner@getredo.com",
      "pj@getredo.com",
      "suz@getredo.com",
      "aaron@getredo.com",
      "patrick@getredo.com",
      "jackie@getredo.com",
      "ridge@getredo.com",
      "eric@getredo.com",
      "kash@getredo.com",
      "jordan@getredo.com",
      "samuel@getredo.com",
      "tyson.peterson@getredo.com",
      "hayden@getredo.com",
      "spencer@getredo.com",
    ];
    return usersAllowedToSeeRateTables.includes(auth.email);
  }, [auth]);

  useEffect(() => {
    if (initialValue) {
      input.setValue(initialValue);
    }
  }, [initialValue]);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });
    const value = input.value;

    const blockedCarrierAccounts: string[] = [];

    if (value.blockedCarrierAccounts.fedex) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.FEDEX);
    }

    if (value.blockedCarrierAccounts.fedexSmartpost) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST);
    }

    if (value.blockedCarrierAccounts.ups) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.REDO_UPS);
    }

    if (value.blockedCarrierAccounts.easypostUSPS) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.EASYPOST_USPS);
    }

    team.settings.outboundLabels = {
      ...team.settings.outboundLabels,
      newOmsEnabled: value.newOmsEnabled,
      blockedCarrierAccounts: blockedCarrierAccounts,
    };

    await updateTeam(client, { teamId: id, team, signal });

    await rpcClient.updateCheckoutOutboundRatesSettings({
      teamId: id,
      enabled: value.checkoutOutboundRates.checkoutOutboundRatesEnabled,
      fulfillmentDelayDays: Number(
        value.checkoutOutboundRates.fulfillmentDelayDays,
      ),
    });

    onSave && onSave(value);
  });

  return (
    <div className={teamCss.container}>
      <PageForm
        initial={initialValue || outboundLabelDefault}
        input={input}
        save={save}
      >
        <div className={gridCss.grid}>
          <div className={gridCss.span12}>
            {userCanSeeRateTables && (
              <div className={gridCss.span12}>
                <OutboundLabelRateCardCard />
              </div>
            )}
          </div>
        </div>
        <br />
        <div className={gridCss.grid}>
          <div className={gridCss.span4}>
            <OutboundLabelsGeneral input={input} />
            <br />
            <AddBalanceCreditCard team={team} />
            {carrierServicesLoad.value && (
              <>
                <br />
                <CheckoutOutboundRatesCard
                  input={input.inputs.checkoutOutboundRates}
                />
              </>
            )}
          </div>
          <div className={gridCss.span8}>
            <BlockCarrierAccountsCard input={input} />
          </div>
        </div>
      </PageForm>
    </div>
  );
});
