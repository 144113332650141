// extracted by mini-css-extract-plugin
export var background = "_29-0";
export var buttonPrimary = "_29-1";
export var container = "_29-2";
export var feature = "_29-5";
export var featureBody = "_29-6";
export var featureIcon = "_29-7";
export var featureSummary = "_29-9";
export var featureTitle = "_29-8";
export var modal = "_29-f";
export var modalAction = "_29-e";
export var modalCloseButton = "_29-d";
export var modalFeatures = "_29-4";
export var modalFooter = "_29-a";
export var modalImageLogo = "_29-b";
export var modalLogo = "_29-c";
export var redoModal = "_29-3";