import {
  CoverageProductEnum,
  DeliveryFilterType,
  DeliverySortType,
  ShippingRateTable,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { AttachmentStrategy } from "@redotech/redo-model/team";
import { Card } from "@redotech/redo-web/card";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import {
  groupInput,
  input,
  InputProvider,
  optionalInput,
} from "@redotech/ui/form";
import {
  arrayEqual,
  booleanEqual,
  objectEqual,
  optionalEqual,
  stringEqual,
} from "@redotech/util/equal";
import { memo, useState } from "react";
import { StorefrontForm } from "./storefront";

export const risForm = optionalInput(
  groupInput({
    enabled: input<boolean>(),
    coverage: input<CoverageProductEnum>(),
    rateTables: input<ShippingRateTable[]>({
      equal: arrayEqual(
        objectEqual<ShippingRateTable>({
          _id: stringEqual,
          name: stringEqual,
          description: stringEqual,
          code: stringEqual,
          type: stringEqual,
          originLocations: arrayEqual(
            objectEqual({
              country: stringEqual,
              provinces: arrayEqual(stringEqual),
            }),
          ),
          destinationLocations: arrayEqual(
            objectEqual({
              country: stringEqual,
              provinces: arrayEqual(stringEqual),
            }),
          ),
          coverageTypes: optionalEqual(
            objectEqual<{ return?: boolean; packageProtection?: boolean }>({
              return: optionalEqual(booleanEqual),
              packageProtection: optionalEqual(booleanEqual),
            }),
          ),
          rates: arrayEqual(
            objectEqual<{
              currency: string;
              conditions: { type: string }[];
              enabled?: boolean;
              merchantPaidCoverageTypes?: {
                return?: boolean;
                packageProtection?: boolean;
              };
              price: string;
            }>({
              conditions: arrayEqual(objectEqual({ type: stringEqual })), //TODO figure out union type 💀
              currency: stringEqual,
              enabled: optionalEqual(booleanEqual),
              merchantPaidCoverageTypes: optionalEqual(
                objectEqual<{ return?: boolean; packageProtection?: boolean }>({
                  return: optionalEqual(booleanEqual),
                  packageProtection: optionalEqual(booleanEqual),
                }),
              ),
              price: optionalEqual(stringEqual),
            }),
          ),
        }),
      ),
    }),
    deliveryFilterType: optionalInput(
      input<DeliveryFilterType>(),
      () => DeliveryFilterType.ONLY_CHEAPEST_NON_REDO,
    ),
    deliverySortType: optionalInput(
      input<DeliverySortType>(),
      () => DeliverySortType.REDO_CHEAPEST_FIRST,
    ),
  }),
  () => risDefault,
);

export type RisForm = InputProvider.Form<typeof risForm>;

export type RisFormValue = InputProvider.Value<typeof risForm>;
export const risDefault = {
  enabled: false,
  coverage: CoverageProductEnum.CHECKOUT,
  rateTables: [],
  deliveryFilterType: DeliveryFilterType.ONLY_REDO,
  deliverySortType: DeliverySortType.REDO_CHEAPEST_FIRST,
};

export const CoverageProduct = memo(function CoverageProduct({
  input,
  storefrontForm,
}: {
  input: RisForm;
  storefrontForm: StorefrontForm;
}) {
  const [prevAttachmentStrategy, _] = useState<AttachmentStrategy>(
    storefrontForm.inputs.attachmentStrategy.value,
  );
  const ris = input.input?.inputs;
  if (!ris) {
    return null;
  }
  const { coverage } = ris;

  return (
    <Card title="Coverage product">
      <FormSelectDropdown
        description="Placement in online store"
        input={coverage}
        label="Placement"
        options={Object.values(CoverageProductEnum)}
        placeholder="Store Front"
        valueChange={(value) => {
          if (value === CoverageProductEnum.SHIPPING) {
            storefrontForm.inputs.attachmentStrategy.setValue("shipping");
          } else {
            if (
              prevAttachmentStrategy === "shipping" ||
              prevAttachmentStrategy === "checkout-button-shipping"
            ) {
              storefrontForm.inputs.attachmentStrategy.setValue("check-out");
            } else {
              storefrontForm.inputs.attachmentStrategy.setValue(
                prevAttachmentStrategy,
              );
            }
          }
        }}
      >
        {(s) => s}
      </FormSelectDropdown>
    </Card>
  );
});
