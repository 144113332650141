import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import {
  ExtensionShopifyClient,
  ShopifyCart,
  ShopifyCartAttributes,
  ShopifyCartItem,
} from "@redotech/shopify-client/ajax";
import { httpResponseEvents } from "@redotech/web-util/http";
import * as isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { log } from "../log";
import { ExtensionShopifyClientContext } from "../shopify";

const isShopifyCartUrl = (url: string) =>
  /\/cart(\/|$)/i.test(url) &&
  !url.includes("api.getredo.com") &&
  !url.includes("localhost");

export function useCart(manualRefresh?: symbol) {
  const [cart, setCart] = useState<ShopifyCart | undefined>(undefined);
  const shopifyClient = useRequiredContext(ExtensionShopifyClientContext);
  const [cartRecheckSymbol, recheckCart] = useState(Symbol());
  const cartRecheck = useDebounce(cartRecheckSymbol, 500);

  useEffect(() => {
    if (!window.Shopify && window.Tapcart?.registerEventHandler) {
      window.Tapcart?.registerEventHandler("cart/updated", function () {
        recheckCart(Symbol());
      });
      return;
    } else {
      const subscription = httpResponseEvents.subscribe(({ request }) => {
        if (isShopifyCartUrl(request.url)) {
          recheckCart(Symbol());
        }
      });
      return () => subscription.unsubscribe();
    }
  }, []);

  useLoad(
    async (signal) => {
      for (let i = 0; i < 2; i++) {
        log("fetching cart");
        const newCart = await shopifyClient.cartGet(signal);
        if (!cart || !isEqual(cart, newCart)) {
          log("found new cart", { newCart });
          setCart(newCart);
          if (newCart.items.length) {
            break;
          }
        } else {
          log("cart unchanged", { cart: newCart });
        }
      }
    },
    [cartRecheck, manualRefresh],
  );

  return cart;
}

export async function safeCartAdd({
  client,
  items,
  params,
  retries = 0,
}: {
  client: ExtensionShopifyClient;
  items: { items: Partial<ShopifyCartItem>[] };
  params?: URLSearchParams;
  retries?: number;
}) {
  let attempts = 0;

  while (attempts <= retries) {
    try {
      return await client.cartAdd(items, params);
    } catch (e) {
      console.error(e);
      attempts += 1;
    }
  }

  console.error("failed to add redo product to cart");
  return;
}

export async function safeUpdateCartAttribute({
  client,
  attributes,
  retries = 0,
}: {
  client: ExtensionShopifyClient;
  attributes: ShopifyCartAttributes;
  retries?: number;
}) {
  let attempts = 0;

  while (attempts <= retries) {
    try {
      return await client.updateCartAttribute(attributes);
    } catch (e) {
      console.error(e);
      attempts += 1;
    }
  }

  console.error("failed to add opted in cart attribute");
  return;
}
