import {
  ComponentProps,
  ComponentType,
  ForwardRefRenderFunction,
  Key,
  ReactElement,
  forwardRef,
  memo,
} from "react";
import { getKey } from "./key";

export interface ComponentWrapper {
  (inner: ReactElement): ReactElement;
}

export function composeWrappers(
  ...wrappers: ComponentWrapper[]
): ComponentWrapper {
  return (inner) =>
    wrappers.reduceRight((result, wrapper) => wrapper(result), inner);
}

export function Component({ children }: { children(): ReactElement }) {
  return children();
}

export function IterableMap<T>({
  children,
  keyFn = getKey,
  items,
}: {
  children(item: T, index: number): ReactElement;
  keyFn?(item: T, index: number): Key;
  items: Iterable<T>;
}) {
  return (
    <>
      {Array.from(items, (item, index) => (
        <Component key={keyFn(item, index)}>
          {() => children(item, index)}
        </Component>
      ))}
    </>
  );
}

export function genericMemo<T extends ComponentType<any>>(
  Component: T,
  propsAreEqual?: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>,
  ) => boolean,
): T {
  return memo(Component, propsAreEqual) as any;
}

export function genericForwardRefMemo<
  T extends ForwardRefRenderFunction<any, any>,
>(Component: T): T {
  return memo(forwardRef(Component as any)) as any;
}
