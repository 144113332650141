import { z } from "zod";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export function isSortDirection(value: string): value is SortDirection {
  return (Object.values(SortDirection) as string[]).includes(value);
}

export interface TableSort<T extends string = string> {
  direction: SortDirection;
  key: T;
}

export const PaginationCursorSchema = z.object({
  nextToken: z.string(),
  previousToken: z.string(),
  page: z.number(),
});
export type PaginationCursor = z.infer<typeof PaginationCursorSchema>;
