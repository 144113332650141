import { ZodError } from "zod";
import {
  InferRpcDefinition,
  RpcClientDefinition,
  RpcDefinition,
} from "./definition";
import { ClientError, serializeError } from "./errors";
import { dehydrate, hydrate } from "./wire-protocol";

export type RpcClient<D extends RpcClientDefinition> = {
  [key in keyof D]: (
    input: D[key]["input"],
    options?: RpcRequestOptions,
  ) => Promise<D[key]["output"]>;
};

export type ClientOptions<D extends RpcDefinition> = {
  baseURL: URL;
  defaultMethod?: "GET" | "POST";
  headers?: Record<string, string>;
  onError?: (rpcName: keyof D, error: unknown) => Promise<void> | void;
};

export type RpcRequestOptions = {
  requestHeaders?: Record<string, string>;
  method?: "GET" | "POST";
  signal?: AbortSignal;
};

export function createRpcClient<D extends RpcDefinition>(
  def: D,
  { baseURL, defaultMethod = "POST", headers, onError }: ClientOptions<D>,
): RpcClient<InferRpcDefinition<D>> {
  const client = {} as RpcClient<InferRpcDefinition<D>>;
  for (const key in def) {
    const rpc = def[key];
    if (!rpc) {
      continue;
    }
    const url = new URL(
      `${baseURL.pathname.replace(/\/$/, "")}/${key}`,
      baseURL,
    );

    client[key] = async (params, options) => {
      try {
        let input;
        try {
          const zod = rpc.input.safeParse(params);
          if (!zod.success) {
            throw zod.error;
          }
          input = dehydrate({ input: zod.data });
        } catch (error) {
          throw new Error(
            `RPC Input Validation Error ${key}: ${serializeError(error)}`,
          );
        }
        const method = options?.method ?? defaultMethod;

        let response;
        if (method === "POST") {
          response = await fetch(url.toString(), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...headers,
              ...(options?.requestHeaders ?? {}),
            },
            body: JSON.stringify(input),
            signal: options?.signal,
          });
        } else if (method === "GET") {
          const getUrl = new URL(url);
          const params = inputToSearchParams(input);
          getUrl.search = params.toString();

          response = await fetch(getUrl.toString(), {
            method: "GET",
            headers: { ...headers, ...(options?.requestHeaders ?? {}) },
            signal: options?.signal,
          });
        } else {
          throw new Error(`Unsupported HTTP method ${method}`);
        }
        if (!response?.ok) {
          throw response;
        }
        const json: unknown = await response.json();
        if (!json || typeof json !== "object" || !("output" in json)) {
          throw new TypeError("Network response did not contain RPC output");
        }

        let output;
        try {
          const hydratedOutput = hydrate(json.output);
          const zod = rpc.output.safeParse(hydratedOutput);
          if (!zod.success) {
            throw zod.error;
          }
          output = zod.data;
        } catch (error) {
          throw new Error(
            `RPC Output Validation Error ${key}: ${serializeError(error)}`,
          );
        }

        return output;
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          throw new Error(`Request aborted for RPC method ${key}`);
          return; // Optionally return a default value or handle the abort case
        }

        await onError?.(key, error);
        if (error instanceof Response) {
          let message: string | undefined;
          let code: string | undefined;
          if (error.headers.get("Content-Type")?.includes("application/json")) {
            const json: unknown = await error.json();

            if (json && typeof json === "object") {
              const error = "error" in json ? String(json.error) : undefined;
              const m = "message" in json ? String(json.message) : undefined;
              code = "code" in json ? String(json.code) : undefined;
              message = error || m;
            }
          } else {
            message = await error.text();
          }
          throw new ClientError(error.status, message ?? "Unknown error", code);
        } else if (error instanceof ZodError) {
          throw new Error(
            `RPC Validation Error ${key}: ${serializeError(error)}`,
          );
        }
        // throw new Error(error.toString());
        throw error;
      }
    };
  }
  return client;
}

function inputToSearchParams(input: any): URLSearchParams {
  const searchParams = new URLSearchParams();
  if (typeof input?.input !== "object") {
    throw new Error("GET RPC input must be an object");
  }
  for (const key in input.input) {
    const value = input.input[key];
    if (value === undefined) {
      continue;
    }
    if (value === null) {
      searchParams.append(key, "");
    } else {
      searchParams.append(key, JSON.stringify(value));
    }
  }
  return searchParams;
}
