import { memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoCheckbox } from "./redo-checkbox";

export interface RedoCheckboxAdvancedProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  rightContent: React.ReactNode;
  checked: boolean;
  onClick: () => void;
}

export const RedoCheckboxAdvanced = memo(
  function RedoCheckboxCardAdvancedProps({
    title,
    checked,
    onClick,
    children,
    rightContent,
  }: RedoCheckboxAdvancedProps) {
    return (
      <Flex align="center" justify="space-between">
        <Flex gap="lg">
          <Flex align="center" justify="center">
            <RedoCheckbox label="" setValue={onClick} value={checked} />
          </Flex>
          <Flex align="flex-start" dir="column" flex={1} gap="xs">
            <Text fontSize="xs" fontWeight="bold">
              {title}
            </Text>
            <Text color="secondary" fontSize="xxs">
              {children}
            </Text>
          </Flex>
        </Flex>
        {rightContent}
      </Flex>
    );
  },
);
