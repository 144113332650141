// extracted by mini-css-extract-plugin
export var brand = "_f-9";
export var button = "_f-0";
export var chevron = "_f-v";
export var chevronButton = "_f-w";
export var content = "_f-n";
export var danger = "_f-g";
export var dangerOutlined = "_f-h";
export var dark = "_f-l";
export var darkTheme = "_f-c";
export var extraSmall = "_f-4";
export var fullWidth = "_f-8";
export var ghost = "_f-d";
export var gray = "_f-t";
export var icon = "_f-p";
export var iconButton = "_f-s";
export var iconButtonBorder = "_f-x";
export var iconButtonBorderDark = "_f-y";
export var iconButtonBorderLight = "_f-z";
export var iconLeft = "_f-q";
export var iconRight = "_f-r";
export var large = "_f-1";
export var light = "_f-m";
export var medium = "_f-2";
export var micro = "_f-5";
export var nano = "_f-7";
export var outlined = "_f-k";
export var pending = "_f-a";
export var primary = "_f-i";
export var shadow = "_f-o";
export var small = "_f-3";
export var solidLight = "_f-e";
export var solidLightBrand = "_f-f";
export var spinner = "_f-u";
export var transparent = "_f-j";
export var warning = "_f-b";
export var wideNano = "_f-6";