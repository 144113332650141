// extracted by mini-css-extract-plugin
export var arrows = "_1h-5";
export var decrementer = "_1h-9";
export var disabled = "_1h-3";
export var error = "_1h-1";
export var hiddenText = "_1h-a";
export var incrementer = "_1h-8";
export var input = "_1h-7";
export var inputWrapper = "_1h-0";
export var readonly = "_1h-2";
export var regular = "_1h-6";
export var small = "_1h-4";