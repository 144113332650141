import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { RedoClient } from "@redotech/redo-api-client";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import { FulfillmentDeliveryEstimate } from "@redotech/redo-model/estimate";
import { ConversationFileUploadError } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { ConversationFileUploadResponse } from "@redotech/redo-model/support/conversations/conversation-file-upload-response";
import { TeamInfo } from "@redotech/redo-model/team";
import { WidgetAutocheck, WidgetFees } from "@redotech/redo-model/widget";
import { AllCookies, getCookie } from "@redotech/redo-web/utils/cookies";
import { getLocalStorageWithExpiry } from "@redotech/redo-web/utils/local-storage-wrapper";
import { CUSTOMER_WIDGET_TOKEN_KEY } from "@redotech/redo-web/utils/shared-conf";
import { isEnumValue } from "@redotech/util/enum";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { AuthenticatedShopifyExtensionClient } from "./authenticated-shopify-extension-client";

export class ShopifyExtensionClient {
  constructor(
    private readonly redoClient: RedoClient,
    readonly widgetId?: string,
    readonly storeURL?: string,
  ) {
    if (!this.widgetId && !this.storeURL) {
      throw new Error(
        "Shopify Extension client needs either widgetId or Store URL",
      );
    }
  }

  get client(): AxiosInstance {
    return this.redoClient.client;
  }

  buildURL(path: string) {
    if (this.widgetId) {
      return `widgets/${encodeURIComponent(this.widgetId)}/${path}`;
    } else {
      return `widgets/${path}/?storeUrl=${this.storeURL}`;
    }
  }

  buildContactFormURL(path: string) {
    return `contact-form/${path}/?storeUrl=${this.storeURL}`;
  }
}

/**
 * GET /widgets/:widget_id/autocheck
 */
export async function getAutocheck(
  client: ShopifyExtensionClient,
  {
    signal,
    customerId,
    foreignCurrency,
    currencyExchangeRate,
    desiredTreatment,
  }: {
    signal?: AbortSignal;
    customerId: string | null;
    foreignCurrency?: string;
    currencyExchangeRate?: string;
    desiredTreatment?: string;
  },
): Promise<WidgetAutocheck> {
  const response = await client.client.get(client.buildURL("autocheck"), {
    signal,
    params: {
      customer_id: customerId,
      foreign_currency: foreignCurrency,
      currency_exchange_rate: currencyExchangeRate,
      desired_treatment: desiredTreatment,
    },
  });
  return response.data;
}

/**
 * GET /widgets/:widget_id/fees
 */
export async function getFees(
  client: ShopifyExtensionClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<WidgetFees> {
  const response = await client.client.get(client.buildURL("fees"), { signal });
  return response.data;
}

/**
 * GET /widgets/:widget_id/collections
 */
export async function getCollections(
  client: ShopifyExtensionClient,
  { signal, productId }: { signal?: AbortSignal; productId: string },
): Promise<{ title: string; handle: string }[]> {
  const response = await client.client.get(client.buildURL("collections"), {
    signal,
    params: { product_id: productId },
  });
  return response.data.collections;
}

/**
 * GET /widgets/estimate
 */
export async function getProductEstimate(
  client: ShopifyExtensionClient,
  {
    signal,
    productId,
    countryCode,
    provinceCode,
  }: {
    signal?: AbortSignal;
    productId?: string;
    countryCode?: string;
    provinceCode?: string;
  },
): Promise<FulfillmentDeliveryEstimate> {
  const response = await client.client.get(client.buildURL("estimate"), {
    signal,
    params: {
      product_id: productId,
      country_code: countryCode,
      province_code: provinceCode,
    },
  });
  return response.data;
}

/**
 * POST /widgets/:widget_id/selling-plan/:sellingPlanId
 */
export async function getSellingPlan(
  client: ShopifyExtensionClient,
  { signal, sellingPlanId }: { signal?: AbortSignal; sellingPlanId: number },
): Promise<any> {
  const response = await client.client.get(
    client.buildURL(`selling-plan/${sellingPlanId}`),
    { signal },
  );
  return response.data;
}

/**
 * POST /contact-form/submit
 */
export async function submitContactForm(
  client: AuthenticatedShopifyExtensionClient,
  {
    signal,
    formContents,
  }: {
    signal?: AbortSignal;
    formContents: {
      name: string;
      email: string;
      subject: string;
      phone: string;
      comment: string;
      attachments: Attachment[];
    };
  },
): Promise<AxiosResponse> {
  return await client.client.post(
    client.buildContactFormURL("submit"),
    formContents,
    { signal },
  );
}

/**
 * POST /contact-form/upload
 */
export async function uploadFile(
  client: ShopifyExtensionClient,
  params: FormData,
  { signal }: { signal?: AbortSignal } = {},
): Promise<
  | { success: true; body: ConversationFileUploadResponse }
  | { success: false; error: ConversationFileUploadError }
> {
  try {
    const response = await client.client.post(
      client.buildContactFormURL("upload"),
      params,
      { signal },
    );
    return {
      success: true,
      body: response.data as ConversationFileUploadResponse,
    };
  } catch (error) {
    if (
      error instanceof AxiosError &&
      isEnumValue(error.response?.data?.error, ConversationFileUploadError)
    ) {
      return { success: false, error: error.response?.data?.error };
    }
    throw error;
  }
}

/**
 * POST /user-session
 */
export async function trackUserSession(
  client: ShopifyExtensionClient,
): Promise<AxiosResponse> {
  const widgetAuthToken = getLocalStorageWithExpiry(CUSTOMER_WIDGET_TOKEN_KEY);
  const shopifySessionId = getCookie(AllCookies.ShopifySessionId);

  if (shopifySessionId === undefined) {
    throw new Error("Shopify session id is missing");
  }

  const url = client.buildURL("user-session");
  const body = { shopifySessionId };
  const options =
    widgetAuthToken !== null
      ? {
          headers: {
            [String(AuthorizationField.name)]:
              credentialsFieldValueFormat.write(
                bearerCredentialsFormat.write(widgetAuthToken),
              ),
          },
        }
      : undefined;

  return await client.client.post(url, body, options);
}

/**
 * GET /widgets/teamInfo
 */
export async function getTeamInfo(
  client: AuthenticatedShopifyExtensionClient,
): Promise<TeamInfo> {
  const response = await client.client.get(client.buildWidgetURL("teamInfo"));
  return response.data;
}
