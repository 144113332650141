// extracted by mini-css-extract-plugin
export var bubble = "_14-0";
export var container = "_14-3";
export var contrastBorder = "_14-4";
export var greyOut = "_14-2";
export var initialsWrapper = "_14-1";
export var large = "_14-9";
export var medium = "_14-8";
export var mediumSmall = "_14-7";
export var micro = "_14-5";
export var small = "_14-6";
export var tiny = "_14-b";
export var xTiny = "_14-a";