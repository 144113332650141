// extracted by mini-css-extract-plugin
export var brand = "_1y-4";
export var compact = "_1y-7";
export var extraSmall = "_1y-8";
export var icon = "_1y-0";
export var large = "_1y-1";
export var medium = "_1y-2";
export var outline = "_1y-9";
export var primaryDark = "_1y-5";
export var primaryLight = "_1y-3";
export var primaryModern = "_1y-6";