import * as classnames from "classnames";
import { memo } from "react";
import { Card } from "../../card";
import { Flex } from "../../flex";
import { RadioButton } from "../../radio";
import { Text } from "../../text";
import { RedoCheckbox, RedoCheckboxSize } from "./redo-checkbox";
import * as css from "./redo-checkbox-card.module.css";

export const checkboxCardInputTypes = ["radio", "checkbox"] as const;
export type CheckboxCardInputType = (typeof checkboxCardInputTypes)[number];

export enum Layout {
  SHORT = "short",
  TALL = "tall",
}

export interface RedoCheckboxCardProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  checked: boolean;
  onClick: () => void;
  borderColor?: string;
  inputType?: CheckboxCardInputType;
  checkboxSize?: RedoCheckboxSize;
  layout?: Layout;
}

export const RedoCheckboxCard = memo(function RedoCheckboxCardProps({
  title,
  icon,
  checked,
  onClick,
  inputType,
  checkboxSize = RedoCheckboxSize.MEDIUM,
  layout = Layout.SHORT,
  children,
}: RedoCheckboxCardProps) {
  const Input = () => (
    <div className={css.inputBlocker}>
      {inputType === "checkbox" && (
        <RedoCheckbox
          label=""
          setValue={() => {}}
          size={checkboxSize}
          value={checked}
        />
      )}
      {inputType === "radio" && (
        <RadioButton selected={checked} value="" valueChange={() => {}} />
      )}
    </div>
  );
  return (
    <Card
      className={classnames(css.checkboxCard, {
        [css.checkboxCardSelected]: checked,
      })}
      onClick={onClick}
    >
      <Flex
        flexDirection={layout === Layout.SHORT ? "row" : "column"}
        gap={layout === Layout.SHORT ? "lg" : "3xl"}
      >
        <Flex align="flex-start" justify="space-between">
          {icon && <div className={css.cardIcon}>{icon}</div>}
          {layout === Layout.TALL && <Input />}
        </Flex>
        <Flex align="flex-start" dir="column" flex={1} gap="xs">
          <Text fontSize="md" fontWeight="bold">
            {title}
          </Text>
          <Text color="secondary" fontSize="sm">
            {children}
          </Text>
        </Flex>
        {layout === Layout.SHORT && <Input />}
      </Flex>
    </Card>
  );
});
