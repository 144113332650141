import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getAuditLog, getAuditLogs } from "./schema/audit-logs/audit-logs";
import { addBalanceCredit } from "./schema/balance/add-balance-credit";
import { getBalance } from "./schema/balance/get-balance";
import { createCommentSoldTeam } from "./schema/comment-sold/create-team";
import { getConversionSettings } from "./schema/conversion/get-conversion-settings";
import { updateConversionSettings } from "./schema/conversion/update-conversion-settings";
import { getMultipassSecret } from "./schema/customer-accounts/get-multipass-secret";
import { setMultipassSecret } from "./schema/customer-accounts/set-multipass-secret";
import { fastSyncFulfillments } from "./schema/fulfillments-sync/fast-sync-fullfillments";
import { createPhoneNumber } from "./schema/phone-numbers/create-phone-number";
import { getPhoneNumberByTeamIdAndUseCase } from "./schema/phone-numbers/get-phone-number-by-team-id-and-use-case";
import { updatePhoneNumber } from "./schema/phone-numbers/update-phone-number";
import { getRateTables } from "./schema/rate-tables/get-rate-tables";
import { saveRateTable } from "./schema/rate-tables/save-rate-table";
import { getRateTablesFromDeliveryProfile } from "./schema/redo-in-shipping/get-rate-tables-from-delivery-profile";
import { getRisIntegration } from "./schema/redo-in-shipping/get-ris-integration";
import { hasCarrierServices } from "./schema/redo-in-shipping/has-carrier-services";
import { updateRisIntegration } from "./schema/redo-in-shipping/update-ris-integration";
import { refreshShipmentRates } from "./schema/shipment-rates/refresh-shipment-rates";
import { getCheckoutOutboundRatesSettings } from "./schema/shopify/get-checkout-outbound-rates-settings";
import { getDeliveryProfile } from "./schema/shopify/get-delivery-profile";
import { syncShopifyProductsForTeam } from "./schema/shopify/sync-shopify-products-for-team";
import { updateCheckoutOutboundRatesSettings } from "./schema/shopify/update-checkout-outbound-rates-settings";

export const rpcDefinition = {
  createCommentSoldTeam,
  getMultipassSecret,
  setMultipassSecret,
  getPhoneNumberByTeamIdAndUseCase,
  createPhoneNumber,
  updatePhoneNumber,
  getCheckoutOutboundRatesSettings,
  updateCheckoutOutboundRatesSettings,
  getRisIntegration,
  updateRisIntegration,
  getRateTablesFromDeliveryProfile,
  getAuditLog,
  getAuditLogs,
  getRateTables,
  refreshShipmentRates,
  fastSyncFulfillments,
  saveRateTable,
  getBalance,
  addBalanceCredit,
  syncShopifyProductsForTeam,
  getDeliveryProfile,
  hasCarrierServices,
  getConversionSettings,
  updateConversionSettings,
};

export type AdminRpcDefinition = InferRpcDefinition<typeof rpcDefinition>;
