import {
  ExtensionShopifyClient,
  ShopifyCart,
  ShopifyCartAttributes,
  ShopifyCartItem,
  ShopifyClientType,
  ShopifyProduct,
  ShopifyProductJS,
  ShopifyShop,
} from "@redotech/shopify-client/ajax";
import {
  StorefrontApiClient,
  createStorefrontApiClient,
} from "@shopify/storefront-api-client";
import { createContext } from "react";
import {
  ProductByHandleQuery,
  ProductByHandleQueryVariables,
  ProductGetJsQuery,
  ProductQuery,
  ProductQueryVariables,
} from "./shopify-storefront.graphql";

export const ExtensionShopifyClientContext = createContext<
  ExtensionShopifyClient | undefined
>(undefined);

export class TapcartClient implements ExtensionShopifyClient {
  type = ShopifyClientType.TAPCART;
  storefrontClient: StorefrontApiClient;
  tapcart: typeof window.Tapcart;

  constructor(
    storefrontAccessToken: string,
    storeUrl: string,
    tapcart: typeof Tapcart,
  ) {
    this.storefrontClient = createStorefrontApiClient({
      storeDomain: `https://${storeUrl}`,
      apiVersion: "2023-10",
      publicAccessToken: storefrontAccessToken,
      retries: 3,
      customFetchApi: window.fetch,
    });
    this.tapcart = tapcart;
  }

  async removeItemFromCart(
    item: ShopifyCartItem,
    numToRemove: number,
    signal?: AbortSignal,
  ): Promise<undefined> {
    await this.tapcart.actions.removeFromCart({
      lineItems: [
        { variantId: `${item.id}`, quantity: numToRemove, attributes: null },
      ],
    });
    return Promise.resolve(undefined);
  }

  async updateCartAttribute(
    attributes: ShopifyCartAttributes,
    signal?: AbortSignal | undefined,
  ) {
    this.tapcart.actions.updateCartAttributes(attributes);
  }

  async updateCartItemSellingPlan(
    item: ShopifyCartItem,
    sellingPlanId: number | null,
    signal?: AbortSignal | undefined,
  ): Promise<undefined> {
    throw new Error("Selling plan editing not supported on Tapcart");
  }

  async cartAdd(
    data: { items: Partial<ShopifyCartItem>[] },
    params: URLSearchParams,
  ): Promise<undefined> {
    await this.tapcart.actions.addToCart({
      lineItems: [
        {
          variantId: data.items[0]?.id?.toString(),
          quantity: data.items[0]?.quantity,
        },
      ],
    });
    return Promise.resolve(undefined);
  }

  async cartGet(signal?: AbortSignal | undefined): Promise<ShopifyCart> {
    const cart = this.tapcart.variables.cart;
    const cartItems: ShopifyCartItem[] = [];
    const query = /* GraphQL */ `
      query product($id: ID!) {
        product(id: $id) {
          handle
          vendor
          title
          variants(first: 250) {
            edges {
              node {
                id
                price {
                  amount
                }
              }
            }
          }
        }
      }
    `;

    await Promise.all(
      cart.items.map(async (item) => {
        const variables: ProductQueryVariables = {
          id: `gid://shopify/Product/${item.productId}`,
        };
        const response = await this.storefrontClient.request<ProductQuery>(
          query,
          { variables },
        );

        let price = 0;
        if (response.data?.product) {
          const variant = response.data.product.variants.edges.find((edge) =>
            edge.node.id.includes(item.variantId.toString()),
          );
          price = variant ? parseFloat(variant.node.price.amount) : 0;

          cartItems.push({
            id: parseInt(item.variantId),
            product_id: parseInt(item.productId),
            variant_id: parseInt(item.variantId),
            handle: response.data.product.handle,
            quantity: item.quantity,
            vendor: response.data.product.vendor,
            title: response.data.product.title,
            requires_shipping: true,
            line_price: price,
          });
        }
      }),
    );
    return { currency: cart.currency, items: cartItems };
  }

  async cartClear(): Promise<undefined> {
    for (const item of this.tapcart.variables.cart.items) {
      await this.tapcart.actions.removeFromCart({
        variantId: item.variantId,
        quantity: item.quantity,
      });
    }
    return Promise.resolve(undefined);
  }

  async productGet(
    handle: string,
    signal?: AbortSignal | undefined,
  ): Promise<ShopifyProduct> {
    const query = /* GraphQL */ `
      query productByHandle($handle: String!) {
        productByHandle(handle: $handle) {
          id
          tags
          title
          variants(first: 250) {
            edges {
              node {
                id
                price {
                  amount
                }
                requiresShipping
              }
            }
          }
        }
      }
    `;
    const variables: ProductByHandleQueryVariables = { handle };
    const gidRegex = /^.+\/(\d+)$/;
    const response = await this.storefrontClient.request<ProductByHandleQuery>(
      query,
      { variables },
    );
    return {
      id: +response.data!.productByHandle!.id.replace(gidRegex, "$1"),
      tags: response.data!.productByHandle!.tags.join(", "),
      title: response.data!.productByHandle!.title,
      variants: response.data!.productByHandle!.variants.edges.map((edge) => ({
        id: +edge.node.id.replace(gidRegex, "$1"),
        requires_shipping: edge.node.requiresShipping,
        price: parseFloat(edge.node.price.amount),
      })),
    };
  }

  async productGetJS(
    handle: string,
    signal?: AbortSignal | undefined,
  ): Promise<ShopifyProductJS> {
    const query = /* GraphQL */ `
      query productGetJs($handle: String!) {
        productByHandle(handle: $handle) {
          id
          priceRange {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
          variants(first: 250) {
            edges {
              node {
                id
                price {
                  amount
                }
                requiresShipping
              }
            }
          }
        }
      }
    `;
    const variables = { handle };
    const response = await this.storefrontClient.request<ProductGetJsQuery>(
      query,
      { variables },
    );
    const gidRegex = /^.+\/(\d+)$/;
    return {
      id: +response.data!.productByHandle!.id.replace(gidRegex, "$1"),
      price_min:
        +response.data!.productByHandle!.priceRange.minVariantPrice.amount,
      price_max:
        +response.data!.productByHandle!.priceRange.maxVariantPrice.amount,
      variants: response.data!.productByHandle!.variants.edges.map((edge) => ({
        id: +edge.node.id.replace(gidRegex, "$1"),
        price: +edge.node.price.amount,
        requires_shipping: edge.node.requiresShipping,
      })),
    };
  }

  async getShopMetafield(): Promise<ShopifyShop | undefined> {
    return;
  }

  async deleteCartAttribute(): Promise<void> {}
}

export function shopifyProductHandle(url: string) {
  const u = new URL(url);
  // Need to handle both cases:
  // `/products/breeze-blazer/`
  // `/products/breeze-blazer`
  const pathname = u.pathname.endsWith("/")
    ? u.pathname.slice(0, -1)
    : u.pathname;
  return decodeURIComponent(pathname.split("/").pop()!);
}

export function isOnProductPage(onTapcart: boolean | undefined = false) {
  return onTapcart
    ? !!window.Tapcart.variables.product
    : location.href.includes("/products/");
}

export function isOnCollectionPage(onTapcart: boolean | undefined = false) {
  return onTapcart
    ? !!window.Tapcart.variables.collection
    : location.href.includes("/collections/");
}

export function isOnHomePage() {
  return (window as any).ShopifyAnalytics?.meta?.page?.pageType === "home";
}
