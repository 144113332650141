// extracted by mini-css-extract-plugin
export var button = "_f-0";
export var buttonContent = "_f-9";
export var dropdownButton = "_f-7";
export var iconContainer = "_f-6";
export var lg = "_f-4";
export var md = "_f-3";
export var pending = "_f-a";
export var sm = "_f-2";
export var spinner = "_f-8";
export var xl = "_f-5";
export var xs = "_f-1";