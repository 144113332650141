import { z } from "zod";
import { Team } from "./team";

export type Offering = {
  title: string;
  id: string;
  collectionHandle: string;
  price: { currency: string; amount: number };
};

export type Collection = { title: string; handle: string };

export type Selections = {
  [variantId: string]: { collectionHandle: string; timeId: string };
};

export type ExtendedWarrantyShopMetafield = {
  enabled: NonNullable<Team["settings"]["extendedWarranties"]>["enabled"];
  collectionSettings: NonNullable<
    Team["settings"]["extendedWarranties"]
  >["collectionSettings"];
  warrantyProductId?: string;
};

export type ExtendedWarrantyShopMetafieldStyles = {
  sideImage?: string;
  logo?: string;
} & Team["settings"]["extendedWarranties"];

export const ExtendedWarrantySchema = z.object({
  variantId: z.string(),
  timeId: z.string(),
  daysExtended: z.number(),
  pricePaid: z.string(),
  collectionHandle: z.string(),
  warrantyActivationDelay: z.number(),
});

export type ExtendedWarranty = z.infer<typeof ExtendedWarrantySchema>;

export const CART_ATTRIBUTE_KEY = "extended-warranty-selections";
export const SHOP_METAFIELD_KEY = "extended-warranty-settings";
export const SHOP_METAFIELD_NAME = "redo_extended_warranty_settings";
export const SHOP_METAFIELD_STYLES_KEY = "extended-warranty-styles";
export const SHOP_METAFIELD_STYLES_NAME = "redo_extended_warranty_styles";
export const SHOP_METAFIELD_NAMESPACE = "$app";
export const NO_COVERAGE_TIME_ID = "no_coverage";
export const DEFAULT_PRODUCT_TITLE = "Redo Extended Warranty";

export enum ExtendedWarrantyDataTarget {
  CT_NOT_ADDED_CONTAINER = "ew-ct-not-added-container",
  CT_NOT_ADDED_SHIELD = "ew-ct-not-added-shield",
  CT_NOT_ADDED_CONTENT = "ew-ct-not-added-content",
  CT_NOT_ADDED_ADD = "ew-ct-not-added-add",
  CT_ADDED_CONTAINER = "ew-ct-added-container",
  CT_ADDED_SHIELD = "ew-ct-added-shield",
  CT_ADDED_CONTENT = "ew-ct-added-content",
  CT_ADDED_REMOVE = "ew-ct-added-remove",
  CT_BUTTON = "ew-ct-button",
  CT_MODAL_CONTAINER = "ew-ct-modal-container",
  CT_MODAL_TEXT = "ew-ct-modal-text",
  CT_MODAL_BUTTON = "ew-ct-modal-button",
  CT_MODAL_CONTENT_C = "ew-ct-modal-content-container",
  PDP_CONTAINER = "ew-pdp-container",
  PDP_SHIELD = "ew-pdp-shield",
  PDP_CONTENT = "ew-pdp-content",
  PDP_TITLE = "ew-pdp-title",
  PDP_SUBTITLE = "ew-pdp-subtitle",
  PDP_LEARN_MORE_CONTAINER = "ew-pdp-learn-more-container",
  PDP_ADD_BUTTON = "ew-pdp-add-button",
  LEARN_MORE_LINK = "ew-learn-more-link",
  ADD_BUTTON_CONTAINER = "ew-add-button-container",
  OFFERING_TILES_CONTAINER = "ew-offering-tiles-container",
  OFFERING_TILE_CONTAINER = "ew-offering-tile-container",
  OFFERING_TILE_TITLE = "ew-offering-tile-title",
  OFFERING_TILE_SUBTITLE = "ew-offering-tile-subtitle",
  OFFERING_TILE_PRICE = "ew-offering-tile-price",
  MODAL_CONTAINER = "ew-modal-container",
  MODAL_CONTENT_1 = "ew-modal-content-1",
  MODAL_CONTENT_2 = "ew-modal-content-2",
  MODAL_CONTENT_2_LIST = "ew-modal-content-2-list",
  MODAL_CONTENT_2_LIST_ITEM = "ew-modal-content-2-list-item",
  MODAL_CONTENT_3 = "ew-modal-content-3",
  MODAL_BUTTON_CONTAINER = "ew-modal-button-container",
  MODAL_BUTTON_DECLINE = "ew-modal-button-decline",
  MODAL_BUTTON_ACCEPT = "ew-modal-button-accept",
}

export const getCheapestOffering = (
  offerings: Offering[],
): Offering | undefined => {
  return offerings
    .filter((o) => o.id !== NO_COVERAGE_TIME_ID)
    .reduce((cheapest, current) => {
      if (!cheapest || !current) {
        return current;
      }
      return current.price.amount < cheapest.price.amount ? current : cheapest;
    }, offerings[0]);
};

export const DEFAULT_TEXT = {
  modalTitle: "Enhance your item's protection",
  modalDescription:
    "Our protection plan complements the manufacturer warranty to provide even greater peace of mind",
  modalCoverageHeader: "Extended coverage includes:",
  modalPlanHeader: "Plan options:",
  modalBulletPoints: [
    "Accidental damage such as breaks and drops",
    "100% coverage for the duration of your plan",
    "Hassle-free replacements",
    "Enhanced protection against unexpected failures",
  ],
  pdpTitle: "Protect this item",
  pdpLabel: "Protection",
  pdpDescription: "Choose a protection plan in case of accidental damage",
  pdpAddToCartDescription: "Plans starting at %price%",
  pdpAddedToCartTitle: "Item protected",
  pdpAddedToCartDescription: "Added %plan_title% protection plan",
  cartToggleModalNotAddedDescription: "Protect this item",
  cartToggleModalAddedDescription: "Added %plan_title% protection plan",
  cartToggleAddedDescription: "Added %plan_title% protection plan",
  cartToggleNotAddedDescription: "%plan_title% protection plan for %price%",
};
