import {
  arrayJsonFormat,
  booleanJsonFormat,
  dateJsonFormat,
  discriminatedUnionFormat,
  JsonFormat,
  neverJsonFormat,
  nullableJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  stringUnionJsonFormat,
  tupleJsonFormat,
  typedStringJsonFormat,
  unionFormat,
} from "@redotech/json/format";
import { Tuple } from "@redotech/util/type";
import { ConversationPlatform } from "../conversation";
import {
  sortableConversationsTableColumns,
  SortableConversationTableColumn,
} from "../tables/conversations-table-sort";
import { SortDirection, TableSort } from "../tables/table";
import {
  AdvancedFilter,
  AssigneesFilter,
  AssigneesFilterType,
  ChannelFilterType,
  ChannelsFilter,
  ClosedDateFilter,
  ConversationFiltersV3,
  ConversationTagFilterType,
  ConversationTagsFilter,
  CreatedDateFilter,
  CustomerTagsFilter,
  CustomerTagsFilterType,
  DateFilter,
  DateFilterQueryType,
  FilterGroupFilterOption,
  FiltersStatus,
  KnownDateFilterTimeFrame,
  LastResponseAtFilter,
  MentionsUsersFilter,
  ReadStatusFilter,
  WordsFilter,
} from "./conversation-filters";

const channelsFilterFormat: JsonFormat<ChannelsFilter> = objectJsonFormat(
  {
    type: typedStringJsonFormat(FilterGroupFilterOption.CHANNELS),
    value: arrayJsonFormat(stringEnumJsonFormat(ConversationPlatform)),
    query: stringEnumJsonFormat(ChannelFilterType),
  },
  {},
);

const assigneesFilterFormat: JsonFormat<AssigneesFilter> = objectJsonFormat(
  {
    type: typedStringJsonFormat(FilterGroupFilterOption.ASSIGNEES),
    value: arrayJsonFormat(nullableJsonFormat(stringJsonFormat)),
    query: stringEnumJsonFormat(AssigneesFilterType),
  },
  {},
);

const readStatusFilterFormat: JsonFormat<ReadStatusFilter> = objectJsonFormat(
  {
    type: typedStringJsonFormat(FilterGroupFilterOption.READ),
    value: booleanJsonFormat,
  },
  { query: neverJsonFormat },
);

const customDateFormat = unionFormat<Date, Tuple<Date, 2>>(
  dateJsonFormat,
  tupleJsonFormat<Date, 2>(dateJsonFormat, 2),
  (value) => {
    if (Array.isArray(value)) {
      return tupleJsonFormat(dateJsonFormat, 2);
    }
    return dateJsonFormat;
  },
);

const dateFilterFormat = <S extends FilterGroupFilterOption>(
  option: S,
): JsonFormat<DateFilter<S>> => {
  return objectJsonFormat<{
    type: string;
    value: KnownDateFilterTimeFrame;
    query: DateFilterQueryType;
    customDate?: Date | Tuple<Date, 2>;
  }>(
    {
      type: typedStringJsonFormat(option),
      value: stringEnumJsonFormat(KnownDateFilterTimeFrame),
      query: stringEnumJsonFormat(DateFilterQueryType),
    },
    { customDate: customDateFormat },
  ) as JsonFormat<DateFilter<S>>;
};

const createdDateFilterFormat: JsonFormat<CreatedDateFilter> = dateFilterFormat(
  FilterGroupFilterOption.CREATED_DATE,
);

const lastResponseAtFilterFormat: JsonFormat<LastResponseAtFilter> =
  dateFilterFormat(FilterGroupFilterOption.LAST_RESPONSE_AT);

const closedDateFilterFormat: JsonFormat<ClosedDateFilter> = dateFilterFormat(
  FilterGroupFilterOption.CLOSED_DATE,
);

const conversationTagsFilterFormat: JsonFormat<ConversationTagsFilter> =
  objectJsonFormat(
    {
      type: typedStringJsonFormat(FilterGroupFilterOption.CONVERSATION_TAGS),
      value: arrayJsonFormat(stringJsonFormat),
      query: stringEnumJsonFormat(ConversationTagFilterType),
    },
    {},
  );

const wordsFilterFormat: JsonFormat<WordsFilter> = objectJsonFormat(
  {
    type: typedStringJsonFormat(FilterGroupFilterOption.WORDS),
    value: arrayJsonFormat(stringJsonFormat),
  },
  { query: neverJsonFormat },
);

const customerTagsFilterFormat: JsonFormat<CustomerTagsFilter> =
  objectJsonFormat(
    {
      type: typedStringJsonFormat(FilterGroupFilterOption.CUSTOMER_TAGS),
      value: arrayJsonFormat(stringJsonFormat),
      query: stringEnumJsonFormat(CustomerTagsFilterType),
    },
    {},
  );

const mentionsUsersFilterFormat: JsonFormat<MentionsUsersFilter> =
  objectJsonFormat(
    {
      type: typedStringJsonFormat(FilterGroupFilterOption.MENTIONS),
      value: arrayJsonFormat(stringJsonFormat),
    },
    { query: neverJsonFormat },
  );

const formats: Record<FilterGroupFilterOption, JsonFormat<AdvancedFilter>> = {
  [FilterGroupFilterOption.CHANNELS]: channelsFilterFormat,
  [FilterGroupFilterOption.ASSIGNEES]: assigneesFilterFormat,
  [FilterGroupFilterOption.READ]: readStatusFilterFormat,
  [FilterGroupFilterOption.CREATED_DATE]: createdDateFilterFormat,
  [FilterGroupFilterOption.LAST_RESPONSE_AT]: lastResponseAtFilterFormat,
  [FilterGroupFilterOption.CLOSED_DATE]: closedDateFilterFormat,
  [FilterGroupFilterOption.CONVERSATION_TAGS]: conversationTagsFilterFormat,
  [FilterGroupFilterOption.WORDS]: wordsFilterFormat,
  [FilterGroupFilterOption.CUSTOMER_TAGS]: customerTagsFilterFormat,
  [FilterGroupFilterOption.MENTIONS]: mentionsUsersFilterFormat,
};

const filterOptionFormat: JsonFormat<AdvancedFilter> = discriminatedUnionFormat(
  "type",
  formats,
);

export const filterArrayFormat: JsonFormat<AdvancedFilter[]> =
  arrayJsonFormat(filterOptionFormat);

export const v3FiltersFormat: JsonFormat<ConversationFiltersV3> =
  objectJsonFormat(
    { advancedFilters: filterArrayFormat },
    {
      status: nullableJsonFormat(stringEnumJsonFormat(FiltersStatus)),
      search: stringJsonFormat,
      sort: objectJsonFormat<TableSort<SortableConversationTableColumn>>(
        {
          key: stringUnionJsonFormat(sortableConversationsTableColumns),
          direction: stringEnumJsonFormat(SortDirection),
        },
        {},
      ),
      customerEmail: stringJsonFormat,
      drafts: booleanJsonFormat,
    },
  );
