import { Status } from "@redotech/http/semantics";
import { ZodError } from "zod";

export class RpcError<Code extends string> extends Error {
  constructor(
    readonly status: Status,

    override readonly message: string,
    readonly code?: Code,
  ) {
    super(message);
  }
}

export class ClientError extends Error {
  constructor(
    readonly statusCode: number,

    override readonly message: string,
    public readonly code: string | undefined,
  ) {
    super(message);
  }
}

export function serializeError(error: unknown): string {
  if (error instanceof ZodError) {
    return JSON.stringify(error.format());
  } else if (error instanceof RpcError) {
    return JSON.stringify({ error: error.message, code: error.code });
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return JSON.stringify(error, null, 2);
  }
}
