// extracted by mini-css-extract-plugin
export var chip = "_20-9";
export var chipLabel = "_20-a";
export var close = "_20-7";
export var closeIcon = "_20-8";
export var error = "_20-6";
export var infoTooltip = "_20-2";
export var innerContainer = "_20-1";
export var input = "_20-b";
export var medium = "_20-5";
export var outerContainer = "_20-0";
export var small = "_20-4";
export var xSmall = "_20-3";