export enum SyncShopifyProductsForTeamResponse {
  STARTED = "STARTED",
  ALREADY_IN_PROGRESS = "ALREADY_IN_PROGRESS",
}

export interface ProductForSync {
  collections: { handle: string; id: string }[];
  description: string;
  handle: string;
  /** gid://shopify/Product/123 */
  id: string;
  image: { src: string | null; altText: string | null };
  metafields: Record<string, string>;
  priceRange: {
    maxVariantPrice: { amount: string; currencyCode: string };
    minVariantPrice: { amount: string; currencyCode: string };
  };
  publishedToOnlineStore: boolean;
  status: string;
  tags: string[];
  title: string;
  totalInventory: number;
  vendor: string | undefined;
  productType: string | undefined;
  variants: Array<{
    availableForSale: boolean;
    grams: number;
    displayName: string;
    /** gid://shopify/Product/123 */
    id: string;
    image: { src: string | null; altText: string | null };
    inventory_policy: string;
    inventory_quantity: number;
    /** In default store currency */
    price: string;
    sku: string;
    taxCode: string | undefined;
    title: string;
  }>;
  updatedAt: string;
}

export const ONLINE_STORE_SALES_CHANNEL_NAME = "Online Store";
