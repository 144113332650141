import { Gid } from "@redotech/util/shopify-id";

export enum ExpirationType {
  DELIVERY = "delivery",
  EXPIRATION_DAYS = "expiration",
}

export enum DiscountGroupType {
  POPUP = "popup",
  ORDER_TRACKING = "orderTracking",
  CAMPAIGN = "campaign",
  STATIC = "static",
  DYNAMIC = "dynamic",
}

export enum DiscountType {
  FREE_SHIPPING = "freeShipping",
  ORDER = "order",
  PRODUCT = "product",
}

export enum DiscountProductType {
  PRODUCT = "product",
  COLLECTION = "collection",
}

export enum DiscountProvider {
  SHOPIFY = "shopifyDiscount",
  COMMENTSOLD = "commentsold",
  OTHER = "other",
}

export interface DiscountCollection {
  id?: Gid;
  name?: string;
}

export enum DiscountCollectionsSelectionType {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}
export interface DiscountProduct {
  id?: string;
  title?: string;
}

export enum DiscountValueType {
  PERCENTAGE = "percentage",
  AMOUNT = "amount",
}

export enum DiscountMinRequirementType {
  MINIMUM_QUANTITY = "minimumQuantity",
  MINIMUM_SUBTOTAL = "minimumSubtotal",
}

export interface DiscountSettingsBasic {
  discountType: DiscountType;
  expirationType: ExpirationType;
  expirationDays?: number;
  combinesWith?: {
    orderDiscounts?: boolean;
    productDiscounts?: boolean;
    shippingDiscount?: boolean;
  };
  minimumRequirement: { type: DiscountMinRequirementType; minimum: number };
  defaultText?: string;
}

export interface DiscountSettingsOrder extends DiscountSettingsBasic {
  discountType: DiscountType.ORDER;
  discountValueType: DiscountValueType;
  discountAmountValue?: number;
  discountPercentageValue?: number; // must be a number between 0.0 and 1.0
}

export interface DiscountSettingsFreeShipping extends DiscountSettingsBasic {
  discountType: DiscountType.FREE_SHIPPING;
  freeShippingCountryCodes?: string[];
  maximumShippingPrice: number;
}

export interface DiscountSettingsProduct extends DiscountSettingsBasic {
  discountType: DiscountType.PRODUCT;
  discountProductType: DiscountProductType;
  discountProducts?: DiscountProduct[];
  discountCollections?: DiscountCollection[];
  discountCollectionsSelectionType?: DiscountCollectionsSelectionType;
  discountValueType: DiscountValueType;
  discountAmountValue?: number;
  discountPercentageValue?: number; // must be a number between 0.0 and 1.0
}

export type DiscountSettings =
  | DiscountSettingsOrder
  | DiscountSettingsFreeShipping
  | DiscountSettingsProduct;
