// extracted by mini-css-extract-plugin
export var checkbox = "_18-0";
export var circle = "_18-3";
export var horizontalContainer = "_18-7";
export var sm = "_18-5";
export var switch0 = "_18-2";
export var switchDisabled = "_18-8";
export var switchEnabled = "_18-1";
export var textSwitchContainer = "_18-9";
export var textSwitchOption = "_18-a";
export var textSwitchOptionSelected = "_18-b";
export var verticalContainer = "_18-6";
export var xs = "_18-4";