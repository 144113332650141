// extracted by mini-css-extract-plugin
export var container = "_z-0";
export var error = "_z-9";
export var label = "_z-5";
export var light = "_z-6";
export var md = "_z-3";
export var sm = "_z-2";
export var thin = "_z-8";
export var thinBold = "_z-7";
export var vertical = "_z-4";
export var xs = "_z-1";