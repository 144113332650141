import { ShippingCoverageZodSchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getRisIntegration = {
  input: z.object({ teamId: zExt.objectId(), integrationType: z.string() }),
  output: z.object({
    risIntegration: ShippingCoverageZodSchema.nullable(),
    hasShopifyIntegration: z.boolean(),
  }),
};
