import { CURRENCY_FORMAT } from "@redotech/redo-model/money";

import {
  maxValue,
  PriceCondition,
  WeightCondition,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import {
  convertWeight,
  WeightUnit,
} from "@redotech/redo-model/weight-conversion";

export function getTextFromCondition(
  condition: WeightCondition | PriceCondition,
) {
  if (condition.type === "weight") {
    if (condition.maxGrams === maxValue) {
      return `Orders greater than ${convertWeight(condition.minGrams, WeightUnit.GRAMS, WeightUnit.POUNDS).toFixed(2)}lbs`;
    }
    return `Orders less than ${convertWeight(condition.maxGrams, WeightUnit.GRAMS, WeightUnit.POUNDS).toFixed(2)}lbs`;
  } else {
    if (condition.maxPrice === maxValue) {
      return `Orders ${CURRENCY_FORMAT(condition.currency).format(condition.minPrice)} and over`;
    }
    return `Orders under ${CURRENCY_FORMAT(condition.currency).format(condition.maxPrice)}`;
  }
}
