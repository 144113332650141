// extracted by mini-css-extract-plugin
export var background = "_26-0";
export var buttonPrimary = "_26-1";
export var container = "_26-2";
export var feature = "_26-5";
export var featureBody = "_26-6";
export var featureIcon = "_26-7";
export var featureSummary = "_26-9";
export var featureTitle = "_26-8";
export var modal = "_26-f";
export var modalAction = "_26-e";
export var modalCloseButton = "_26-d";
export var modalFeatures = "_26-4";
export var modalFooter = "_26-a";
export var modalImageLogo = "_26-b";
export var modalLogo = "_26-c";
export var redoModal = "_26-3";