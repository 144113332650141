import { CountryCode } from "@redotech/locale/countries";
import { Currency } from "@redotech/money/iso4217/currencies";
import {
  Collection,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { Team } from "@redotech/redo-model/team";
import { getPackageProtectionPrice } from "./price-calculations";

export const calculateOfferings = ({
  collectionOfferings,
  variant,
  currency = Currency.USD,
  countryCode = "US",
}: {
  collectionOfferings: NonNullable<
    Team["settings"]["extendedWarranties"]
  >["collectionSettings"];
  variant: { price: number; variantId: string; collections: Collection[] };
  currency?: Currency;
  countryCode?: CountryCode;
}): Offering[] => {
  const matchingCollections = collectionOfferings.filter((c) =>
    variant.collections.some((col) => col.handle === c.collectionHandle),
  );
  if (matchingCollections.length === 0) {
    return [];
  }
  // if product is in more than one collection that offers an extended warranty, pick the first one for now.
  const collection = matchingCollections[0];
  if (!collection) {
    return [];
  }

  const offerings = collection.offerings.map((offering) => {
    const amount = getPackageProtectionPrice(
      variant.price / 100,
      [offering.pricingRuleSet],
      countryCode,
      Number(offering.minPrice),
      Number(offering.maxPrice),
      0,
    );
    return {
      title: offering.timeTitle,
      id: offering.timeId,
      collectionHandle: collection.collectionHandle,
      price: { currency, amount },
    };
  });
  offerings.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
  offerings.push({
    title: "No coverage",
    id: NO_COVERAGE_TIME_ID,
    collectionHandle: collection.collectionHandle,
    price: { currency, amount: 0 },
  });

  return offerings;
};
