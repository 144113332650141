// extracted by mini-css-extract-plugin
export var alternate = "_1d-h";
export var black = "_1d-3";
export var blue = "_1d-d";
export var blueLight = "_1d-c";
export var brand = "_1d-7";
export var error = "_1d-9";
export var gray = "_1d-6";
export var grayBlue = "_1d-b";
export var iconWrapper = "_1d-4";
export var indigo = "_1d-e";
export var orange = "_1d-g";
export var pink = "_1d-f";
export var primary = "_1d-0";
export var purple = "_1d-8";
export var success = "_1d-5";
export var warning = "_1d-a";
export var white = "_1d-1";
export var whiteSuccess = "_1d-2";