import type { ExtensionShopifyClient } from "@redotech/shopify-client/ajax";

/**
 * Adds a `redoDebug` function to the window that can be called from the browser console.
 * This method enables debug logging for both the shopify extension and the checkout extension.
 */
export function mountRedoDebugFunction(
  extensionShopifyClient: ExtensionShopifyClient,
) {
  window.redoDebug = async function (enabled: boolean): Promise<void> {
    // Set a session storage value for the `log` function to read
    sessionStorage?.setItem("redo-debug", String(enabled));

    // Set (or remove) a cart attribute for the checkout-extension to read
    await extensionShopifyClient.updateCartAttribute({
      attributes: [
        {
          /** @see https://shopify.dev/docs/api/ajax/reference/cart#private-cart-attributes */
          key: "__redo-debug",
          value: enabled ? String(enabled) : "", // Empty string removes the attribute
        },
      ],
    });
  };
}

export function log(...args: Parameters<typeof console.log>): void {
  if (sessionStorage?.getItem("redo-debug") === "true") {
    console.log(...args);
  }
}
