// extracted by mini-css-extract-plugin
export var box = "_1v-0";
export var check = "_1v-1";
export var checkboxButton = "_1v-6";
export var checkboxGroup = "_1v-5";
export var disabled = "_1v-7";
export var ghost = "_1v-9";
export var input = "_1v-2";
export var label = "_1v-4";
export var normal = "_1v-8";
export var uncheck = "_1v-3";