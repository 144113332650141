import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateCheckoutOutboundRatesSettings = {
  input: z.object({
    teamId: zExt.objectId(),
    enabled: z.boolean(),
    fulfillmentDelayDays: z.number(),
  }),
  output: z.null(),
};
