import { Category } from "@redotech/redo-model/advanced-flow/triggers";
import { CONVERSATIONS_FILTERS_VERSION } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { CreateRuleBody } from "@redotech/redo-model/create-rule-body";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { Rule } from "@redotech/redo-model/rule";
import {
  PopulatedSplit,
  Split,
  Treatment,
  TreatmentPerformance,
} from "@redotech/redo-model/split";
import { BillingSettings, Team } from "@redotech/redo-model/team";
import { View } from "@redotech/redo-model/view";
import { isDef } from "@redotech/util/checks";
import { AxiosHeaders } from "axios";
import { RedoAdminClient } from ".";
import { SplitRequest } from "../team/split/split-tests";
import { TreatmentRequest } from "../team/split/split-utils";

export interface AuthTeam {
  authToken: string;
  user: any;
}

/**
 * POST /teams/:teamId/auth
 */
export async function createTeamAuth(
  client: RedoAdminClient,
  {
    name,
    teamId,
    signal,
  }: {
    name: { first: string; last: string };
    teamId: string;
    signal?: AbortSignal;
  },
): Promise<AuthTeam> {
  const response = await client.client.post(
    `/teams/${encodeURIComponent(teamId)}/auth`,
    { name },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams/:teamId
 */
export async function getTeam(
  client: RedoAdminClient,
  { signal, teamId }: { signal?: AbortSignal; teamId: string },
): Promise<Team> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

interface TeamBillingInfo {
  lastBill: { amount: number; date: string; status: string } | null;
  nextBill: { amount: number; date: string };
}

interface AppEmbedInfo {
  scriptTagEnabled: boolean;
  appEmbedEnabled: boolean;
  mainThemeId: string;
}

/**
 * GET /teams/:teamId/splits
 */
export async function getSplits(
  client: RedoAdminClient,
  { signal, teamId }: { signal?: AbortSignal; teamId: string },
): Promise<PopulatedSplit[]> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}/splits`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /team/:teamId/end_split/:splitId
 */
export async function endSplitTest(
  client: RedoAdminClient,
  {
    signal,
    split,
    teamId,
  }: { signal?: AbortSignal; split: PopulatedSplit; teamId: string },
): Promise<void> {
  await client.client.post(
    `teams/${teamId}/end_split/${split._id}`,
    { teamId, split },
    { headers: client.authorization(), signal },
  );
}

/**
 * PUT /team/:teamId/restart_split/:splitId
 */
export async function restartSplitTest(
  client: RedoAdminClient,
  {
    signal,
    split,
    teamId,
  }: { signal?: AbortSignal; split: PopulatedSplit; teamId: string },
): Promise<void> {
  await client.client.post(
    `teams/${teamId}/restart_split/${split._id}`,
    { teamId, split },
    { headers: client.authorization(), signal },
  );
}

/**
 * PUT /teams/:teamId/treatments/:treatmentId
 */
export async function updateTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    treatmentId,
    value,
  }: { signal?: AbortSignal; teamId: string; treatmentId: string; value: any },
): Promise<void> {
  await client.client.put(
    `teams/${teamId}/treatments/${treatmentId}`,
    { value },
    { headers: client.authorization(), signal },
  );
}

/**
 * DELETE /teams/:teamId/treatments/:treatmentId
 */
export async function removeTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    treatmentId,
  }: { signal?: AbortSignal; teamId: string; treatmentId: string },
): Promise<void> {
  await client.client.delete(`teams/${teamId}/treatments/${treatmentId}`, {
    headers: client.authorization(),
    signal,
  });
}

/**
 * DELETE /teams/:teamId/concierge-treatments/:treatmentId
 */
export async function deleteGenericTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    treatmentId,
  }: { signal?: AbortSignal; teamId: string; treatmentId: string },
): Promise<void> {
  await client.client.delete(
    `teams/${teamId}/concierge-treatments/${treatmentId}`,
    { headers: client.authorization(), signal },
  );
}

/**
 * GET /teams/:teamId/splits/:splitId
 */
export async function getSplit(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
  }: { signal?: AbortSignal; teamId: string; splitId: string },
): Promise<Split> {
  const response = await client.client.get(
    `teams/${teamId}/splits/${splitId}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 *
 * GET /teams/:teamId/splits/:splitId/data
 */
export async function getSplitData(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
  }: { signal?: AbortSignal; teamId: string; splitId: string },
): Promise<TreatmentPerformance[]> {
  const response = await client.client.get(
    `teams/${teamId}/splits/${splitId}/data`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /teams/:teamId/splits
 */
export async function createNewSplit(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    split,
  }: { signal?: AbortSignal; teamId: string; split: SplitRequest },
): Promise<Split> {
  const response = await client.client.post(
    `teams/${teamId}/splits`,
    { split },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * DELETE /teams/:teamId/splits/:splitId
 */
export async function deleteSelectedSplit(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
  }: { signal?: AbortSignal; teamId: string; splitId: string },
): Promise<Split> {
  const response = await client.client.delete(
    `teams/${teamId}/splits/${splitId}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /team/:teamId/splits/:splitId
 */
export async function updateSplit(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
    split,
  }: {
    signal?: AbortSignal;
    teamId: string;
    splitId: string;
    split: SplitRequest;
  },
): Promise<Split> {
  const response = await client.client.put(
    `teams/${teamId}/splits/${splitId}`,
    { split },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams/:teamId/splits/:splitId/treatments
 */
export async function getTreatments(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
  }: { signal?: AbortSignal; teamId: string; splitId: string },
): Promise<Treatment[]> {
  const response = await client.client.get(
    `teams/${teamId}/splits/${splitId}/treatments`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /teams/:teamId/splits/:splitId/treatments
 */
export async function createNewTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
    treatment,
  }: {
    signal?: AbortSignal;
    teamId: string;
    splitId: string;
    treatment: TreatmentRequest;
  },
): Promise<Treatment> {
  const response = await client.client.post(
    `teams/${teamId}/splits/${splitId}/treatments`,
    { treatment },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

type ConciergeTreatment = {
  _id: string;
  name: string;
  split_id: string;
  active: boolean;
  weight: number;
  settings: { productConciergeEnabled: boolean };
};

/**
 * POST /teams/:teamId/splits/:splitId/concierge-treatments
 */
export async function createNewConciergeTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
    treatmentSettings,
    name: treatmentName,
  }: {
    signal?: AbortSignal;
    teamId: string;
    splitId: string;
    treatmentSettings: object;
    name: string;
  },
): Promise<ConciergeTreatment> {
  const response = await client.client.post(
    `teams/${teamId}/splits/${splitId}/concierge-treatments`,
    { settings: treatmentSettings, name: treatmentName },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /teams/:teamId/concierge-treatments/:treatmentId
 */
export async function updateConciergeTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    treatmentId,
    treatmentSettings,
    name: treatmentName,
  }: {
    signal?: AbortSignal;
    teamId: string;
    treatmentId: string;
    treatmentSettings?: object;
    name?: string;
  },
): Promise<ConciergeTreatment> {
  const response = await client.client.put(
    `teams/${teamId}/concierge-treatments/${treatmentId}`,
    {
      ...(isDef(treatmentSettings) && { settings: treatmentSettings }),
      ...(isDef(treatmentName) && { name: treatmentName }),
    },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /:team_id/create_preview_treatment
 */
export async function createPreviewTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    treatment,
  }: { signal?: AbortSignal; teamId: string; treatment: TreatmentRequest },
): Promise<Treatment> {
  const response = await client.client.post(
    `teams/${teamId}/create_preview_treatment`,
    { treatment },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /teams/:teamId/treatments/:treatmentId/launch
 */
export async function launchTreatment(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    splitId,
    treatmentId,
  }: {
    signal?: AbortSignal;
    teamId: string;
    splitId: string;
    treatmentId: string;
  },
): Promise<void> {
  await client.client.post(
    `teams/${teamId}/treatments/${treatmentId}/launch`,
    { splitId },
    { headers: client.authorization(), signal },
  );
}

/**
 * GET /teams/:teamId/billing-date
 */
export async function getBillingInfo(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    settings,
  }: { signal?: AbortSignal; teamId: string; settings: BillingSettings },
): Promise<TeamBillingInfo> {
  const response = await client.client.post(
    `teams/${encodeURIComponent(teamId)}/billing-info`,
    { settings },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams/:teamId/app-embed-info
 */
export async function getAppEmbedInfo(
  client: RedoAdminClient,
  { signal, teamId }: { signal?: AbortSignal; teamId: string },
): Promise<AppEmbedInfo> {
  const response = await client.client.get(
    `teams/${encodeURIComponent(teamId)}/app-embed-info`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

export async function updateScriptTag(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    enabled,
  }: { signal?: AbortSignal; teamId: string; enabled: boolean },
): Promise<AppEmbedInfo> {
  const response = await client.client.post(
    `teams/${encodeURIComponent(teamId)}/update-script-tag`,
    { enabled },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams
 */
export async function getTeams(
  client: RedoAdminClient,
  {
    coverageEnabled,
    pageContinue,
    pageSize = 20,
    search,
    signal,
    sort,
  }: {
    coverageEnabled?: boolean;
    pageContinue: string | undefined;
    pageSize?: number;
    search?: string;
    signal?: AbortSignal;
    sort?: { key: string; direction: string };
  },
): Promise<{ data: Team[]; pageNext: string | undefined }> {
  const response = await client.client.get("teams", {
    headers: {
      ...client.authorization(),
      "X-Page-Continue": pageContinue,
      "X-Page-Size": pageSize,
    },
    signal,
    params: {
      "settings.coverageEnabled": coverageEnabled,
      search,
      sort: sort && `${sort.key}:${sort.direction}`,
    },
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * PUT /team/:teamId
 */
export async function updateTeam(
  client: RedoAdminClient,
  {
    signal,
    team,
    teamId,
  }: { signal?: AbortSignal; team: Team; teamId: string },
): Promise<void> {
  await client.client.put(`teams/${teamId}`, team, {
    headers: client.authorization(),
    signal,
  });
}

export async function getSystemViews(
  client: RedoAdminClient,
  signal?: AbortSignal,
): Promise<View[]> {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };

  const response = await client.client.get(`teams/system/views`, {
    headers: { ...v3Header, ...client.authorization() },
    signal,
  });
  return response.data.views;
}

export const createView = async (
  client: RedoAdminClient,
  teamId: string,
  body: CreateViewBody,
  signal?: AbortSignal,
) => {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };
  const response = await client.client.post(`teams/${teamId}/views`, body, {
    headers: { ...v3Header, ...client.authorization() },
    signal,
  });
  return response.data;
};

/**
 * POST /rules
 */
export async function createRule(
  client: RedoAdminClient,
  teamId: string,
  body: CreateRuleBody,
  signal?: AbortSignal,
): Promise<{ data: Rule }> {
  const response = await client.client.post(`teams/${teamId}/rules`, body, {
    headers: client.authorization(),
    signal,
  });
  return { data: response.data };
}

export async function getSystemRules(
  client: RedoAdminClient,
  signal?: AbortSignal,
): Promise<{ data: Rule[] }> {
  const response = await client.client.get(`teams/system/rules`, {
    headers: client.authorization(),
    signal,
  });
  return response;
}

/**
 * POST /teams/:teamId/email-templates/default
 */
export async function createDefaultEmailTemplates(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    category,
  }: { signal?: AbortSignal; teamId: string; category: Category },
): Promise<void> {
  await client.client.post(
    `teams/${teamId}/email-templates/default`,
    { category },
    { headers: client.authorization(), signal },
  );
}

export async function createDefaultEmailFlows(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    category,
  }: { signal?: AbortSignal; teamId: string; category: Category },
): Promise<void> {
  await client.client.post(
    `teams/${teamId}/email-flows/default`,
    { category },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /team/upload
 */
export async function createUpload(
  client: RedoAdminClient,
  { file, signal }: { file: File; signal?: AbortSignal },
): Promise<{ url: string }> {
  const data = new FormData();
  data.append("image", file);
  const response = await client.client.postForm("teams/upload", data, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /teams/:teamId/accounts-web-pixel
 */
export async function updateAccountsWebPixel(
  client: RedoAdminClient,
  {
    teamId,
    enabled,
    signal,
  }: { teamId: string; enabled: boolean; signal?: AbortSignal },
): Promise<void> {
  const response = await client.client.post(
    `teams/${teamId}/accounts-web-pixel`,
    { enabled },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams/:teamId/collections
 */
export async function getCollections(
  client: RedoAdminClient,
  { signal, teamId }: { signal?: AbortSignal; teamId: string },
): Promise<{ id: string; title: string; handle: string }[]> {
  const response = await client.client.get(`teams/${teamId}/collections`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /teams/:teamId/import-zendesk-macros
 */
export async function uploadZendeskMacros(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    file,
  }: { signal?: AbortSignal; teamId: string; file: File },
): Promise<void> {
  const data = new FormData();
  data.append("file", file);
  await client.client.postForm(`teams/${teamId}/import-zendesk-macros`, data, {
    headers: client.authorization(),
    signal,
  });
}

/**
 * POST /teams/:teamId/import-reamaze-macros
 */
export async function uploadReamazeMacros(
  client: RedoAdminClient,
  {
    signal,
    teamId,
    file,
  }: { signal?: AbortSignal; teamId: string; file: File },
): Promise<void> {
  const data = new FormData();
  data.append("file", file);
  await client.client.postForm(`teams/${teamId}/import-reamaze-macros`, data, {
    headers: client.authorization(),
    signal,
  });
}
