import { Big } from "big.js";
import { z, ZodTypeAny } from "zod";

export const zExt = {
  big: (params?: z.RawCreateParams) =>
    z
      .custom<Big>(
        (data) =>
          data instanceof Big ||
          typeof data === "string" ||
          typeof data === "number",
        params,
      )
      .transform<Big>(Big),
  instant: (params?: z.RawCreateParams) =>
    z.custom<Temporal.Instant>(
      (data) => data instanceof Temporal.Instant,
      params,
    ),
  objectId: (params?: z.RawCreateParams) =>
    z
      .custom<string>(
        (id) =>
          typeof id === "string" ||
          (typeof id === "object" && id && "toString" in id),
        params,
      )
      .transform<string>((id) => (typeof id === "string" ? id : String(id))),
  coercedResult: <T extends ZodTypeAny>(okType: T) =>
    z.union([
      z.object({ ok: z.literal(true), value: okType }),
      z.object({
        ok: z.literal(false),
        error: z.object({
          message: z.string(),
          name: z.string(),
          stack: z.optional(z.string()),
        }),
      }),
    ]),
};
