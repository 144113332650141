/**
 * @file
 * Model-driven forms. Tracks the values and statuses of inputs.
 * Works well with React.memo, by re-using results when possible.
 */

import { Equal, identityEqual } from "@redotech/util/equal";
import {
  ArrayKeyFn,
  IncFunction,
  incArray,
  incCompose,
  incObject,
  incValue,
} from "@redotech/util/inc";
import { objectMapValues } from "@redotech/util/object";
import * as validator from "validator";
/**
 * Group input state
 * @template C Sub-inputs
 */
export interface GroupInput<C> extends Input<{ [K in keyof C]: InputV<C[K]> }> {
  /** Child inputs */
  inputs: C;
}

/**
 * Input state
 * @template V Value
 */
export interface Input<V> {
  /** Whether input has changed from initial value */
  changed: boolean;
  /** Value */
  value: V;
  /** Error for this input and child inputs */
  allErrors: ValidationError[];
  /** Errors for this input */
  errors: ValidationError[];
  /** Change the value */
  setValue(value: V): void;
}

export type InputV<T> = T extends Input<infer V> ? V : never;

/**
 * Input schema
 */
export interface InputProvider<V, C extends Input<V> = Input<V>> {
  /**
   * @param initial Initial value
   * @param setValue Set value
   */
  (initial: V, setValue: SetState<V>): IncFunction<V, C>;
}

/**
 * Input schema
 */
export namespace InputProvider {
  export type Form<T extends InputProvider<any, any>> = InputProviderC<T>;
  export type Value<T extends InputProvider<any, any>> = InputProviderV<T>;
}

export type InputProviderC<T> =
  T extends InputProvider<any, infer C> ? C : never;

export type InputProviderV<T> =
  T extends InputProvider<infer V, any> ? V : never;

/**
 * List input state
 */
export interface ListInput<V, C extends Input<V>> extends Input<V[]> {
  /** Child inputs */
  inputs: C[];
}

/**
 * Optional input state
 */
export interface OptionalInput<V, C extends Input<V>>
  extends Input<V | undefined> {
  /** Child input */
  input: C | undefined;
}

/**
 * Optional input. If the value is undefined, the input (and validation) is disabled.
 */
export function optionalInput<T, C extends Input<T>>(
  inner: InputProvider<T, C>,
  initialFn: () => T,
): InputProvider<T | undefined, OptionalInput<T, C>> {
  return (initial, setValue) => {
    return (function f(
      previous: IncFunction<T, Input<T>> | undefined,
    ): IncFunction<T | undefined, OptionalInput<T, C>> {
      return (value: T | undefined) => {
        if (value === undefined) {
          return {
            next: (value) => f(undefined)(value),
            value: {
              allErrors: [],
              changed: value !== initial,
              errors: [],
              input: undefined,
              setValue(value) {
                setValue(() => value);
              },
              value: undefined,
            },
          };
        }
        if (!previous) {
          previous = inner(
            initial !== undefined ? initial : initialFn(),
            (fn) =>
              setValue((value) =>
                fn(value !== undefined ? value : initialFn()),
              ),
          );
        }
        const { next, value: input } = previous(value);
        return {
          next: (value) => f(next)(value),
          value: <OptionalInput<T, C>>{
            ...input,
            changed: initial === undefined || input.changed,
            input,
            setValue(value) {
              if (value === undefined) {
                setValue(() => undefined);
              } else {
                input.setValue(value);
              }
            },
          },
        };
      };
    })(undefined);
  };
}

/**
 * Group input
 */
export function groupInput<C>(
  inputs: {
    [K in keyof C]: C[K] extends Input<infer V>
      ? InputProvider<V, C[K]>
      : never;
  },
  {
    validator = noopValidator(),
  }: { validator?: Validator<{ [K in keyof C]: InputV<C[K]> }> } = {},
): InputProvider<{ [K in keyof C]: InputV<C[K]> }, GroupInput<C>> {
  return (initial, setValue): IncFunction<any, any> => {
    const controls = <{ [K in keyof C]: IncFunction<any, Input<any>> }>(
      objectMapValues(inputs, (provider, key) =>
        provider(initial[<keyof C>key], (value) => {
          setValue((values) => ({ ...values, [key]: <any>value(values[key]) }));
        }),
      )
    );
    return incCompose(
      incObject(controls),
      incValue((inputs): GroupInput<C> => {
        const value = <{ [K in keyof C]: InputV<C[K]> }>(
          objectMapValues(inputs, (input) => (<Input<any>>input).value)
        );
        const errors = validator(value);
        return {
          errors,
          allErrors: [
            ...Object.values(inputs).flatMap(
              (input) => (<Input<any>>input).allErrors,
            ),
            ...errors,
          ],
          changed: Object.values(inputs).some(
            (input) => (<Input<any>>input).changed,
          ),
          setValue(value) {
            setValue(() => value);
          },
          inputs: <C>inputs,
          value,
        };
      }),
    );
  };
}

/**
 * Single-value input
 */
export function input<T>({
  equal = identityEqual,
  validator = noopValidator(),
}: { equal?: Equal<T>; validator?: Validator<T> } = {}): InputProvider<T> {
  return (initial, setValue) =>
    incValue<T, Input<T>>((value) => {
      const errors = validator(value);
      return {
        changed: !equal(initial, value),
        allErrors: errors,
        errors,
        value,
        setValue(value) {
          setValue(() => value);
        },
      };
    }, equal);
}

/**
 * Dynamically-sized list of inputs
 */
export function listInput<K, V, C extends Input<V>>(
  fn: (key: K) => InputProvider<V, C>,
  initialFn: (key: K) => V,
  keyFn: ArrayKeyFn<V, K>,
  { validator = noopValidator() }: { validator?: Validator<V[]> } = {},
): InputProvider<V[], ListInput<V, C>> {
  return (initial, setValue) => {
    const keyedInitial = new Map(
      initial.map((value, index) => [keyFn(value, index), value]),
    );
    return incCompose(
      incArray(
        (key) =>
          fn(key)(
            keyedInitial.has(key) ? keyedInitial.get(key)! : initialFn(key),
            (value) =>
              setValue((values) =>
                values.map((v, index) =>
                  Object.is(key, keyFn(v, index)) ? value(v) : v,
                ),
              ),
          ),
        keyFn,
      ),
      incValue((inputs) => {
        const value = inputs.map((input) => input.value);
        const changed =
          initial.length !== inputs.length ||
          inputs.some(
            (input, index) =>
              input.changed ||
              !Object.is(
                keyFn(initial[index], index),
                keyFn(input.value, index),
              ),
          );
        const errors = validator(value);
        return {
          changed,
          allErrors: [...inputs.flatMap((input) => input.allErrors), ...errors],
          errors,
          setValue(value) {
            setValue(() => value);
          },
          inputs,
          value,
        };
      }),
    );
  };
}

/**
 * Requires non-empty string or array
 */
export const nonEmptyValidator: Validator<
  string | readonly unknown[] | undefined
> = (value) => {
  const errors: ValidationError[] = [];
  if (!value?.length) {
    errors.push("Required");
  }
  return errors;
};

export const notUndefinedValidator: Validator<any> = (value) => {
  const errors: ValidationError[] = [];
  if (value === undefined) {
    errors.push("Required");
  }
  return errors;
};

/**
 * Requires string that is empty or has at least 1 non-whitespace character
 */
export const nonWhitespaceValidator: Validator<string> = (value) => {
  const errors: ValidationError[] = [];
  if (value && !value.trim()) {
    errors.push("Cannot be empty");
  }
  return errors;
};

export const twoDigitStringValidator: Validator<string[]> = (value) => {
  const errors: ValidationError[] = [];

  for (const str of value) {
    if (!/^\d\d$/.test(str)) {
      errors.push("Please enter a 2 digit number");
    }
  }
  return errors;
};

/**
 * Enforces min, max, and decimal place requirements on numbers
 */
export const numberValidator = ({
  min,
  max,
  maxDecimalPlaces,
}: {
  min?: number;
  max?: number;
  maxDecimalPlaces?: number;
}): Validator<string | number> => {
  return (value) => {
    const errors: ValidationError[] = [];
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      errors.push("Must be a valid number");
    } else {
      if (min !== undefined && min > numberValue) {
        errors.push(`Must be greater than ${min}`);
      }
      if (max !== undefined && max < numberValue) {
        errors.push(`Must be less than ${max}`);
      }
      if (maxDecimalPlaces !== undefined) {
        const decimalPlaces = value.toString().split(".")[1]?.length || 0;
        if (decimalPlaces > maxDecimalPlaces) {
          errors.push(`Must have ${maxDecimalPlaces} or fewer decimal places`);
        }
      }
    }
    return errors;
  };
};

/**
 * No-op validator
 */
export function noopValidator<T>(): Validator<T> {
  return noopValidator_;
}

const noopValidator_ = () => [];

/**
 * Requires valid phone number. Allows empty string.
 */
export const phoneNumberValidator = (value: string) => {
  const errors = [];
  if (value && !validator.isMobilePhone(value)) {
    errors.push(`${value} is not a valid phone number`);
  }
  return errors;
};

/**
 * Set state action
 */
export interface SetState<T> {
  (fn: (existing: T) => T): void;
}

/**
 * Validator
 */
export interface Validator<T> {
  (value: T): ValidationError[];
}

export type ValidationError = string;

/**
 * Combine multiple validators
 */
export function validatorAll<T>(validators: Validator<T>[]): Validator<T> {
  return (value) => validators.flatMap((validator) => validator(value));
}

export function numberToStringInput(input: Input<number>): Input<string> {
  return {
    ...input,
    value: input.value.toString(),
    setValue(value) {
      input.setValue(Number(value));
    },
  };
}
