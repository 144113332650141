import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { FormSwitch } from "@redotech/redo-web/switch";
import { memo } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../team";
import { OutboundLabelForm } from "../outbound-labels";

export const OutboundLabelsGeneral = memo(function OutboundLabelsGeneral({
  input,
}: {
  input: OutboundLabelForm;
}) {
  const { newOmsEnabled } = input.inputs;

  const client = useRequiredContext(RedoAdminRpcClientContext);
  const team = useRequiredContext(TeamContext);

  const [refreshShipmentRatesLoad, triggerRefreshShipmentRatesLoad] =
    useTriggerLoad<boolean>(async () => {
      const result = await client.refreshShipmentRates({ teamId: team._id });
      return result.success;
    });

  const [fastSyncFulfillmentsLoad, triggerFastSyncFulfillmentsLoad] =
    useTriggerLoad<boolean>(async () => {
      const result = await client.fastSyncFulfillments({ teamId: team._id });
      return result.success;
    });

  return (
    <Card title="General">
      <Flex dir="row" justify="space-between">
        <FormSwitch input={newOmsEnabled} label="Enable New OMS" />
        <Flex dir="column">
          <RedoButton
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={() => {
              triggerRefreshShipmentRatesLoad();
            }}
            pending={refreshShipmentRatesLoad.pending}
            size={RedoButtonSize.REGULAR}
            text="Refresh Shipment Rates"
            theme={RedoButtonTheme.NORMAL}
          />
          <RedoButton
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={() => {
              triggerFastSyncFulfillmentsLoad();
            }}
            pending={fastSyncFulfillmentsLoad.pending}
            size={RedoButtonSize.REGULAR}
            text="Fast Sync Fulfillments"
            theme={RedoButtonTheme.NORMAL}
          />
        </Flex>
      </Flex>
    </Card>
  );
});
