// extracted by mini-css-extract-plugin
export var chevron = "_26-a";
export var disabled = "_26-9";
export var icon = "_26-b";
export var input = "_26-0";
export var open = "_26-7";
export var placeholder = "_26-8";
export var regular = "_26-3";
export var singleSelectInput = "_26-1 _26-0";
export var small = "_26-2";
export var spinnerContainer = "_26-c";
export var tag = "_26-5";
export var tagsContainer = "_26-6";
export var tagsInput = "_26-4 _26-0";