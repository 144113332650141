// extracted by mini-css-extract-plugin
export var box = "_v-1";
export var checked = "_v-5";
export var descriptionSpacer = "_v-9";
export var disabled = "_v-4";
export var icon = "_v-6";
export var input = "_v-0";
export var labelSpacer = "_v-8";
export var medium = "_v-3";
export var small = "_v-2";
export var text = "_v-7";