import { genericMemo, IterableMap } from "@redotech/react-util/component";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { Flex } from "../../flex";
import { Spinner } from "../../spinner";
import { Text } from "../../text";
import { PaddingProps, paddingPropsKeys, SpacingValue } from "../../theme/box";
import * as redoDropdownInputCss from "../select-dropdown/redo-dropdown-input.module.css";
import {
  RedoListItem,
  RedoListItemMenu,
  RedoListItemSize,
  RedoListItemVariant,
} from "./redo-list-item";
import * as redoListCss from "./redo-list.module.css";

export interface RedoListItem<T> {
  label?: ReactNode;
  id?: string;
  value: T;
  menu?: RedoListItemMenu;
  suffix?: ReactNode;
}

export enum RedoListItemSelectedSource {
  Keyboard = "keyboard",
  Mouse = "mouse",
}

/**
 * @param refToListenTo -- this can be an item like a button or an input.
 * After the button is clicked or the input receives focus,
 * keyboard navigation will be enabled for the list.
 */
export interface RedoListProps<T> {
  items: RedoListItem<T>[];
  itemSelected(
    item: RedoListItem<T>,
    source: RedoListItemSelectedSource,
    index: number,
  ): void;
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  selectionVariant?: RedoListItemVariant;
  children(item: RedoListItem<T>): ReactNode;
  isItemSelected?(item: RedoListItem<T>): boolean;
  keyFn?: (item: RedoListItem<T>, index: number) => string | number;
  size?: RedoListItemSize;
  containerClassName?: string;
  gap?: SpacingValue;
  isItemDisabled?(item: RedoListItem<T>): boolean;
  itemsLoading?: boolean;
  emptyListMessage?: string;
}

export const RedoList = genericMemo(function RedoList<T>({
  size = RedoListItemSize.MEDIUM,
  items,
  itemsLoading = false,
  itemSelected,
  isItemSelected = () => false,
  focusedIndex,
  setFocusedIndex,
  refToListenTo,
  children,
  selectionVariant = RedoListItemVariant.CHECKMARK,
  keyFn = (_, idx) => idx,
  containerClassName,
  gap,
  isItemDisabled = () => false,
  emptyListMessage = "No items",
  ...otherProps
}: RedoListProps<T> & PaddingProps) {
  const paddingProps = Object.fromEntries(
    Object.entries(otherProps).filter(([key]) =>
      paddingPropsKeys.includes(key as any),
    ),
  ) as PaddingProps;
  // FIXME
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleKeyPress(event: KeyboardEvent | React.KeyboardEvent) {
    if (event.key === EnterKey) {
      event.preventDefault();
      if (focusedIndex === undefined) {
        return;
      }
      event.stopPropagation();
      const selectedItem = items[focusedIndex];
      if (!isItemDisabled(selectedItem)) {
        itemSelected(
          selectedItem,
          RedoListItemSelectedSource.Keyboard,
          focusedIndex,
        );
      }
    } else if (event.key === ArrowDown) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      let newIndex = Math.min(oldIndex + 1, items.length - 1);
      while (newIndex < items.length && isItemDisabled(items[newIndex])) {
        newIndex++;
      }
      if (newIndex < items.length) {
        setFocusedIndex(newIndex);
      }
    } else if (event.key === ArrowUp) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? items.length;
      let newIndex = Math.min(oldIndex - 1, items.length - 1);
      while (newIndex >= 0 && isItemDisabled(items[newIndex])) {
        newIndex--;
      }
      if (newIndex >= 0) {
        setFocusedIndex(newIndex);
      } else {
        setFocusedIndex(undefined);
      }
    }
  }

  useEffect(() => {
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
    };
  }, [refToListenTo, handleKeyPress]);

  return (
    <div className={containerClassName}>
      {itemsLoading ? (
        <Flex justify="center">
          <Flex className={redoDropdownInputCss.spinnerContainer}>
            <Spinner />
          </Flex>
        </Flex>
      ) : (
        <Flex
          className={redoListCss.childrenContainer}
          dir="column"
          gap={gap}
          onKeyDown={handleKeyPress}
          {...paddingProps}
          tabIndex={0}
        >
          {items.length === 0 && (
            <RedoListItem
              disabled
              focused={false}
              itemClicked={() => {}}
              menu={undefined}
              onItemHovered={() => {}}
              selected={false}
              selectionVariant={selectionVariant}
              size={size}
            >
              <Text>{emptyListMessage}</Text>
            </RedoListItem>
          )}
          <IterableMap items={items} keyFn={keyFn}>
            {(item, idx) => {
              const node = children(item);
              const disabled = isItemDisabled(item);
              return (
                <RedoListItem
                  disabled={disabled}
                  focused={idx === focusedIndex}
                  itemClicked={() => {
                    if (!disabled) {
                      itemSelected(item, RedoListItemSelectedSource.Mouse, idx);
                    }
                  }}
                  menu={item.menu}
                  onItemHovered={(hovered) => {
                    if (hovered && !disabled) {
                      setFocusedIndex(idx);
                    } else {
                      setFocusedIndex((currentFocusedIndex) => {
                        const thisItemIsFocused = currentFocusedIndex === idx;
                        return thisItemIsFocused
                          ? undefined
                          : currentFocusedIndex;
                      });
                    }
                  }}
                  selected={isItemSelected(item)}
                  selectionVariant={selectionVariant}
                  size={size}
                  suffix={item.suffix}
                >
                  {node}
                </RedoListItem>
              );
            }}
          </IterableMap>
        </Flex>
      )}
    </div>
  );
});
