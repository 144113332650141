export const DEFAULT_TOGGLE_SELECTOR =
  window.Shopify?.theme?.name === "test-data" ||
  window.Shopify?.theme?.name === "Dawn"
    ? ".cart__ctas"
    : '[name="checkout"]:not([type="hidden"])';

export const DEFAULT_TOGGLE_CLICK_SELECTOR = [
  "#addToCart",
  "#addToCart *",
  ".ajax-cart__item-quantity button",
  ".ajax-cart__item-quantity button *",
  'a[href="/cart"]',
  'a[href="/cart"] *',
  ".ajax-cart__item-remove",
  ".ajax-cart__item-remove *",
  ".product-form__submit",
  ".product-form__submit *",
  '[name="add"]',
  '[name="add"] *',
  "[data-add-to-cart-trigger]",
  "[data-add-to-cart-trigger] *",
  "cart-remove-button",
  "cart-remove-button *",
].join(", ");

export const DEFAULT_VARIANT_SELECTOR = [
  // highly generic selectors
  '.cart-item:has([href*="%variant_id%"])',
  '.CartItem:has([href*="%variant_id%"])',
  '.CartItemWrapper:has([href*="%variant_id%"])',
  '.cart_item:has([href*="%variant_id%"])',
  '.cart__item:has([href*="%variant_id%"])',
  '.cart-row:has([href*="%variant_id%"])',
  '.cart--item:has([href*="%variant_id%"])',
  '.cart__row:has([href*="%variant_id%"])',
  '.cart-line-item:has([href*="%variant_id%"])',
  '.cart-summary-item:has([href*="%variant_id%"])',
  '.cart-summary-item-container:has([href*="%variant_id%"])',
  '.cart__items__row:has([href*="%variant_id%"])',
  '.cart-summary__product:has([href*="%variant_id%"])',
  '.cart__row[data-cart-item-url*="%variant_id%"]',
  '.cart_items > div:has([href*="%variant_id%"])',
  '.line-item:has([href*="%variant_id%"])',
  '.Card--product:has([href*="%variant_id%"])',
  '.product-cart-item:has([href*="%variant_id%"])',
  '.cart-product-item:has([href*="%variant_id%"])',
  '.cart-product:has([href*="%variant_id%"])',
  '.cart-item-container:has([href*="%variant_id%"])',
  '.ajax-cart__cart-item:has([href*="%variant_id%"])',
  '.ajaxcart__product:has([href*="%variant_id%"])',
  '.ajaxcart__row:has([href*="%variant_id%"])',
  '[action="/cart"] tr:has([href*="%variant_id%"])',
  '[action="/cart"] li:has([href*="%variant_id%"])',
  // more specific selectors but unlikely to have false positives
  '.upcart-product-item:has([href*="%variant_id%"])',
  '.upsell-cart__item:has([href*="%variant_id%"])',
  '.cart-sidebar-item:has([href*="%variant_id%"])',
  '.mini-cart__item:has([href*="%variant_id%"])',
  '.minicart__entry:has([href*="%variant_id%"])',
  '.cart-drawer__inner__item:has([href*="%variant_id%"])',
  '.quick-cart__item:has([href*="%variant_id%"])',
  '.rebuy-cart__flyout-item:has([href*="%variant_id%"])',
  '.js__cart-table-item-row:has([href*="%variant_id%"])',
  '.eam-cart-item:has([href*="%variant_id%"])',
  'div[data-hulkapps-lineitem]:has([href*="%variant_id%"])',
  '.mu-cart-item:has([href*="%variant_id%"])',
  '.boost-pfs-minicart-item:has([href*="%variant_id%"])',
  '.boost-sd__cart-item:has([href*="%variant_id%"])',
  '.f-cart-drawer-item:has([href*="%variant_id%"])',
  '.mini-products-list .item:has([href*="%variant_id%"])',
  '[class*="CartItem_item_wrapper" i]:has([href*="%variant_id%"])',
  ".hs-product-%variant_id%",
].join(", ");

export const DEFAULT_PRODUCT_SELECTOR = [
  // highly generic selectors
  '.cart-item:has([href*="%handle%"])',
  '.CartItem:has([href*="%handle%"])',
  '.CartItemWrapper:has([href*="%handle%"])',
  '.cart_item:has([href*="%handle%"])',
  '.cart__item:has([href*="%handle%"])',
  '.cart-row:has([href*="%handle%"])',
  '.cart--item:has([href*="%handle%"])',
  '.cart__row:has([href*="%handle%"])',
  '.cart-line-item:has([href*="%handle%"])',
  '.cart-summary-item:has([href*="%handle%"])',
  '.cart-summary-item-container:has([href*="%handle%"])',
  '.cart__items__row:has([href*="%handle%"])',
  '.cart-summary__product:has([href*="%handle%"])',
  '.cart__row[data-cart-item-url*="%handle%"]',
  '.cart_items > div:has([href*="%handle%"])',
  '.line-item:has([href*="%handle%"])',
  '.Card--product:has([href*="%handle%"])',
  '.product-cart-item:has([href*="%handle%"])',
  '.cart-product-item:has([href*="%handle%"])',
  '.cart-product:has([href*="%handle%"])',
  '.cart-item-container:has([href*="%handle%"])',
  '.ajax-cart__cart-item:has([href*="%handle%"])',
  '.ajaxcart__product:has([href*="%handle%"])',
  '.ajaxcart__row:has([href*="%handle%"])',
  'div[data-vendor="re:do"]',
  '[data-variant="%variant_id%"]',
  '[data-variant-id="%variant_id%"]',
  '[data-product="%product_id%"]',
  '[data-product="%variant_id%"]',
  '[data-product-id="%product_id%"]',
  '[data-cartitem-id="%variant_id%"]',
  '[data-handle="%handle%"]',
  '[data-id="%variant_id%"]',
  ".product-%handle%",
  ".%handle%",
  '[action="/cart"] tr:has([href*="%handle%"])',
  '[action="/cart"] li:has([href*="%handle%"])',
  // more specific selectors but unlikely to have false positives
  '.upcart-product-item:has([href*="%handle%"])',
  '.upsell-cart__item:has([href*="%handle%"])',
  '.cart-sidebar-item:has([href*="%handle%"])',
  '.mini-cart__item:has([href*="%handle%"])',
  '.minicart__entry:has([href*="%handle%"])',
  '.cart-drawer__inner__item:has([href*="%handle%"])',
  '.quick-cart__item:has([href*="%handle%"])',
  '.rebuy-cart__flyout-item:has([href*="%handle%"])',
  '.js__cart-table-item-row:has([href*="%handle%"])',
  '.eam-cart-item:has([href*="%handle%"])',
  'div[data-hulkapps-lineitem]:has([href*="%handle%"])',
  '.mu-cart-item:has([href*="%handle%"])',
  '.boost-pfs-minicart-item:has([href*="%handle%"])',
  '.boost-sd__cart-item:has([href*="%handle%"])',
  '.unicart__row[data-vendor="re:do"]',
  '.f-cart-drawer-item:has([href*="%handle%"])',
  '.mini-products-list .item:has([href*="%handle%"])',
  "[data-merge-list-item]:has(.product-%handle%)",
  '[data-slidecart-product-id="%product_id%"]',
  '[class*="CartItem_item_wrapper" i]:has([href*="%handle%"])',
  ".hs-product-%variant_id%",
].join(", ");

// Could be simplified with selectors like:
// [class*="cartcount" i]
// [class*="cart-count" i]
// but that might result in false positives
export const DEFAULT_CART_COUNT_SELECTOR = [
  ".cart-link__count",
  ".cart-count-bubble",
  ".cart_count",
  ".cart-count",
  ".cartCount",
  ".count-bubble",
  ".cart-count__text",
  ".cart-drawer__title-count",
  ".cart-link__bubble-num",
  ".current-cart-count",
  ".cart__count--text",
  ".cart--external--total-items",
  ".cart-drawer__item-qty",
  ".cart-item-count",
  ".cart__form-item-count",
  ".rebuy-cart__flyout-subtotal-label",
  ".thb-item-count",
  ".upcart-header-text",
  ".site-header-cart--button",
  ".cart-summary__item-list-header__count",
  ".item__count",
  ".item_count",
  ".numitems",
  ".site-header-cart--count",
  ".site-header__cart-count",
  ".cart-link__bubble",
  ".cart-count-js",
  ".header-cart__count",
  ".header__cart__count",
  ".CartCount",
  ".cart-count-badge",
  ".js-cart-count",
  "#cart-notification-button",
  "#CartCount",
  "#cartCount",
  "#cartCountSelector",
  "cart-count", // element, not class
  "[data-cart-count]",
  "[data-cart-popup-cart-quantity]",
  "[data-js-cart-count]",
  '[href="/cart"] [class*="count" i]',
  '[href="/cart"] [id*="count" i]',
].join(", ");

export const DEFAULT_CHECKOUT_BUTTONS_SELECTOR = [
  '[name="checkout"]',
  ".rebuy-button.rebuy-cart__checkout-button",
  ".ajax-cart__checkout",
  ".upcart-checkout-button",
].join(", ");

export const DEFAULT_RIC_PLACEMENT_SELECTOR =
  window.Shopify?.theme?.name === "test-data" ||
  window.Shopify?.theme?.name === "Dawn"
    ? ".cart__ctas"
    : [
        '[name="checkout"]',
        ".rebuy-cart__flyout-actions",
        ".ajax-cart__subtotal",
        ".upcart-checkout-button-container",
      ].join(", ");
