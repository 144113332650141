import { assertNever } from "@redotech/util/type";
import {
  OutboundWeightByZoneRateTable,
  RateTableValueMode,
} from "./rate-table";

export enum RateTableCarrier {
  USPS = "USPS",
  FEDEX = "FedEx",
}

export enum USPSRateTableServiceLevel {
  Default = "Default",
  GroundAdvantage = "Ground Advantage",
}

export enum FedexRateTableServiceLevel {
  Default = "Default",
  SmartPost = "Smart Post",
  OneRateExpressSaver = "One Rate Express Saver",
  OneRateTwoDay = "One Rate Two Day",
  OneRateTwoDayAM = "One Rate Two Day AM",
  OneRateStandard = "One Rate Standard Overnight",
  OneRatePriorityOvernight = "One Rate Priority Overnight",
  OneRateFirstOvernight = "One Rate First Overnight",
}

export type RateTableServiceLevel =
  | USPSRateTableServiceLevel
  | FedexRateTableServiceLevel;

type RateTableServiceLevels = {
  [RateTableCarrier.USPS]: USPSRateTableServiceLevel;
  [RateTableCarrier.FEDEX]: FedexRateTableServiceLevel;
};

export const RateTableServiceLevelsMapping: Record<
  RateTableCarrier,
  RateTableServiceLevels[RateTableCarrier][]
> = {
  [RateTableCarrier.USPS]: Object.values(USPSRateTableServiceLevel),
  [RateTableCarrier.FEDEX]: Object.values(FedexRateTableServiceLevel),
};

export function defaultCarrierRateTableServiceLevel(
  carrier: RateTableCarrier,
): RateTableServiceLevel {
  switch (carrier) {
    case RateTableCarrier.USPS:
      return USPSRateTableServiceLevel.Default;
    case RateTableCarrier.FEDEX:
      return FedexRateTableServiceLevel.Default;
    default:
      assertNever(carrier);
  }
}

export type RateTableKeys = {
  carrier: RateTableCarrier;
  serviceLevel: RateTableServiceLevel;
};

export enum EasypostFedexServiceLevel {
  SmartPost = "FEDEX_SMART_POST",
  ExpressSaver = "FEDEX_EXPRESS_SAVER",
  TwoDay = "FEDEX_2_DAY",
  TwoDayAM = "FEDEX_2_DAY_AM",
  StandardOvernight = "STANDARD_OVERNIGHT",
  PriorityOvernight = "PRIORITY_OVERNIGHT",
  FirstOvernight = "FIRST_OVERNIGHT",
}

export enum EasypostUSPSRateTableServiceLevel {
  Ground = "GroundAdvantage",
}

// Determines what rate table keys to use given the carrier and service level from easypost
export function getRateTableKeysFromRate(
  carrier: string,
  serviceLevel: string,
): RateTableKeys | null {
  if (carrier === "FedExSmartPost") {
    return {
      carrier: RateTableCarrier.FEDEX,
      serviceLevel: FedexRateTableServiceLevel.SmartPost,
    };
  }

  if (carrier === RateTableCarrier.FEDEX) {
    if (serviceLevel === EasypostFedexServiceLevel.SmartPost) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.SmartPost,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.ExpressSaver) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRateExpressSaver,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.TwoDay) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRateTwoDay,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.TwoDayAM) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRateTwoDayAM,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.PriorityOvernight) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRatePriorityOvernight,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.StandardOvernight) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRateStandard,
      };
    } else if (serviceLevel === EasypostFedexServiceLevel.FirstOvernight) {
      return {
        carrier: RateTableCarrier.FEDEX,
        serviceLevel: FedexRateTableServiceLevel.OneRateFirstOvernight,
      };
    }

    return {
      carrier: RateTableCarrier.FEDEX,
      serviceLevel: FedexRateTableServiceLevel.Default,
    };
  }

  if (carrier === RateTableCarrier.USPS) {
    if (serviceLevel === EasypostUSPSRateTableServiceLevel.Ground) {
      return {
        carrier: RateTableCarrier.USPS,
        serviceLevel: USPSRateTableServiceLevel.GroundAdvantage,
      };
    }

    return {
      carrier: RateTableCarrier.USPS,
      serviceLevel: USPSRateTableServiceLevel.Default,
    };
  }

  return null;
}

export function getDefaultRateTableServiceLevel(
  carrier: RateTableCarrier,
): RateTableServiceLevel {
  switch (carrier) {
    case RateTableCarrier.USPS:
      return USPSRateTableServiceLevel.Default;
    case RateTableCarrier.FEDEX:
      return FedexRateTableServiceLevel.Default;
    default:
      assertNever(carrier);
  }
}

export function isDefaultServiceLevel(
  serviceLevel: RateTableServiceLevel,
): boolean {
  const defaults = [
    FedexRateTableServiceLevel.Default,
    USPSRateTableServiceLevel.Default,
  ];
  return defaults.includes(serviceLevel);
}

export function findMatchingOutboundRateTable(
  rateTableKeys: RateTableKeys,
  rateTables: OutboundWeightByZoneRateTable[],
  valueMode: RateTableValueMode,
): OutboundWeightByZoneRateTable | null {
  return (
    rateTables.find(
      (table: OutboundWeightByZoneRateTable) =>
        table.carrier === rateTableKeys.carrier &&
        table.serviceLevel === rateTableKeys.serviceLevel &&
        table.valueMode === valueMode,
    ) ?? null
  );
}
