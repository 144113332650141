// extracted by mini-css-extract-plugin
export var border = "_y-5";
export var button = "_y-g";
export var center = "_y-7";
export var centered = "_y-3";
export var closeButton = "_y-9";
export var disabledWrapper = "_y-h";
export var error = "_y-k";
export var fill = "_y-d";
export var footer = "_y-c";
export var footerAction = "_y-e";
export var footerButtons = "_y-f";
export var header = "_y-4";
export var icon = "_y-j";
export var info = "_y-n";
export var inline = "_y-6";
export var large = "_y-2";
export var main = "_y-a";
export var modal = "_y-0";
export var none = "_y-o";
export var scrollable = "_y-b";
export var small = "_y-1";
export var success = "_y-m";
export var tight = "_y-i";
export var titleIcon = "_y-8";
export var warn = "_y-l";