import { memo, useEffect, useState } from "react";
import { Flex } from "../flex";
import { Text, TextProps } from "../text";

import { useHandler } from "@redotech/react-util/hook";
import * as textMeasureBoxCss from "./text-measure-box.module.css";

export const TextMeasureBox = memo(function TextMeasureBox({
  text,
  textWidth,
  setTextWidth,
  ...textProps
}: Omit<TextProps, "fontFamily" | "lineHeight"> & {
  text: string;
  textWidth: number;
  setTextWidth: (width: number) => void;
}) {
  const [measureBox, setMeasureBox] = useState<HTMLDivElement | null>(null);

  const recalculateInputWidth = useHandler(() => {
    requestAnimationFrame(() => {
      if (!measureBox) {
        return;
      }
      const width = measureBox.offsetWidth;
      setTextWidth(width);
    });
  });

  useEffect(() => {
    recalculateInputWidth();
  }, [measureBox, text, recalculateInputWidth]);

  const textWithHtmlSpaces = text.replace(/ /g, "\u00a0");

  return (
    <Flex className={textMeasureBoxCss.measureBox}>
      <Text {...textProps} ref={setMeasureBox}>
        {textWithHtmlSpaces}
      </Text>
    </Flex>
  );
});
