// extracted by mini-css-extract-plugin
export var grid = "_1-0";
export var span1 = "_1-2";
export var span10 = "_1-b";
export var span10L = "_1-z";
export var span10M = "_1-n";
export var span10Xl = "_1-1b";
export var span11 = "_1-c";
export var span11L = "_1-10";
export var span11M = "_1-o";
export var span11Xl = "_1-1c";
export var span12 = "_1-d";
export var span12L = "_1-11";
export var span12M = "_1-p";
export var span12Xl = "_1-1d";
export var span1L = "_1-q";
export var span1M = "_1-e";
export var span1Xl = "_1-12";
export var span2 = "_1-3";
export var span2L = "_1-r";
export var span2M = "_1-f";
export var span2Xl = "_1-13";
export var span3 = "_1-4";
export var span3L = "_1-s";
export var span3M = "_1-g";
export var span3Xl = "_1-14";
export var span4 = "_1-5";
export var span4L = "_1-t";
export var span4M = "_1-h";
export var span4Xl = "_1-15";
export var span5 = "_1-6";
export var span5L = "_1-u";
export var span5M = "_1-i";
export var span5Xl = "_1-16";
export var span6 = "_1-7";
export var span6L = "_1-v";
export var span6M = "_1-j";
export var span6Xl = "_1-17";
export var span7 = "_1-8";
export var span7L = "_1-w";
export var span7M = "_1-k";
export var span7Xl = "_1-18";
export var span8 = "_1-9";
export var span8L = "_1-x";
export var span8M = "_1-l";
export var span8Xl = "_1-19";
export var span9 = "_1-a";
export var span9L = "_1-y";
export var span9M = "_1-m";
export var span9Xl = "_1-1a";
export var stretch = "_1-1";