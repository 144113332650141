// extracted by mini-css-extract-plugin
export var chevron = "_28-a";
export var disabled = "_28-9";
export var icon = "_28-b";
export var input = "_28-0";
export var open = "_28-7";
export var placeholder = "_28-8";
export var regular = "_28-3";
export var singleSelectInput = "_28-1 _28-0";
export var small = "_28-2";
export var spinnerContainer = "_28-c";
export var tag = "_28-5";
export var tagsContainer = "_28-6";
export var tagsInput = "_28-4 _28-0";