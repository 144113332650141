import { genericMemo } from "@redotech/react-util/component";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import * as classNames from "classnames";
import { ReactNode, useState } from "react";
import { Icon } from "../../icon";
import { Text } from "../../text";
import { RedoListItem } from "../list/redo-list";
import { RedoListItemSize } from "../list/redo-list-item";
import { RedoSingleSelectDropdown } from "../select-dropdown/redo-single-select-dropdown";
import * as baseRedoInputDropdownCss from "./base-redo-input-dropdown.module.css";
import { RedoInputSize, RedoInputState } from "./base-redo-text-input";

export const BaseRedoInputDropdown = genericMemo(function BaseRedoInputDropdown<
  T,
>({
  options,
  optionSelected,
  children,
  selectedItem,
  placeholder,
  size,
  state,
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
}: {
  size: RedoInputSize;
  options: RedoListItem<T>[];
  optionSelected(value: RedoListItem<T>): void;
  children(item: RedoListItem<T>): ReactNode;
  selectedItem?: RedoListItem<T>;
  state?: RedoInputState;
  placeholder?: string;
  className?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
}) {
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const sizeClass =
    size === RedoInputSize.SMALL
      ? baseRedoInputDropdownCss.small
      : baseRedoInputDropdownCss.regular;

  const disabled = state === RedoInputState.DISABLED;

  if (state === RedoInputState.READONLY) {
    return selectedItem ? (
      children(selectedItem)
    ) : (
      <Text textColor="tertiary">{placeholder}</Text>
    );
  }

  const { backgroundColor } =
    dangerousStyleThatShouldOnlyBeUsedForMerchantBranding || {};
  const buttonOverrideStyles = { backgroundColor };

  return (
    <>
      <button
        className={classNames(
          baseRedoInputDropdownCss.input,
          sizeClass,
          disabled ? baseRedoInputDropdownCss.disabled : "",
        )}
        disabled={disabled}
        ref={setDropdownButtonRef}
        style={buttonOverrideStyles}
        type="button"
      >
        {selectedItem ? (
          children(selectedItem)
        ) : (
          <Text textColor="tertiary">{placeholder}</Text>
        )}
        <Icon
          arbiterIconSvg={dropdownOpen ? ChevronUpIcon : ChevronDownIcon}
          className={baseRedoInputDropdownCss.chevron}
          color="ghost"
        />
      </button>
      <RedoSingleSelectDropdown<T>
        dropdownButtonRef={dropdownButtonRef}
        fitToAnchor={false}
        onDropdownToggled={setDropdownOpen}
        options={options}
        optionSelected={optionSelected}
        selectedItem={options.find(
          (item) => item.value === selectedItem?.value,
        )}
        size={
          size === RedoInputSize.SMALL
            ? RedoListItemSize.SMALL
            : RedoListItemSize.MEDIUM
        }
      >
        {(item) => children(item)}
      </RedoSingleSelectDropdown>
    </>
  );
});
