// extracted by mini-css-extract-plugin
export var background = "_2a-0";
export var container = "_2a-1";
export var contentItem = "_2a-7";
export var extendedWarranty = "_2a-6";
export var modal = "_2a-b";
export var modalCloseButton = "_2a-9";
export var modalContent = "_2a-3";
export var modalContentMain = "_2a-5";
export var modalImageLogo = "_2a-8";
export var modalLogo = "_2a-a";
export var modalSideImage = "_2a-4";
export var preview = "_2a-c";
export var redoModal = "_2a-2";