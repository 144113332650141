import { CheckoutOptimizationZodSchema } from "@redotech/redo-model/team";
import { z } from "zod";

export const updateConversionSettings = {
  input: z.object({
    teamId: z.string(),
    checkoutOptimization: CheckoutOptimizationZodSchema,
  }),
  output: z.null(),
};
