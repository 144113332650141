// extracted by mini-css-extract-plugin
export var actions = "_d-0";
export var actionsContainer = "_d-3";
export var border = "_d-1";
export var childFullHeight = "_d-9";
export var collapse = "_d-2";
export var compact = "_d-e";
export var content = "_d-4";
export var contentChild = "_d-8";
export var fullscreen = "_d-6";
export var header = "_d-a";
export var headerContainer = "_d-g";
export var heightAuto = "_d-c";
export var hideHeaderBorder = "_d-f";
export var hidePadding = "_d-5";
export var noScrollX = "_d-h";
export var noScrollY = "_d-i";
export var optOutTopPadding = "_d-7";
export var profile = "_d-j";
export var profileContainer = "_d-k";
export var profileImage = "_d-l";
export var profileMain = "_d-m";
export var profileSubtitle = "_d-o";
export var profileTitle = "_d-n";
export var scrolledTop = "_d-d";
export var tabsWrapper = "_d-q";
export var title = "_d-p";
export var withTabs = "_d-b";