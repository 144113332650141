// extracted by mini-css-extract-plugin
export var arrows = "_1o-5";
export var decrementer = "_1o-9";
export var disabled = "_1o-3";
export var error = "_1o-1";
export var hiddenText = "_1o-a";
export var incrementer = "_1o-8";
export var input = "_1o-7";
export var inputWrapper = "_1o-0";
export var readonly = "_1o-2";
export var regular = "_1o-6";
export var small = "_1o-4";