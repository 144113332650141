import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo } from "react";

export const supportGeneralForm = groupInput({ enabled: input<boolean>() });

export type SupportGeneralForm = InputProvider.Form<typeof supportGeneralForm>;

export type SupportGeneralValue = InputProvider.Value<
  typeof supportGeneralForm
>;

export const supportGeneralDefault: SupportGeneralValue = { enabled: false };

export const SupportGeneralCard = memo(function SupportGeneralCard({
  input,
}: {
  input: SupportGeneralForm;
}) {
  const { enabled } = input.inputs;

  return (
    <Card title="Customer Support">
      <section className={gridCss.grid}>
        <div className={gridCss.span6L}>
          <FormSwitch input={enabled} label="Enabled">
            Enable Customer Support product
          </FormSwitch>
        </div>
      </section>
    </Card>
  );
});
