import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import type { RouteObject } from "react-router-dom";

const auditLogBreadcrumb = breadcrumbSlot();

export const auditLogsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Audit Logs" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page /> };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { AuditLogsPage } = await import("./audit-logs");
          return { Component: AuditLogsPage };
        },
      },
      {
        path: ":auditLogId",
        handle: { breadcrumb: auditLogBreadcrumb },
        async lazy() {
          const { AuditLogPage } = await import("./audit-log");
          return { element: <AuditLogPage breadcrumb={auditLogBreadcrumb} /> };
        },
      },
    ],
  },
];
