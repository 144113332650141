// extracted by mini-css-extract-plugin
export var alternate = "_19-h";
export var black = "_19-3";
export var blue = "_19-d";
export var blueLight = "_19-c";
export var brand = "_19-7";
export var error = "_19-9";
export var gray = "_19-6";
export var grayBlue = "_19-b";
export var iconWrapper = "_19-4";
export var indigo = "_19-e";
export var orange = "_19-g";
export var pink = "_19-f";
export var primary = "_19-0";
export var purple = "_19-8";
export var success = "_19-5";
export var warning = "_19-a";
export var white = "_19-1";
export var whiteSuccess = "_19-2";