import { CountryCode } from "@redotech/locale/countries";
import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useEventListener } from "@redotech/react-util/event";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { StyleShadowRoot } from "@redotech/react-util/style";
import {
  CART_ATTRIBUTE_KEY,
  Collection,
  DEFAULT_TEXT,
  ExtendedWarrantyDataTarget,
  ExtendedWarrantyShopMetafield,
  ExtendedWarrantyShopMetafieldStyles,
  getCheapestOffering,
  NO_COVERAGE_TIME_ID,
  Offering,
  Selections,
  SHOP_METAFIELD_KEY,
  SHOP_METAFIELD_NAMESPACE,
  SHOP_METAFIELD_STYLES_KEY,
} from "@redotech/redo-model/extended-warranty";
import { ExtendedWarrantyCartToggleExperience } from "@redotech/redo-model/team";
import { calculateOfferings } from "@redotech/redo-shopify-utils/calculate-offerings";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import ShieldZapIconOutline from "@redotech/redo-web/arbiter-icon/shield-zap.svg";
import { RedoClientProvider } from "@redotech/redo-web/client";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import { ModalRootContext } from "@redotech/redo-web/modal-root";
import { Text } from "@redotech/redo-web/text";
import {
  ExtensionShopifyClient,
  ShopifyAjaxClient,
  ShopifyCartItem,
} from "@redotech/shopify-client/ajax";
import htmlReactParser from "html-react-parser";
import { memo, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { useDebounce } from "usehooks-ts";
import {
  ExtendedWarrantyModal,
  LearnMoreLink,
  ToggleAddButton,
} from "./app-blocks/extended-warranty-options/extended-warranty-options";
import {
  ShopifyExtensionClientContext,
  ShopifyExtensionClientProvider,
} from "./client/client-context";
import { getCollections } from "./client/shopify-extension-client";
import * as extendedWarrantyCss from "./extended-warranty.module.css";
import {
  DEFAULT_TOGGLE_CLICK_SELECTOR,
  DEFAULT_VARIANT_SELECTOR,
} from "./selectors";
import { ExtensionShopifyClientContext } from "./shopify";
import STYLE_MANAGER from "./style-manager";
import { useCart } from "./utils/cart";
export class ExtendedWarrantyCartToggleElement extends HTMLElement {
  #deregisterStyles?: () => void;
  #deregisterModalStyles?: () => void;

  #root!: ShadowRoot;
  #modalContainer!: HTMLElement;

  get root() {
    return this.#root;
  }

  get modalContainer() {
    return this.#modalContainer;
  }

  connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: "closed" });
    this.#root = shadowRoot;
    this.#deregisterStyles = STYLE_MANAGER.register(shadowRoot);
    const modalRoot = this.ownerDocument.createElement("div");
    modalRoot.setAttribute("id", "redo-modal-root");
    modalRoot.style.display = "initial";
    modalRoot.style.position = "absolute";
    modalRoot.style.zIndex = "50000";
    document.body.append(modalRoot);
    const modalShadowRoot = modalRoot.attachShadow({ mode: "open" });
    this.#deregisterModalStyles = STYLE_MANAGER.register(modalShadowRoot);
    this.#modalContainer = this.ownerDocument.createElement("div");
    modalShadowRoot.append(this.#modalContainer);
  }

  disconnectedCallback() {
    this.#deregisterStyles?.();
    this.#deregisterModalStyles?.();
    this.#modalContainer?.remove();
    this.dispatchEvent(new Event("disconnect"));
  }

  static #registered = false;

  static register() {
    if (!this.#registered) {
      customElements.define(this.TAG, this);
      this.#registered = true;
    }
  }

  static readonly TAG = "redo-extended-warranty-cart-toggle";
}

export function initialize(extensionShopifyClient: ExtensionShopifyClient) {
  if (window.blockRedo) {
    return;
  }
  ExtendedWarrantyCartToggleElement.register();
  const redoContainer = document.createElement(
    ExtendedWarrantyCartToggleElement.TAG,
  ) as ExtendedWarrantyCartToggleElement;
  redoContainer.id = "redo-extended-warranty-container";
  document.head.appendChild(redoContainer);
  const root = createRoot(redoContainer);
  root.render(
    <ExtensionShopifyClientContext.Provider value={extensionShopifyClient}>
      <RedoClientProvider url={process.env.REDO_API_URL!}>
        <ShopifyExtensionClientProvider
          storeUrl={Shopify?.shop}
          widgetId={window.redoWidgetId}
        >
          <ModalRootContext.Provider value={redoContainer.modalContainer}>
            <ExtendedWarrantyCartToggle />
          </ModalRootContext.Provider>
        </ShopifyExtensionClientProvider>
      </RedoClientProvider>
    </ExtensionShopifyClientContext.Provider>,
  );
}

// TODO: Get rid of by storing the title in the metafield
const getOfferingTitle = (
  collectionsOfferings: ExtendedWarrantyShopMetafield["collectionSettings"],
  collectionHandle: string,
  timeId: string,
) => {
  const collectionOfferings = collectionsOfferings.find(
    (c) => c.collectionHandle === collectionHandle,
  );
  if (!collectionOfferings) {
    return "";
  }
  const offering = collectionOfferings.offerings.find(
    (o) => o.timeId === timeId,
  );
  if (!offering) {
    return "";
  }
  return offering.timeTitle;
};

type TitledSelection = {
  collectionHandle: string;
  timeId: string;
  title: string;
};

export const ExtendedWarrantyCartToggle = memo(
  function ExtendedWarrantyCartToggle() {
    const shopifyAjaxClient = ShopifyAjaxClient.fromEnv();
    const shopifyClient = useRequiredContext(ExtensionShopifyClientContext);
    const redoShopifyExtensionClient = useRequiredContext(
      ShopifyExtensionClientContext,
    );
    const [cartRefreshSignal, setCartRefresh] = useState(Symbol());
    const cartRefresh = useDebounce(cartRefreshSignal, 500);
    const cart = useCart(cartRefresh);

    const settingsLoad = useLoad(async () => {
      const baseSettings = await shopifyClient.getShopMetafield({
        key: SHOP_METAFIELD_KEY,
        namespace: SHOP_METAFIELD_NAMESPACE,
      });

      const styleSettings = await shopifyClient.getShopMetafield({
        key: SHOP_METAFIELD_STYLES_KEY,
        namespace: SHOP_METAFIELD_NAMESPACE,
      });

      const baseSettingsValue = baseSettings?.metafield?.value;
      const styleSettingsValue = styleSettings?.metafield?.value;

      if (!baseSettingsValue) {
        return undefined;
      }

      const extendedWarrantySettings = JSON.parse(
        baseSettingsValue,
      ) as ExtendedWarrantyShopMetafield;

      let extendedWarrantyStyleSettings:
        | ExtendedWarrantyShopMetafieldStyles
        | {} = {};
      if (styleSettingsValue) {
        extendedWarrantyStyleSettings = JSON.parse(
          styleSettingsValue,
        ) as ExtendedWarrantyShopMetafieldStyles;
      }

      if (!extendedWarrantySettings) {
        return undefined;
      }

      return { ...extendedWarrantySettings, ...extendedWarrantyStyleSettings };
    }, [shopifyClient]);

    const collectionsLoad = useLoad(async () => {
      if (!cart) {
        return {};
      }

      const variantIdToCollections: Record<string, Collection[]> = {};
      const collectionPromises = cart?.items.map(async (item) => {
        const collections = await getCollections(redoShopifyExtensionClient, {
          productId: String(item.product_id),
        });
        variantIdToCollections[String(item.variant_id)] = collections;
      });
      await Promise.all(collectionPromises);
      return variantIdToCollections;
    }, [cart]);

    const collectionOfferings = useMemo(
      () => settingsLoad.value?.collectionSettings,
      [settingsLoad],
    );
    const variantSelector = useMemo(
      () => settingsLoad.value?.variantSelector,
      [settingsLoad],
    );
    const variantIdToCollections = useMemo(
      () => collectionsLoad.value,
      [collectionsLoad],
    );

    const cartOfferingsLoad = useLoad(
      async (signal: AbortSignal) => {
        if (!collectionOfferings || !cart || !variantIdToCollections) {
          return {};
        }

        const itemOfferings: Record<string, Offering[]> = {};
        const itemPromises = cart.items.map(async (item) => {
          const collections = variantIdToCollections[String(item.variant_id)];
          if (!collections) {
            return;
          }

          const product = await shopifyAjaxClient.productGet(item.handle);
          if (!product || !product.variants) {
            return;
          }
          const variant = product.variants.find(
            (v) => v.id === item.variant_id,
          );
          if (!variant) {
            return;
          }
          const offerings = calculateOfferings({
            collectionOfferings,
            variant: {
              // Variant price is in dollars, so we need to convert to cents
              price: Number(variant.price) * 100,
              variantId: String(item.variant_id),
              collections,
            },
            currency: String(
              (window as any).ShopifyAnalytics?.meta?.currency,
            ) as Currency,
            countryCode: window.Shopify?.country as CountryCode,
          });
          if (offerings) {
            itemOfferings[String(item.variant_id)] = offerings;
          }
        });
        await Promise.all(itemPromises);
        return itemOfferings;
      },
      [collectionOfferings, cart, variantIdToCollections],
    );

    const cartOfferings = useMemo(
      () => cartOfferingsLoad.value,
      [cartOfferingsLoad],
    );

    const currentSelections = useMemo(() => {
      // TODO: Abstract
      const cartAttribute = cart?.attributes?.[CART_ATTRIBUTE_KEY];
      if (!cartAttribute) {
        return [];
      }

      const selections: Selections = JSON.parse(cartAttribute);
      const selectedItems: {
        variantId: string;
        collectionHandle: string;
        timeId: string;
      }[] = [];
      Object.entries(selections).forEach(
        ([variantId, { collectionHandle, timeId }]) => {
          if (timeId !== NO_COVERAGE_TIME_ID) {
            selectedItems.push({ variantId, collectionHandle, timeId });
          }
        },
      );
      return selectedItems;
    }, [cart]);

    const renderTogglesLoad = useLoad(async () => {
      if (
        !currentSelections ||
        !collectionOfferings ||
        !collectionOfferings.length
      ) {
        return [];
      }

      // TODO: Add style overrides
      const productSelector = variantSelector || DEFAULT_VARIANT_SELECTOR;

      // TODO: Abstract selection, combine with offerings
      const toggles: {
        toggle: ExtendedWarrantyCartToggleElement;
        item: ShopifyCartItem;
        selection?: { collectionHandle: string; timeId: string; title: string };
        offerings: Offering[];
      }[] = [];
      // TODO: There may be a more efficient way to do this
      cart?.items.forEach((item, index) => {
        const selectors: string[] = [];

        selectors.push(
          productSelector
            .replaceAll("%variant_id%", String(item.variant_id))
            .replaceAll("%handle%", String(item.handle))
            .replaceAll("%index%", String(index + 1)),
        );

        const selector = selectors.filter(Boolean).join(", ");

        const containers = Array.from(
          document.querySelectorAll(selector),
        ) as HTMLElement[];

        for (const container of containers) {
          const selection = currentSelections.find(
            (s) => s.variantId === item.variant_id.toString(),
          );

          const offerings = cartOfferings?.[String(item.variant_id)];

          if (!selection && (!offerings || offerings.length === 0)) {
            continue;
          }

          let toggle = container.nextElementSibling;
          if (
            !toggle ||
            !(toggle instanceof ExtendedWarrantyCartToggleElement)
          ) {
            toggle = document.createElement(
              ExtendedWarrantyCartToggleElement.TAG,
            ) as ExtendedWarrantyCartToggleElement;
            container.insertAdjacentElement("afterend", toggle);
          }

          const cartToggleExperience = settingsLoad.value?.cartToggleExperience;
          if (
            cartToggleExperience ===
              ExtendedWarrantyCartToggleExperience.REMOVE_ONLY &&
            !selection
          ) {
            continue;
          }

          toggles.push({
            toggle: toggle as ExtendedWarrantyCartToggleElement,
            item,
            selection: selection
              ? {
                  collectionHandle: selection.collectionHandle,
                  timeId: selection.timeId,
                  title: getOfferingTitle(
                    collectionOfferings,
                    selection.collectionHandle,
                    selection.timeId,
                  ),
                }
              : undefined,
            offerings: offerings || [],
          });
        }
      });

      return toggles;
    }, [cart, cartRefresh, currentSelections, cartOfferings]);

    const removeWarranty = useHandler(async (variantId: string) => {
      const cartAttribute = cart?.attributes?.[CART_ATTRIBUTE_KEY];
      if (!cartAttribute) {
        return;
      }
      const selections: Selections = JSON.parse(cartAttribute);

      if (!selections[variantId]) {
        return;
      }
      selections[variantId].timeId = NO_COVERAGE_TIME_ID;

      await shopifyAjaxClient.updateCartAttribute({
        attributes: [
          { key: CART_ATTRIBUTE_KEY, value: JSON.stringify(selections) },
        ],
      });
    });

    const addWarranty = useHandler(
      async (variantId: string, selectedOffering: Offering) => {
        let selections: Selections = {};
        const cartAttribute = cart?.attributes?.[CART_ATTRIBUTE_KEY];
        if (cartAttribute) {
          selections = JSON.parse(cartAttribute);
        }

        selections[variantId] = {
          collectionHandle: selectedOffering.collectionHandle,
          timeId: selectedOffering.id,
        };

        await shopifyAjaxClient.updateCartAttribute({
          attributes: [
            { key: CART_ATTRIBUTE_KEY, value: JSON.stringify(selections) },
          ],
        });
      },
    );

    useEventListener(
      document,
      "click",
      (e: Event) => {
        if (
          e.target instanceof Element &&
          // Check if the click was one of the elements that could trigger a cart change or open.
          e.target.matches(DEFAULT_TOGGLE_CLICK_SELECTOR)
        ) {
          for (let i = 1; i <= 5; i *= 2) {
            setTimeout(() => setCartRefresh(Symbol()), i * 500);
          }
        }
      },
      true,
    );

    const toggles = renderTogglesLoad.value;
    const settings = settingsLoad.value;

    return (
      <>
        {toggles?.map(({ toggle, item, selection, offerings }, index) => {
          return (
            <StyleShadowRoot
              key={index}
              shadowRoot={toggle.root}
              styleManager={STYLE_MANAGER}
            >
              <ExtendedWarrantyCartToggleContent
                addWarranty={addWarranty}
                cartToggleExperience={settings?.cartToggleExperience}
                customCss={settings?.customToggleCss}
                item={item}
                logo={settings?.logo}
                offerings={offerings}
                removeWarranty={removeWarranty}
                selection={selection}
                sideImage={settings?.sideImage}
                text={{
                  modalTitle: settingsLoad.value?.modalTitle,
                  modalCoverageHeader: settingsLoad.value?.modalCoverageHeader,
                  modalDescription: settingsLoad.value?.modalDescription,
                  modalBulletPoints: settingsLoad.value?.modalBulletPoints,
                  modalPlanHeader: settingsLoad.value?.modalPlanHeader,
                  cartToggleModalNotAddedDescription:
                    settingsLoad.value?.cartToggleModalNotAddedDescription,
                  cartToggleModalAddedDescription:
                    settingsLoad.value?.cartToggleModalAddedDescription,
                  cartToggleAddedDescription:
                    settingsLoad.value?.cartToggleAddedDescription,
                  cartToggleNotAddedDescription:
                    settingsLoad.value?.cartToggleNotAddedDescription,
                }}
              />
            </StyleShadowRoot>
          );
        })}
      </>
    );
  },
);

export const ExtendedWarrantyCartToggleContent = memo(
  function ExtendedWarrantyCartToggleContent({
    addWarranty,
    removeWarranty,
    item,
    selection,
    offerings,
    cartToggleExperience,
    customCss,
    logo,
    sideImage,
    text,
    isPreview,
  }: {
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
    removeWarranty: (variantId: string) => void;
    item: ShopifyCartItem;
    selection?: TitledSelection;
    offerings: Offering[];
    cartToggleExperience?: ExtendedWarrantyCartToggleExperience;
    customCss?: string;
    logo?: string;
    sideImage?: string;
    text?: {
      modalTitle?: string;
      modalCoverageHeader?: string;
      modalDescription?: string;
      modalBulletPoints?: string[];
      modalPlanHeader?: string;
      cartToggleModalNotAddedDescription?: string;
      cartToggleModalAddedDescription?: string;
      cartToggleAddedDescription?: string;
      cartToggleNotAddedDescription?: string;
    };
    isPreview?: boolean;
  }) {
    return (
      <>
        {customCss ? <style>{customCss}</style> : null}
        {cartToggleExperience === ExtendedWarrantyCartToggleExperience.MODAL ? (
          <ModalExperience
            addWarranty={addWarranty}
            customCss={customCss}
            isPreview={isPreview}
            item={item}
            logo={logo}
            offerings={offerings}
            removeWarranty={removeWarranty}
            selection={selection}
            sideImage={sideImage}
            text={text}
          />
        ) : (
          <CartToggleExperience
            addWarranty={addWarranty}
            cartToggleExperience={cartToggleExperience}
            item={item}
            offerings={offerings}
            removeWarranty={removeWarranty}
            selection={selection}
            text={text}
          />
        )}
      </>
    );
  },
);

const ModalExperience = memo(function ModalExperience({
  item,
  selection,
  offerings,
  sideImage,
  logo,
  removeWarranty,
  addWarranty,
  customCss,
  text,
  isPreview,
}: {
  item: ShopifyCartItem;
  selection?: TitledSelection;
  offerings: Offering[];
  sideImage?: string;
  logo?: string;
  text?: {
    modalTitle?: string;
    modalCoverageHeader?: string;
    modalDescription?: string;
    modalBulletPoints?: string[];
    modalPlanHeader?: string;
    cartToggleModalNotAddedDescription?: string;
    cartToggleModalAddedDescription?: string;
  };
  removeWarranty: (variantId: string) => void;
  addWarranty: (variantId: string, selectedOffering: Offering) => void;
  customCss?: string;
  isPreview?: boolean;
}) {
  const selectedOffering = selection
    ? offerings.find((o) => o.id === selection.timeId)
    : undefined;

  const [showModal, setShowModal] = useState(false);

  const handleLearnMoreClick = useHandler(() => {
    setShowModal(true);
  });

  const handleAddClick = useHandler(() => {
    setShowModal(true);
  });

  const handleModalClose = useHandler(() => {
    setShowModal(false);
  });

  const handleModalTileClick = useHandler((offering: Offering) => {
    if (offering.id === NO_COVERAGE_TIME_ID) {
      if (selection) {
        removeWarranty(String(item.variant_id));
      }
    } else if (!selection || selection.timeId !== offering.id) {
      addWarranty(String(item.variant_id), offering);
    }
    setShowModal(false);
  });

  const {
    cartToggleModalNotAddedDescription,
    cartToggleModalAddedDescription,
  } = {
    cartToggleModalNotAddedDescription:
      text?.cartToggleModalNotAddedDescription ||
      DEFAULT_TEXT.cartToggleModalNotAddedDescription,
    cartToggleModalAddedDescription:
      text?.cartToggleModalAddedDescription ||
      DEFAULT_TEXT.cartToggleModalAddedDescription,
  };

  return (
    <>
      <Flex
        className={extendedWarrantyCss.container}
        data-target={ExtendedWarrantyDataTarget.CT_MODAL_CONTAINER}
        justify="space-between"
        wrap="nowrap"
      >
        {selection ? (
          <ShieldTickIcon
            className={extendedWarrantyCss.shieldIcon}
            data-target={ExtendedWarrantyDataTarget.CT_ADDED_SHIELD}
          />
        ) : (
          <ShieldZapIconOutline
            className={extendedWarrantyCss.shieldIcon}
            data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_SHIELD}
          />
        )}
        <Flex
          data-target={ExtendedWarrantyDataTarget.CT_MODAL_TEXT}
          flexDirection="column"
          gap="none"
        >
          <Text
            className={extendedWarrantyCss.plan}
            fontSize="sm"
            fontWeight="medium"
          >
            {!selection
              ? htmlReactParser(cartToggleModalNotAddedDescription)
              : htmlReactParser(
                  cartToggleModalAddedDescription.replace(
                    "%plan_title%",
                    selection.title,
                  ),
                )}
          </Text>
          <LearnMoreLink onClick={handleLearnMoreClick} />
        </Flex>
        <Flex
          align="center"
          data-target={ExtendedWarrantyDataTarget.CT_MODAL_BUTTON}
          height="auto"
        >
          <ToggleAddButton
            onAddClick={handleAddClick}
            selectedOffering={selectedOffering}
          />
        </Flex>
      </Flex>
      {showModal && !isPreview && (
        <ExtendedWarrantyModal
          customCss={customCss}
          logo={logo}
          offerings={offerings}
          onClose={handleModalClose}
          onTileClick={handleModalTileClick}
          open={showModal}
          selectedOffering={selectedOffering}
          sideImage={sideImage}
          text={text}
        />
      )}
    </>
  );
});

const CartToggleExperience = memo(function CartToggleExperience({
  cartToggleExperience,
  selection,
  item,
  offerings,
  removeWarranty,
  addWarranty,
  text,
}: {
  item: ShopifyCartItem;
  selection?: TitledSelection;
  offerings: Offering[];
  cartToggleExperience?: ExtendedWarrantyCartToggleExperience;
  text?: {
    cartToggleAddedDescription?: string;
    cartToggleNotAddedDescription?: string;
  };
  removeWarranty: (variantId: string) => void;
  addWarranty: (variantId: string, selectedOffering: Offering) => void;
}) {
  if (selection) {
    return (
      <ExtendedWarrantyCartToggleAdded
        item={item}
        removeWarranty={removeWarranty}
        text={text}
        title={selection.title}
      />
    );
  } else if (
    cartToggleExperience !== ExtendedWarrantyCartToggleExperience.REMOVE_ONLY
  ) {
    return (
      <ExtendedWarrantyCartToggleNotAdded
        addWarranty={addWarranty}
        item={item}
        offerings={offerings}
        text={text}
      />
    );
  } else {
    return null;
  }
});

const ExtendedWarrantyCartToggleAdded = memo(
  function ExtendedWarrantyCartToggleAdded({
    title,
    item,
    removeWarranty,
    text,
  }: {
    title: string;
    item: ShopifyCartItem;
    removeWarranty: (variantId: string) => void;
    text?: { cartToggleAddedDescription?: string };
  }) {
    const { cartToggleAddedDescription } = {
      cartToggleAddedDescription:
        text?.cartToggleAddedDescription ||
        DEFAULT_TEXT.cartToggleAddedDescription,
    };

    return (
      <Flex
        className={extendedWarrantyCss.container}
        data-target={ExtendedWarrantyDataTarget.CT_ADDED_CONTAINER}
        justify="space-between"
        wrap="nowrap"
      >
        <ShieldTickIcon
          className={extendedWarrantyCss.shieldIcon}
          data-target={ExtendedWarrantyDataTarget.CT_ADDED_SHIELD}
        />
        <Flex
          data-target={ExtendedWarrantyDataTarget.CT_ADDED_CONTENT}
          flexDirection="column"
          gap="none"
        >
          <Text
            className={extendedWarrantyCss.plan}
            fontSize="sm"
            fontWeight="medium"
          >
            {htmlReactParser(
              cartToggleAddedDescription.replace("%plan_title%", title),
            )}
          </Text>
          <a
            className={extendedWarrantyCss.remove}
            data-target={ExtendedWarrantyDataTarget.CT_ADDED_REMOVE}
            onClick={() => removeWarranty(String(item.variant_id))}
          >
            Remove
          </a>
        </Flex>
      </Flex>
    );
  },
);

const ExtendedWarrantyCartToggleNotAdded = memo(
  function ExtendedWarrantyCartToggleNotAdded({
    item,
    offerings,
    addWarranty,
    text,
  }: {
    item: ShopifyCartItem;
    offerings: Offering[];
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
    text?: { cartToggleNotAddedDescription?: string };
  }) {
    const selectedOffering = getCheapestOffering(offerings);

    const handleAddClick = useHandler(() => {
      if (!selectedOffering) {
        return;
      }
      addWarranty(String(item.variant_id), selectedOffering);
    });

    if (!selectedOffering) {
      return null;
    }

    const { cartToggleNotAddedDescription } = {
      cartToggleNotAddedDescription:
        text?.cartToggleNotAddedDescription ||
        DEFAULT_TEXT.cartToggleNotAddedDescription,
    };

    return (
      <Flex
        className={extendedWarrantyCss.container}
        data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_CONTAINER}
        justify="space-between"
        wrap="nowrap"
      >
        <ShieldZapIconOutline
          className={extendedWarrantyCss.shieldIcon}
          data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_SHIELD}
        />
        <Flex
          data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_CONTENT}
          flexDirection="column"
          gap="xs"
        >
          <Text
            className={extendedWarrantyCss.plan}
            fontSize="sm"
            fontWeight="medium"
          >
            {htmlReactParser(
              cartToggleNotAddedDescription
                .replace("%plan_title%", selectedOffering.title)
                .replace(
                  "%price%",
                  CURRENCY_FORMAT(selectedOffering.price.currency).format(
                    selectedOffering.price.amount,
                  ),
                ),
            )}
          </Text>
          <ToggleAddButton
            data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_ADD}
            onAddClick={handleAddClick}
          />
        </Flex>
      </Flex>
    );
  },
);
