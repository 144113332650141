import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { toast } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { MultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import classNames from "classnames";
import { memo, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../client/context";
import { getSystemRules, getSystemViews } from "../../../client/team";
import { TeamContext } from "../../team";
import { setupRules, setupViews } from "./support-onboarding-actions";
import * as supportOnboardingCss from "./support-onboarding.module.css";

export const SupportOnboarding = memo(function SupportOnboarding() {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const systemRules = useLoad(async () => {
    const result = await getSystemRules(client);
    return result.data;
  }, [client]);

  const systemViews = useLoad(async () => {
    const result = await getSystemViews(client);
    return result;
  }, [client]);

  const [rulesToCreate, setRulesToCreate] = useState<readonly string[]>([]);
  const [viewsToCreate, setViewsToCreate] = useState<readonly string[]>([]);

  async function handleSetupRules() {
    if (!team) {
      return;
    }
    const ruleIds = new Set(rulesToCreate);
    const rules = (systemRules.value || []).filter((rule) =>
      ruleIds.has(rule._id),
    );
    try {
      await setupRules({ client, teamId: team._id, rules });
      toast("Rules created successfully", { variant: "success" });
    } catch (e) {
      toast("Error creating rules", { variant: "error" });
    }
  }

  async function handleSetupViews() {
    if (!team) {
      return;
    }
    const viewIds = new Set(viewsToCreate);
    const views = (systemViews.value || []).filter((view) =>
      viewIds.has(view._id),
    );
    try {
      await setupViews({ client, teamId: team._id, views });
      toast("Views created successfully", { variant: "success" });
    } catch (e) {
      toast("Error creating views", { variant: "error" });
    }
  }

  return (
    <Card title="Support onboarding">
      <Text fontSize="lg" fontWeight="semibold">
        Rules and views are pulled from the team "[Data] Redo Default Email
        Templates"
      </Text>
      <Flex as="section" className={gridCss.grid} dir="column">
        <Flex
          className={classNames(
            gridCss.span6L,
            supportOnboardingCss.sectionWidth,
          )}
          dir="column"
        >
          <Text fontSize="lg" fontWeight="semibold">
            Rules
          </Text>
          <MultiSelectDropdown
            options={(systemRules.value || []).map((rule) => rule._id)}
            value={rulesToCreate}
            valueChange={(value) => setRulesToCreate(value || [])}
          >
            {(option) => {
              return (
                <p>
                  {
                    (systemRules.value || []).find(
                      (rule) => rule._id === option,
                    )?.name
                  }
                </p>
              );
            }}
          </MultiSelectDropdown>
          <Button
            disabled={rulesToCreate.length === 0}
            onClick={handleSetupRules}
            theme={ButtonTheme.PRIMARY}
          >
            Create these rules for this team
          </Button>
        </Flex>
        <Flex
          className={classNames(
            gridCss.span6L,
            supportOnboardingCss.sectionWidth,
          )}
          dir="column"
        >
          <Text fontSize="lg" fontWeight="semibold">
            Views
          </Text>
          <MultiSelectDropdown
            options={(systemViews.value || []).map((view) => view._id)}
            value={viewsToCreate}
            valueChange={(value) => setViewsToCreate(value || [])}
          >
            {(option) => {
              return (
                <p>
                  {
                    (systemViews.value || []).find(
                      (view) => view._id === option,
                    )?.name
                  }
                </p>
              );
            }}
          </MultiSelectDropdown>
          <Button
            disabled={viewsToCreate.length === 0}
            onClick={handleSetupViews}
            theme={ButtonTheme.PRIMARY}
          >
            Create these views for this team
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
});
