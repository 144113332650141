// extracted by mini-css-extract-plugin
export var active = "_20-a";
export var activeIndicator = "_20-9";
export var disabled = "_20-b";
export var fullWidth = "_20-2";
export var lg = "_20-7";
export var md = "_20-6";
export var redoButtonTabGroup = "_20-0";
export var sm = "_20-5";
export var tabButton = "_20-4";
export var tabButtonsContainer = "_20-1";
export var themeDestructive = "_20-d";
export var themeNormal = "_20-c";
export var themeSuccess = "_20-e";
export var xl = "_20-8";
export var xs = "_20-3";