import { SellingPlanGroup, WidgetAutocheck } from "@redotech/redo-model/widget";
import { getLocalStorageJson } from "@redotech/redo-web/utils/local-storage-wrapper";
import type { ShopifyCartItem } from "@redotech/shopify-client/ajax";

export const isRedoItem = (item: ShopifyCartItem) =>
  item.vendor?.toLowerCase() === "re:do" ||
  item.title?.toLowerCase().includes("re:do");

export const isPackageProtection = (item: ShopifyCartItem) =>
  item.handle === "navidium-shipping-protection" ||
  item.vendor === "Order Protection";

export const containsExcludedItemProperty = (
  item: ShopifyCartItem,
  excludedProperties: { name: string; value: string }[],
): boolean => {
  return excludedProperties.some((property) => {
    return Object.entries(item.properties || {}).some(([key, val]) => {
      return key === property.name && val === property.value;
    });
  });
};

export const REDO_DISABLE_TAGS = ["redo-hidden"];

export const getMatchingRedoSellingPlanId = async (
  redoProduct: { sellingPlanGroups: SellingPlanGroup[] },
  sellingPlans: any[],
) => {
  const redoSellingPlans = redoProduct.sellingPlanGroups.flatMap(
    (group) => group.sellingPlans,
  );

  const allowedIntervals = sellingPlans
    .filter((plan) => plan.billingPolicy.intervalDays)
    .map((plan) => plan.billingPolicy.intervalDays);

  const matchingSellingPlans = redoSellingPlans
    .filter((plan) => plan.billingPolicy.intervalDays)
    .filter((plan) =>
      allowedIntervals.includes(plan.billingPolicy.intervalDays),
    );

  if (matchingSellingPlans.length === 0) {
    return null;
  }

  const highestFrequencySellingPlan = matchingSellingPlans.reduce(
    (acc, plan) =>
      plan.billingPolicy.intervalDays! < acc.billingPolicy.intervalDays!
        ? plan
        : acc,
  );

  try {
    const id = highestFrequencySellingPlan.id.split("/").slice(-1)[0];
    return id ? parseInt(id) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getLocalStorageIgnoreExpiry = (key: string) => {
  return getLocalStorageJson(key);
};

export const getVariantIdFromUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url);
    const variantId = urlObj.searchParams.get("variant");
    return variantId;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};

export function getCartShadowRoot(
  autocheck: WidgetAutocheck,
  shadowRootSelectorOverride?: string,
): ShadowRoot | undefined {
  const useShadowRoot =
    shadowRootSelectorOverride ?? autocheck.cartToggle.useShadowRootInCart;
  const shadowDomSelector =
    shadowRootSelectorOverride ?? autocheck.cartToggle.cartShadowRootSelector;
  if (!useShadowRoot || !shadowDomSelector) {
    return undefined;
  }
  return document.querySelector(shadowDomSelector)?.shadowRoot ?? undefined;
}

// Query both the document and the shadow root (if it exists) to make sure we get ALL elements that match the selector
export function queryDocumentAndShadowRoot(
  selector: string,
  autocheck: WidgetAutocheck,
): Element[] {
  return [
    ...document.querySelectorAll(selector),
    ...(getCartShadowRoot(autocheck)?.querySelectorAll(selector) ?? []),
  ];
}
