// extracted by mini-css-extract-plugin
export var background = "_25-0";
export var container = "_25-1";
export var contentItem = "_25-7";
export var extendedWarranty = "_25-6";
export var modal = "_25-b";
export var modalCloseButton = "_25-9";
export var modalContent = "_25-3";
export var modalContentMain = "_25-5";
export var modalImageLogo = "_25-8";
export var modalLogo = "_25-a";
export var modalSideImage = "_25-4";
export var preview = "_25-c";
export var redoModal = "_25-2";