import { Team } from "@redotech/redo-model/team";
import { RedoAdminClient } from ".";

/**
 * POST /teams/:teamId/extended-warranty-settings
 */
export async function updateExtendedWarrantySettings(
  client: RedoAdminClient,
  {
    teamId,
    extendedWarrantySettings,
    sideImage,
    logo,
    signal,
  }: {
    teamId: string;
    extendedWarrantySettings: Team["settings"]["extendedWarranties"];
    sideImage?: string;
    logo?: string;
    signal?: AbortSignal;
  },
): Promise<void> {
  const response = await client.client.post(
    `teams/${encodeURIComponent(teamId)}/extended-warranty-settings`,
    { extendedWarrantySettings, sideImage, logo },
    { headers: client.authorization(), signal },
  );
  return response.data;
}
