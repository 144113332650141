/**
 * @file
 * @see https://shopify.dev/docs/api/ajax
 */

import {
  ContentTypeField,
  ServerErrorClass,
  Status,
  StatusClass,
} from "@redotech/http/semantics";
import { SetCookieField } from "@redotech/http/state";
import { ApplicationJsonType } from "@redotech/json/mime";
import axios, { AxiosHeaders, AxiosInstance } from "axios";
import * as setCookieParser from "set-cookie-parser";

export interface ShopifyCart {
  attributes?: { [key: string]: string };
  items: ShopifyCartItem[];
  currency: string;
  token?: string;
}

export interface ShopifyCartItemDiscounts {
  amount: number;
  title: string;
}

export interface ShopifyCartItem {
  id: number;
  product_id: number;
  variant_id: number;
  handle: string;
  properties?: { [key: string]: string } | null;
  quantity: number;
  vendor: string | null;
  title?: string | null;
  selling_plan_allocation?: ShopifySellingPlanAllocation | null;
  line_price?: number;
  original_line_price?: number;
  discounts?: ShopifyCartItemDiscounts[];
  requires_shipping: boolean;
}

export interface ShopifySellingPlanAllocation {
  compare_at_price: number;
  per_delivery_price: number;
  price: number;
  selling_plan: ShopifySellingPlan;
}
export interface ShopifySellingPlan {
  description: string | null;
  fixed_selling_plan: boolean;
  id: number;
  name: string;
  recurring_deliveries: boolean;
}

export interface ShopifyCartChange {
  id?: string;
  quantity?: number;
  selling_plan?: number | null;
  line?: number;
}

export interface ShopifyCartAttributes {
  attributes: { key: string; value: string }[];
}

interface ShopifyProductImage {
  src: string;
}

export interface ShopifyProduct {
  id: number;
  tags: string;
  title: string;
  variants: ShopifyVariantJS[];
  image?: ShopifyProductImage | undefined;
}

export interface ShopifyProductJS {
  id: number;
  price_min: number;
  price_max: number;
  variants: ShopifyVariantJS[];
}

export interface ShopifyProducts {
  mainShopifyProduct?: ShopifyProductJS;
  returnShopifyProduct?: ShopifyProductJS;
  packageShopifyProduct?: ShopifyProductJS;
  bothShopifyProduct?: ShopifyProductJS;
}

export interface ShopifyVariantJS {
  id: number;
  price: number;
  requires_shipping: boolean;
}

export interface ShopifyShop {
  id: string;
  metafield: ShopifyMetafield;
}

export interface ShopifyMetafield {
  id: string;
  key: string;
  namespace: string;
  value: string;
}

export interface Auth {
  storefrontDigest?: string;
}

const PRIVATE_COOKIE = "storefront_digest";

export class ShopifyAuthError extends Error {}

export enum ShopifyClientType {
  AJAX = "ajax",
  TAPCART = "tapcart",
}

export interface ExtensionShopifyClient {
  type: ShopifyClientType;
  cartAdd(
    data: { items: Partial<ShopifyCartItem>[] },
    params?: URLSearchParams,
  ): Promise<unknown> | Promise<undefined>;
  updateCartAttribute(
    attributes: ShopifyCartAttributes,
    signal?: AbortSignal,
  ): any;
  cartGet(signal?: AbortSignal): Promise<ShopifyCart>;
  removeItemFromCart(
    item: ShopifyCartItem,
    numToRemove: number,
    signal?: AbortSignal,
  ): Promise<undefined>;
  updateCartItemSellingPlan(
    item: ShopifyCartItem,
    sellingPlanId: number | null,
    signal?: AbortSignal,
  ): Promise<undefined>;
  cartClear(): Promise<unknown>;
  productGet(handle: string, signal?: AbortSignal): Promise<ShopifyProduct>;
  productGetJS(handle: string, signal?: AbortSignal): Promise<ShopifyProductJS>;
  getShopMetafield(
    data: { key: string; namespace: string },
    signal?: AbortSignal,
  ): Promise<ShopifyShop | undefined>;
  deleteCartAttribute(
    data: { key: string },
    signal?: AbortSignal,
  ): Promise<void>;
}

export class ShopifyAjaxClient implements ExtensionShopifyClient {
  type = ShopifyClientType.AJAX;
  constructor(url: string, auth: Auth = {}) {
    const headers = new AxiosHeaders();
    if (auth.storefrontDigest) {
      headers.set("Cookie", `${PRIVATE_COOKIE}=${auth.storefrontDigest}`);
    }
    this.axios = axios.create({ baseURL: url, headers });
  }

  private readonly axios: AxiosInstance;

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-add-js
   * @see https://shopify.dev/changelog/cart-line-items-now-ordered-in-reverse-chronological-order-based-on-time-of-addition
   * Adds items to the cart. New items are added to the top of the cart (default Shopify API behavior).
   * Should be used in most cases (as opposed to cartAddToEnd).
   */
  async cartAdd(
    data: { items: Partial<ShopifyCartItem>[] },
    params?: URLSearchParams,
  ): Promise<unknown> {
    if (!params) {
      params = new URLSearchParams();
    }
    const response = await this.axios.post("cart/add.js", data, { params });
    return response.data;
  }

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-add-js
   * @see https://shopify.dev/changelog/cart-line-items-now-ordered-in-reverse-chronological-order-based-on-time-of-addition
   * There is no native Shopify API support for this, so this is a workaround.
   * This removes all items from the cart and adds them back in reverse order.
   * Use only when merchant cart's theme removes cart items by position and not by id.
   */
  async cartAddToEnd(
    data: { items: Partial<ShopifyCartItem>[] },
    params?: URLSearchParams,
  ): Promise<unknown> {
    if (!params) {
      params = new URLSearchParams();
    }
    const currentCart = await this.cartGet();
    const existingItems = currentCart.items;
    const apiFormattedItems = existingItems.map(
      ({
        variant_id: id,
        quantity,
        properties,
        selling_plan_allocation: s,
      }) => ({ id, quantity, properties, selling_plan: s?.selling_plan.id }),
    );
    await this.cartClear();

    // Attempt to add all items in one request
    try {
      const allItems = { items: [...data.items, ...apiFormattedItems] };
      const response = await this.axios.post("cart/add.js", allItems, {
        params,
      });
      return response.data;

      // Attempt to add redo product first, then existing products
    } catch (error) {
      if (existingItems.length > 0) {
        try {
          const newItemsResponse = await this.axios.post("cart/add.js", data, {
            params,
          });
          const existingItemsResponse = await this.axios.post("cart/add.js", {
            items: apiFormattedItems,
          });
          return {
            ...newItemsResponse.data,
            items: [
              ...newItemsResponse.data.items,
              ...existingItemsResponse.data.items,
            ],
          };
        } catch (restoreError) {
          console.error("Failed to restore cart items:", restoreError);
          throw restoreError;
        }
      }
      throw error;
    }
  }

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-add-js
   */
  async cartGet(signal?: AbortSignal): Promise<ShopifyCart> {
    const response = await this.axios.get("cart.js", { signal });
    return response.data;
  }

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-change-js
   */
  async cartChange(
    change: ShopifyCartChange,
    signal?: AbortSignal,
  ): Promise<unknown> {
    const response = await this.axios.post("cart/change.js", change, {
      signal,
    });
    return response.data;
  }

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/cart#post-locale-cart-change-js
   */
  async updateCartAttribute(
    attr: ShopifyCartAttributes,
    signal?: AbortSignal,
  ): Promise<unknown> {
    const attributes: { [key: string]: string } = {};
    attr.attributes.forEach((a) => {
      attributes[a.key] = a.value;
    });

    const postData = { attributes };

    const response = await this.axios.post("cart/update.js", postData, {
      signal,
    });
    return response.data;
  }

  async removeItemFromCart(
    item: ShopifyCartItem,
    numToRemove: number,
    signal?: AbortSignal,
  ): Promise<undefined> {
    const newQuantity = item.quantity - numToRemove;
    await this.cartChange(
      { id: String(item.id), quantity: newQuantity },
      signal,
    );
  }

  async updateCartItemSellingPlan(
    item: ShopifyCartItem,
    sellingPlanId: number | null,
    signal?: AbortSignal,
  ): Promise<undefined> {
    await this.cartChange(
      {
        id: String(item.id),
        quantity: item.quantity,
        selling_plan: sellingPlanId,
      },
      signal,
    );
  }

  async cartClear(): Promise<unknown> {
    const response = await this.axios.post("cart/clear.js");
    return response.data;
  }

  /**
   * @see https://shopify.dev/docs/api/ajax/reference/product#get-locale-products-product-handle-js
   */
  async productGet(
    handle: string,
    signal?: AbortSignal,
  ): Promise<ShopifyProduct> {
    const response = await this.axios.get(
      `products/${encodeURIComponent(handle)}.json`,
      { signal },
    );
    return response.data.product;
  }

  async productGetJS(
    handle: string,
    signal?: AbortSignal,
  ): Promise<ShopifyProductJS> {
    const response = await this.axios.get(
      `products/${encodeURIComponent(handle)}.js`,
      { signal },
    );
    return response.data;
  }

  async productRecommendations(product_id: number): Promise<any> {
    const params = new URLSearchParams();
    params.append("product_id", String(product_id));
    // If this returns an HTML page asking for a password,
    // put the store's password into the storePassword field in the database
    const response = await this.axios.get("recommendations/products.json", {
      params,
    });
    return response.data;
  }

  async productsGet(accessToken: string): Promise<any> {
    const response = await this.axios.get(
      "admin/api/2023-07/products.json?limit=250",
      { headers: { "X-Shopify-Access-Token": accessToken } },
    );
    return response.data;
  }

  async getStorefrontAccessTokens(accessToken: string): Promise<any> {
    const response = await this.axios.get(
      "admin/api/2023-07/storefront_access_tokens.json",
      { headers: { "X-Shopify-Access-Token": accessToken } },
    );
    return response.data;
  }

  async deleteStorefrontAccesstoken(
    tokenId: string,
    accessToken: string,
  ): Promise<any> {
    const response = await this.axios.delete(
      `admin/api/2023-07/storefront_access_tokens/${tokenId}.json`,
      { headers: { "X-Shopify-Access-Token": accessToken } },
    );
    return response.data;
  }

  async createStorefrontAccessToken(accessToken: string): Promise<any> {
    const response = await this.axios.post(
      "admin/api/2023-07/storefront_access_tokens.json",
      { storefront_access_token: { title: "Redo Storefront Access Token" } },
      {
        headers: {
          [String(ContentTypeField.name)]: String(ApplicationJsonType),
          "X-Shopify-Access-Token": accessToken,
        },
      },
    );
    return response.data;
  }

  async productsSearch(search: string): Promise<any> {
    const params = new URLSearchParams();
    params.append("q", search);
    const response = await this.axios.get("search/suggest.json", { params });
    return response.data;
  }

  static fromEnv(auth: Auth = {}): ShopifyAjaxClient {
    return new ShopifyAjaxClient("/", auth);
  }

  async getShopMetafield(): Promise<ShopifyShop | undefined> {
    return;
  }

  async deleteCartAttribute(): Promise<void> {}
}

/**
 * @see https://help.shopify.com/en/manual/online-store/themes/password-page
 */
export async function shopifyAjaxClientPrivate(
  url: string,
  password: string,
): Promise<ShopifyAjaxClient> {
  const axios_ = axios.create({ baseURL: url });
  const data = new URLSearchParams();
  data.append("password", password);
  const headers = new AxiosHeaders();
  const response = await axios_.post("password", data.toString(), {
    headers,
    maxRedirects: 0,
    validateStatus: (status) => {
      const class_ = new Status(status, "").class();
      return !StatusClass.equals(class_, ServerErrorClass);
    },
  });
  const setCookies = response.headers[SetCookieField.name.normalized()]
    ? response.headers[SetCookieField.name.normalized()]
    : "";
  const cookies = setCookieParser(setCookies);
  const cookie = cookies.find((cookie) => cookie.name === PRIVATE_COOKIE);
  if (!cookie) {
    throw new ShopifyAuthError("Invalid store password");
  }
  return new ShopifyAjaxClient(url, { storefrontDigest: cookie.value });
}
