import { useInput } from "@redotech/react-util/form";
import {
  DEFAULT_TEXT,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import {
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyPdpExperience,
  Team,
} from "@redotech/redo-model/team";
import {
  ExtendedWarrantyModal,
  ExtendedWarrantyOptionsExperience,
} from "@redotech/redo-shopify-extension/app-blocks/extended-warranty-options/extended-warranty-options";
import { ExtendedWarrantyCartToggleContent } from "@redotech/redo-shopify-extension/extended-warranty";
import { Card } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormImageUpload } from "@redotech/redo-web/image-upload";
import { LabelPosition } from "@redotech/redo-web/labeled-input";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormSwitch, SwitchSize } from "@redotech/redo-web/switch";
import { Header, Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputLines,
  InputTheme,
} from "@redotech/redo-web/text-input";
import { groupInput, Input, input, InputProvider } from "@redotech/ui/form";
import { optionalEqual, urlEqual } from "@redotech/util/equal";
import classNames from "classnames";
import { Component, memo, ReactNode, useEffect, useState } from "react";
import { ClientUploader } from "../../../client/uploader";
import { ExtendedWarrantySettingsValue } from "./extended-warranties";
import * as extendedWarrantiesCss from "./extended-warranties.module.css";

const extendedWarrantyPdpExperience: readonly ExtendedWarrantyPdpExperience[] =
  [
    ExtendedWarrantyPdpExperience.TILES_MODAL,
    ExtendedWarrantyPdpExperience.UPSELL_MODAL,
  ];

const extendedWarrantyPdpExperienceToTitle = {
  [ExtendedWarrantyPdpExperience.TILES_MODAL]: "Tiles modal",
  [ExtendedWarrantyPdpExperience.UPSELL_MODAL]: "Upsell modal",
};

const extendedWarrantyCartToggleExperience: readonly ExtendedWarrantyCartToggleExperience[] =
  [
    ExtendedWarrantyCartToggleExperience.TOGGLE,
    ExtendedWarrantyCartToggleExperience.MODAL,
    ExtendedWarrantyCartToggleExperience.REMOVE_ONLY,
    ExtendedWarrantyCartToggleExperience.DISABLED,
  ];

const extendedWarrantyCartToggleExperienceToTitle = {
  [ExtendedWarrantyCartToggleExperience.TOGGLE]: "Toggle",
  [ExtendedWarrantyCartToggleExperience.MODAL]: "Modal",
  [ExtendedWarrantyCartToggleExperience.REMOVE_ONLY]: "Remove only",
  [ExtendedWarrantyCartToggleExperience.DISABLED]: "Disabled",
};

const PreviewContainer = memo(function PreviewContainer({
  children,
  previewSettings = [],
}: {
  children: ReactNode;
  previewSettings?: { input: Input<boolean>; label: string }[];
}) {
  class PreviewErrorBoundary extends Component<
    { children: ReactNode },
    { error: Error | null }
  > {
    constructor(props: { children: ReactNode }) {
      super(props);
      this.state = { error: null };
    }

    override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      this.setState({ error });
    }

    override render() {
      if (this.state.error) {
        return (
          <Flex flexDirection="column" gap="xs">
            <Text>Rendering the preview failed</Text>
            <Text>{this.state.error.message}</Text>
          </Flex>
        );
      }
      return this.props.children;
    }
  }

  // TODO: Add to a shadow root so the styles don't bleed into the parent
  return (
    <Flex flexDirection="column" gap="md" w="half">
      <Text>Preview</Text>
      <Flex
        align="center"
        className={extendedWarrantiesCss.previewContainer}
        height="full"
        justify="center"
        p="md"
        radius="md"
        w="full"
      >
        <PreviewErrorBoundary>{children}</PreviewErrorBoundary>
      </Flex>
      {previewSettings.length > 0 && (
        <Flex flexDirection="column" gap="md">
          {previewSettings.map((setting, index) => (
            <FormSwitch
              input={setting.input}
              key={index}
              label={setting.label}
              position={LabelPosition.LEFT}
              size={SwitchSize.EXTRA_SMALL}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
});

const generateExampleOfferings = (
  collectionSettings?: ExtendedWarrantySettingsValue["collectionSettings"][0],
) => {
  if (!collectionSettings) {
    return [
      {
        title: "1 year",
        id: "1-year",
        collectionHandle: "",
        price: { currency: "USD", amount: 10 },
      },
      {
        title: "2 years",
        id: "2-years",
        collectionHandle: "",
        price: { currency: "USD", amount: 20 },
      },
      {
        title: "No coverage",
        id: NO_COVERAGE_TIME_ID,
        collectionHandle: "",
        price: { currency: "USD", amount: 0 },
      },
    ];
  }

  const offerings = collectionSettings.offerings.map((offering) => ({
    title: offering.timeTitle,
    id: offering.timeId,
    collectionHandle: collectionSettings.collectionHandle,
    price: { currency: "USD", amount: Number(offering.minPrice) },
  }));

  offerings.push({
    title: "No coverage",
    id: NO_COVERAGE_TIME_ID,
    collectionHandle: collectionSettings.collectionHandle,
    price: { currency: "USD", amount: 0 },
  });

  return offerings;
};

const ExtendedWarrantyOptionsPreview = memo(
  function ExtendedWarrantyOptionsPreview({
    settings,
    itemSelected,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    itemSelected: boolean;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
    );

    const [selectedOffering, setSelectedOffering] = useState<
      Offering | undefined
    >(populatedOfferings[0]);

    useEffect(() => {
      if (itemSelected) {
        setSelectedOffering(populatedOfferings[0]);
      } else {
        setSelectedOffering(
          populatedOfferings.find(
            (offering) => offering.id === NO_COVERAGE_TIME_ID,
          ),
        );
      }
    }, [itemSelected]);

    return (
      <Flex
        className={extendedWarrantiesCss.pdpPreviewContainer}
        flexDirection="column"
        gap="xs"
        px="xl"
        py="lg"
      >
        <ExtendedWarrantyOptionsExperience
          cheapestOffering={populatedOfferings[0]}
          customCss={extendedWarrantySettings.customPdpCss}
          experience={settings.pdpExperience as ExtendedWarrantyPdpExperience}
          offerings={populatedOfferings}
          onAddClick={() => {}}
          onLearnMoreClick={() => {}}
          onTileClick={setSelectedOffering}
          selectedOffering={selectedOffering}
          text={{
            pdpTitle: settings.pdpTitle,
            pdpLabel: settings.pdpLabel,
            pdpDescription: settings.pdpDescription,
            pdpAddToCartDescription: settings.pdpAddToCartDescription,
            pdpAddedToCartTitle: settings.pdpAddedToCartTitle,
            pdpAddedToCartDescription: settings.pdpAddedToCartDescription,
          }}
        />
      </Flex>
    );
  },
);

const ExtendedWarrantyModalPreview = memo(
  function ExtendedWarrantyModalPreview({
    settings,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
    );

    return (
      <ExtendedWarrantyModal
        customCss={extendedWarrantySettings.customModalCss}
        isPreview
        logo={settings.logo?.toString()}
        offerings={populatedOfferings}
        onClose={() => {}}
        onTileClick={() => {}}
        open
        selectedOffering={undefined}
        sideImage={settings.sideImage?.toString()}
        text={{
          modalTitle: settings.modalTitle,
          modalCoverageHeader: settings.modalCoverageHeader,
          modalDescription: settings.modalDescription,
          modalBulletPoints: settings.modalBulletPoints?.split("\n"),
          modalPlanHeader: settings.modalPlanHeader,
        }}
      />
    );
  },
);

const ExtendedWarrantyCartTogglePreview = memo(
  function ExtendedWarrantyCartTogglePreview({
    settings,
    itemSelected,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    itemSelected: boolean;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
    );

    const [selectedOffering, setSelectedOffering] = useState<
      Offering | undefined
    >(populatedOfferings[0]);

    useEffect(() => {
      if (itemSelected) {
        setSelectedOffering(populatedOfferings[0]);
      } else {
        setSelectedOffering(undefined);
      }
    }, [itemSelected, settings.cartToggleExperience]);

    return (
      <Flex
        className={extendedWarrantiesCss.togglePreviewContainer}
        flexDirection="column"
        gap="xs"
        px="xl"
        py="lg"
      >
        <ExtendedWarrantyCartToggleContent
          addWarranty={(variantId, selectedOffering) => {
            setSelectedOffering(selectedOffering);
          }}
          cartToggleExperience={
            settings.cartToggleExperience as ExtendedWarrantyCartToggleExperience
          }
          customCss={extendedWarrantySettings.customToggleCss}
          isPreview
          item={{
            id: 123,
            product_id: 123,
            variant_id: 123,
            handle: "123",
            title: "123",
            quantity: 1,
            vendor: "Test Vendor",
            requires_shipping: true,
          }}
          offerings={populatedOfferings}
          removeWarranty={() => {
            if (
              settings.cartToggleExperience !==
              ExtendedWarrantyCartToggleExperience.REMOVE_ONLY
            ) {
              setSelectedOffering(undefined);
            }
          }}
          selection={
            selectedOffering
              ? {
                  collectionHandle: selectedOffering.collectionHandle,
                  timeId: selectedOffering.id,
                  title: selectedOffering.title,
                }
              : undefined
          }
          text={{
            cartToggleAddedDescription: settings.cartToggleAddedDescription,
            cartToggleNotAddedDescription:
              settings.cartToggleNotAddedDescription,
            cartToggleModalAddedDescription:
              settings.cartToggleModalAddedDescription,
            cartToggleModalNotAddedDescription:
              settings.cartToggleModalNotAddedDescription,
          }}
        />
      </Flex>
    );
  },
);

const previewForm = groupInput({
  itemSelectedPdp: input<boolean>(),
  itemSelectedCartToggle: input<boolean>(),
});

export type PreviewForm = InputProvider.Form<typeof previewForm>;

export const experienceDesignForm = groupInput({
  logo: input<URL | undefined>({ equal: optionalEqual(urlEqual) }),
  sideImage: input<URL | undefined>({ equal: optionalEqual(urlEqual) }),
  // TODO: Add HTML validation so that we can't save invalid HTML
  modalTitle: input<string>(),
  modalCoverageHeader: input<string>(),
  modalDescription: input<string>(),
  modalPlanHeader: input<string>(),
  modalBulletPoints: input<string>(),
  pdpExperience: input<string>(),
  pdpTitle: input<string>(),
  pdpLabel: input<string>(),
  pdpDescription: input<string>(),
  pdpAddToCartDescription: input<string>(),
  pdpAddedToCartTitle: input<string>(),
  pdpAddedToCartDescription: input<string>(),
  cartToggleExperience: input<string>(),
  cartToggleModalNotAddedDescription: input<string>(),
  cartToggleModalAddedDescription: input<string>(),
  cartToggleAddedDescription: input<string>(),
  cartToggleNotAddedDescription: input<string>(),
});

export type ExperienceDesignForm = InputProvider.Form<
  typeof experienceDesignForm
>;

export type ExperienceDesignValue = InputProvider.Value<
  typeof experienceDesignForm
>;

export const experienceDesignDefault = {
  logo: undefined,
  sideImage: undefined,
  modalTitle: "",
  modalCoverageHeader: "",
  modalDescription: "",
  modalPlanHeader: "",
  modalBulletPoints: "",
  pdpExperience: ExtendedWarrantyPdpExperience.TILES_MODAL,
  pdpTitle: "",
  pdpLabel: "",
  pdpDescription: "",
  pdpAddToCartDescription: "",
  pdpAddedToCartTitle: "",
  pdpAddedToCartDescription: "",
  cartToggleExperience: ExtendedWarrantyCartToggleExperience.TOGGLE,
  cartToggleModalNotAddedDescription: "",
  cartToggleModalAddedDescription: "",
  cartToggleAddedDescription: "",
  cartToggleNotAddedDescription: "",
};

export function experienceDesignTeamToFormValue(
  team?: Team,
): ExperienceDesignValue {
  return {
    logo: team?.settings.extendedWarranties?.logo
      ? new URL(team?.settings.extendedWarranties?.logo)
      : undefined,
    sideImage: team?.settings.extendedWarranties?.sideImage
      ? new URL(team?.settings.extendedWarranties?.sideImage)
      : undefined,
    modalTitle: team?.settings.extendedWarranties?.modalTitle || "",
    modalCoverageHeader:
      team?.settings.extendedWarranties?.modalCoverageHeader || "",
    modalDescription: team?.settings.extendedWarranties?.modalDescription || "",
    modalBulletPoints:
      team?.settings.extendedWarranties?.modalBulletPoints?.join("\n") || "",
    modalPlanHeader: team?.settings.extendedWarranties?.modalPlanHeader || "",
    pdpExperience:
      team?.settings.extendedWarranties?.pdpExperience ||
      ExtendedWarrantyPdpExperience.TILES_MODAL,
    pdpTitle: team?.settings.extendedWarranties?.pdpTitle || "",
    pdpLabel: team?.settings.extendedWarranties?.pdpLabel || "",
    pdpDescription: team?.settings.extendedWarranties?.pdpDescription || "",
    pdpAddToCartDescription:
      team?.settings.extendedWarranties?.pdpAddToCartDescription || "",
    pdpAddedToCartTitle:
      team?.settings.extendedWarranties?.pdpAddedToCartTitle || "",
    pdpAddedToCartDescription:
      team?.settings.extendedWarranties?.pdpAddedToCartDescription || "",
    cartToggleExperience:
      team?.settings.extendedWarranties?.cartToggleExperience ||
      ExtendedWarrantyCartToggleExperience.TOGGLE,
    cartToggleModalNotAddedDescription:
      team?.settings.extendedWarranties?.cartToggleModalNotAddedDescription ||
      "",
    cartToggleModalAddedDescription:
      team?.settings.extendedWarranties?.cartToggleModalAddedDescription || "",
    cartToggleAddedDescription:
      team?.settings.extendedWarranties?.cartToggleAddedDescription || "",
    cartToggleNotAddedDescription:
      team?.settings.extendedWarranties?.cartToggleNotAddedDescription || "",
  };
}

export const ExperienceDesign = memo(function ExperienceDesign({
  input,
  extendedWarrantySettings,
}: {
  input: ExperienceDesignForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
}) {
  const previewInput = useInput(previewForm, {
    itemSelectedPdp: true,
    itemSelectedCartToggle: true,
  });

  return (
    <Card title="Experience Design">
      <ModalExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
      />
      <Divider />
      <PDPExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
        previewInput={previewInput}
      />
      <Divider />
      <CartToggleExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
        previewInput={previewInput}
      />
    </Card>
  );
});

const ModalExperienceDesign = memo(function ModalExperienceDesign({
  input,
  extendedWarrantySettings,
}: {
  input: ExperienceDesignForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
}) {
  const {
    logo,
    sideImage,
    modalTitle,
    modalCoverageHeader,
    modalDescription,
    modalBulletPoints,
  } = input.inputs;

  return (
    <>
      <Flex flexDirection="column" gap="none">
        <Header>Modal</Header>
        <Text fontSize="sm" fontStyle="italic">
          Each element can render HTML. Use the preview to see how it will look.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Flex height="fit-content" w="half">
          <div
            className={classNames(gridCss.grid, extendedWarrantiesCss.fullGrid)}
          >
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <ClientUploader>
                <FormImageUpload input={logo} label="Logo" />
              </ClientUploader>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <ClientUploader>
                <FormImageUpload input={sideImage} label="Left side image" />
              </ClientUploader>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormTextInput
                input={modalTitle}
                label="Modal title"
                placeholder={DEFAULT_TEXT.modalTitle}
              />
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormTextInput
                input={modalCoverageHeader}
                label="Modal coverage header"
                placeholder={DEFAULT_TEXT.modalCoverageHeader}
              />
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                input={modalDescription}
                label="Modal description"
                lines={InputLines.MULTI}
                placeholder={DEFAULT_TEXT.modalDescription}
                theme={InputTheme.FORM}
              />
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                description="Bullet points will be displayed in a list, one per line."
                input={modalBulletPoints}
                label="Modal bullet points"
                lines={InputLines.MULTI}
                placeholder={DEFAULT_TEXT.modalBulletPoints.join("\n")}
                theme={InputTheme.FORM}
              />
            </div>
          </div>
        </Flex>
        <PreviewContainer>
          <ExtendedWarrantyModalPreview
            extendedWarrantySettings={extendedWarrantySettings}
            settings={input.value}
          />
        </PreviewContainer>
      </Flex>
    </>
  );
});

const PDPExperienceDesign = memo(function PDPExperienceDesign({
  input,
  previewInput,
  extendedWarrantySettings,
}: {
  input: ExperienceDesignForm;
  previewInput: PreviewForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
}) {
  const {
    pdpExperience,
    pdpTitle,
    pdpLabel,
    pdpDescription,
    pdpAddedToCartTitle,
    pdpAddedToCartDescription,
    pdpAddToCartDescription,
  } = input.inputs;

  return (
    <>
      <Flex flexDirection="column" gap="none">
        <Header>App Block</Header>
        <Text fontSize="sm" fontStyle="italic">
          Each element can render HTML. Use the preview to see how it will look.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Flex height="fit-content" w="half">
          <div
            className={classNames(gridCss.grid, extendedWarrantiesCss.fullGrid)}
          >
            <div className={gridCss.span12}>
              <FormSelectDropdown
                input={pdpExperience}
                label="Block experience"
                options={extendedWarrantyPdpExperience}
              >
                {(option) =>
                  extendedWarrantyPdpExperienceToTitle[
                    option as ExtendedWarrantyPdpExperience
                  ]
                }
              </FormSelectDropdown>
            </div>
            {pdpExperience.value ===
            ExtendedWarrantyPdpExperience.TILES_MODAL ? (
              <>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpTitle}
                    label="Block title"
                    placeholder={DEFAULT_TEXT.pdpTitle}
                  />
                </div>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpLabel}
                    label="Block label"
                    placeholder={DEFAULT_TEXT.pdpLabel}
                  />
                </div>
                <div className={classNames(gridCss.span12)}>
                  <FormTextInput
                    input={pdpDescription}
                    label="Block description"
                    lines={InputLines.MULTI}
                    placeholder={DEFAULT_TEXT.pdpDescription}
                    theme={InputTheme.FORM}
                  />
                </div>
              </>
            ) : pdpExperience.value ===
              ExtendedWarrantyPdpExperience.UPSELL_MODAL ? (
              <>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpAddedToCartTitle}
                    label="Added to cart title"
                    placeholder={DEFAULT_TEXT.pdpAddedToCartTitle}
                  />
                </div>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpTitle}
                    label="Add to cart title"
                    placeholder={DEFAULT_TEXT.pdpTitle}
                  />
                </div>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpAddedToCartDescription}
                    label="Added to cart description"
                    lines={InputLines.MULTI}
                    placeholder={DEFAULT_TEXT.pdpAddedToCartDescription}
                    theme={InputTheme.FORM}
                  />
                </div>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={pdpAddToCartDescription}
                    label="Add to cart description"
                    lines={InputLines.MULTI}
                    placeholder={DEFAULT_TEXT.pdpAddToCartDescription}
                    theme={InputTheme.FORM}
                  />
                </div>
              </>
            ) : null}
          </div>
        </Flex>
        <PreviewContainer
          previewSettings={
            pdpExperience.value === ExtendedWarrantyPdpExperience.UPSELL_MODAL
              ? [
                  {
                    input: previewInput.inputs.itemSelectedPdp,
                    label: "Item selected",
                  },
                ]
              : []
          }
        >
          <ExtendedWarrantyOptionsPreview
            extendedWarrantySettings={extendedWarrantySettings}
            itemSelected={previewInput.value.itemSelectedPdp}
            settings={input.value}
          />
        </PreviewContainer>
      </Flex>
    </>
  );
});

const CartToggleExperienceDesign = memo(function CartToggleExperienceDesign({
  input,
  previewInput,
  extendedWarrantySettings,
}: {
  input: ExperienceDesignForm;
  previewInput: PreviewForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
}) {
  const {
    cartToggleExperience,
    cartToggleModalNotAddedDescription,
    cartToggleModalAddedDescription,
    cartToggleNotAddedDescription,
    cartToggleAddedDescription,
  } = input.inputs;

  return (
    <>
      <Flex flexDirection="column" gap="none">
        <Header>Cart Toggle</Header>
        <Text fontSize="sm" fontStyle="italic">
          Each element can render HTML. Use the preview to see how it will look.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Flex height="fit-content" w="half">
          <div
            className={classNames(gridCss.grid, extendedWarrantiesCss.fullGrid)}
          >
            <div className={gridCss.span12}>
              <FormSelectDropdown
                input={cartToggleExperience}
                label="Block experience"
                options={extendedWarrantyCartToggleExperience}
              >
                {(option) =>
                  extendedWarrantyCartToggleExperienceToTitle[
                    option as ExtendedWarrantyCartToggleExperience
                  ]
                }
              </FormSelectDropdown>
            </div>
            {cartToggleExperience.value ===
            ExtendedWarrantyCartToggleExperience.MODAL ? (
              <>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={cartToggleModalAddedDescription}
                    label="Added description"
                    placeholder={DEFAULT_TEXT.cartToggleModalAddedDescription}
                  />
                </div>
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={cartToggleModalNotAddedDescription}
                    label="Not added description"
                    placeholder={
                      DEFAULT_TEXT.cartToggleModalNotAddedDescription
                    }
                  />
                </div>
              </>
            ) : cartToggleExperience.value ===
              ExtendedWarrantyCartToggleExperience.TOGGLE ? (
              <div className={classNames(gridCss.span12, gridCss.span6M)}>
                <FormTextInput
                  input={cartToggleNotAddedDescription}
                  label="Not added description"
                  placeholder={DEFAULT_TEXT.cartToggleNotAddedDescription}
                />
              </div>
            ) : null}
            {cartToggleExperience.value ===
              ExtendedWarrantyCartToggleExperience.REMOVE_ONLY ||
            cartToggleExperience.value ===
              ExtendedWarrantyCartToggleExperience.TOGGLE ? (
              <div className={classNames(gridCss.span12, gridCss.span6M)}>
                <FormTextInput
                  input={cartToggleAddedDescription}
                  label="Added description"
                  placeholder={DEFAULT_TEXT.cartToggleAddedDescription}
                />
              </div>
            ) : null}
          </div>
        </Flex>
        <PreviewContainer
          previewSettings={
            cartToggleExperience.value ===
            ExtendedWarrantyCartToggleExperience.MODAL
              ? [
                  {
                    input: previewInput.inputs.itemSelectedCartToggle,
                    label: "Item selected",
                  },
                ]
              : []
          }
        >
          {cartToggleExperience.value !==
          ExtendedWarrantyCartToggleExperience.DISABLED ? (
            <ExtendedWarrantyCartTogglePreview
              extendedWarrantySettings={extendedWarrantySettings}
              itemSelected={previewInput.value.itemSelectedCartToggle}
              settings={input.value}
            />
          ) : (
            <Flex>
              <Text>Disabled</Text>
            </Flex>
          )}
        </PreviewContainer>
      </Flex>
    </>
  );
});
