/**
 * @file
 * Incremental computation. Tree of values.
 */

import { Equal, identityEqual } from "./equal";
import { self } from "./function";
import { objectEntries } from "./object";

function cacheResult<A, B>(
  fn: (input: A) => IncResult<A, B>,
  equal: Equal<A>,
): IncFunction<A, B> {
  return (input) => {
    const { next, value } = fn(input);
    return self((self) => ({
      value,
      next(nextInput) {
        return equal(input, nextInput) ? self() : next(nextInput);
      },
    }));
  };
}

/**
 * Array of incremental values
 */
export function incArray<K, A, B>(
  fn: (key: K) => IncFunction<A, B>,
  keyFn: ArrayKeyFn<A, K>,
): IncFunction<A[], B[]> {
  return (function result(existing: Map<K, IncFunction<A, B>>) {
    return cacheResult((input: A[]): IncResult<A[], B[]> => {
      const nexts: typeof existing = new Map();
      const values: B[] = [];
      for (const [index, element] of input.entries()) {
        const key = keyFn(element, index);
        const f = existing.get(key) || fn(key);
        const { next, value } = f(element);
        nexts.set(key, next);
        values.push(value);
      }
      return { value: values, next: result(nexts) };
    }, identityEqual);
  })(new Map());
}

/**
 * Incremental computation
 */
export interface IncFunction<A, B> {
  /**
   * @param input Input value
   * @returns Result
   */
  (input: A): IncResult<A, B>;
}

export type IncFunctionA<T> = T extends IncFunction<infer A, any> ? A : never;

export type IncFunctionB<T> = T extends IncFunction<any, infer B> ? B : never;

/**
 * Result of an incremental computation
 */
export interface IncResult<A, B> {
  /** Next computation */
  next: IncFunction<A, B>;
  /** Value */
  value: B;
}

/**
 * Map of incremental values
 */
export function incMap<K, A, B>(
  fn: (key: K) => IncFunction<A, B>,
): IncFunction<Map<K, A>, Map<K, B>> {
  return (function result(existing: Map<K, IncFunction<A, B>>) {
    return cacheResult((input: Map<K, A>): IncResult<Map<K, A>, Map<K, B>> => {
      const nexts: typeof existing = new Map();
      const values = new Map<K, B>();
      for (const [key, v] of input) {
        const f = existing.get(key) || fn(key);
        const { next, value } = f(v);
        nexts.set(key, next);
        values.set(key, value);
      }
      return { value: values, next: result(nexts) };
    }, identityEqual);
  })(new Map());
}

/**
 * Chain incremental functions
 */
export function incCompose<A, B, C>(
  a: IncFunction<A, B>,
  b: IncFunction<B, C>,
): IncFunction<A, C> {
  return (input) => {
    const { next: nextA, value: valueA } = a(input);
    const { next: nextB, value: valueB } = b(valueA);
    return { value: valueB, next: incCompose(nextA, nextB) };
  };
}

/**
 * Object with incremental property values
 */
export function incObject<T>(
  properties: T,
): IncFunction<ObjectInput<T>, ObjectOutput<T>> {
  return cacheResult((input: ObjectInput<T>) => {
    const nexts = <T>{};
    const values = <ObjectOutput<T>>{};
    for (const [key, f] of objectEntries(properties)) {
      const { next, value } = (<IncFunction<any, any>>f)(input[key]);
      nexts[key] = <T[keyof T]>next;
      values[key] = value;
    }
    return { value: values, next: incObject(nexts) };
  }, identityEqual);
}

/**
 * A single incremental value
 */
export function incValue<A, B>(
  fn: (input: A) => B,
  equal: Equal<A> = identityEqual,
): IncFunction<A, B> {
  return function result(input): IncResult<A, B> {
    return cacheResult(
      (input: A) => ({ value: fn(input), next: result }),
      equal,
    )(input);
  };
}

export interface ArrayKeyFn<T, K> {
  (input: T, index: number): K;
}

type ObjectInput<T> = { [K in keyof T]: IncFunctionA<T[K]> };

type ObjectOutput<T> = { [K in keyof T]: IncFunctionB<T[K]> };
