export type ArrayMerge<A extends unknown[]> = UnionToIntersection<A[number]>;

export type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

export type NullishPartial<T> = { [P in keyof T]?: T[P] | null };

export type Optional<T> = T | undefined | null;

export type PartialFields<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

// https://github.com/microsoft/TypeScript/issues/31501#issuecomment-1280579305
export type OmitUnion<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export type PartialExcept<T, K extends keyof T> = Partial<T> &
  Required<Pick<T, K>>;

export type WithRequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type WithRequiredNonNullableFields<T, K extends keyof T> = T & {
  [P in K]-?: NonNullable<T[P]>;
};

export type ArrayNonNullable<T> =
  T extends Array<infer U> ? Array<NonNullable<U>> : never;

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => never : never
) extends (k: infer I) => never
  ? I
  : never;

export function isString(v: unknown): v is string {
  return typeof v === "string";
}

export function isDefined<T>(v: T | undefined | null): v is T {
  return v !== undefined && v !== null;
}

export function assertNever(value: never, errorMessage?: string): never {
  throw new Error(errorMessage ?? `Unexpected value: ${value}`);
}

export function safeAssertNever(value: never): void {}

export function isObject(v: unknown): v is Record<string, unknown> {
  return typeof v === "object" && v !== null;
}

export type Tuple<T, TLength extends number> = [T, ...T[]] & {
  length: TLength;
};

/**
 * Lets the compiler know that the value is unused but we are ok with that.
 */
export function sinkValue<T>(value: T): void {
  // noop
}

type BuildRangeTuple<
  Current extends [...number[]],
  Count extends number,
> = Current["length"] extends Count
  ? Current
  : BuildRangeTuple<[number, ...Current], Count>;

type RangeTuple<Count extends number> = BuildRangeTuple<[], Count>;

type BuildRange<
  Current extends number,
  End extends number,
  Accu extends [...number[]],
> = Accu["length"] extends End
  ? Current
  : BuildRange<Current | Accu["length"], End, [number, ...Accu]>;

export type NumberRange<
  StartInclusive extends number,
  EndExclusive extends number,
> = BuildRange<StartInclusive, EndExclusive, RangeTuple<StartInclusive>>;

/**
 * Manifest, for covariant type
 */
export class Type<T> {
  _type!: T;

  wrap(value: T): any {
    return new TypedValue(this, value);
  }

  unwrap(typedValue: any): T {
    if (!(typedValue instanceof TypedValue)) {
      throw new Error("Expected typed value");
    }
    if (typedValue.type !== this) {
      throw new Error("Type mismatch");
    }
    return typedValue.value;
  }
}

export class TypedValue<T> {
  constructor(
    readonly type: Type<T>,
    readonly value: T,
  ) {}
}

type MaxDepth = 7;

export type LeafFieldPaths<
  T,
  Depth extends number[] = [],
  Prefix extends string = "",
> = Depth["length"] extends MaxDepth
  ? never
  : T extends object
    ? {
        [K in keyof T]: T[K] extends object
          ? T[K] extends Array<any>
            ? `${Prefix}${K & string}`
            : LeafFieldPaths<T[K], [...Depth, 1], `${Prefix}${K & string}.`>
          : `${Prefix}${K & string}`;
      }[keyof T]
    : never;

export type RecordKey<R> = R extends Record<infer K, any> ? K : never;
export type RecordValue<R> = R extends Record<any, infer V> ? V : never;
export type RecordEntry<R> =
  R extends Record<infer K, infer V> ? [K, V] : never;

export type LeafFieldPathValue<
  T,
  Path extends LeafFieldPaths<T>,
  Depth extends number[] = [],
> = Depth["length"] extends MaxDepth
  ? never
  : T extends object
    ? Path extends `${infer FIRST extends keyof T & string}.${infer REST}`
      ? REST extends LeafFieldPaths<T[FIRST]>
        ? LeafFieldPathValue<T[FIRST], REST, [...Depth, 1]>
        : never
      : Path extends keyof T
        ? T[Path]
        : never
    : never;

type LeafFieldWithValueHelper<T, K> =
  K extends LeafFieldPaths<T> ? [K, LeafFieldPathValue<T, K>] : never;

/** Given an object type, returns a union of array types
 * each of which consists of a leaf field path for T
 * paired with the type of that leaf field */
export type LeafFieldWithValue<T> = LeafFieldWithValueHelper<
  T,
  LeafFieldPaths<T>
>;

export type Value<T> = T[keyof T];
