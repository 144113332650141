import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput, LabelSize } from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { memo, useEffect, useState } from "react";

export const checkoutOutboundRatesForm = groupInput({
  checkoutOutboundRatesEnabled: input<boolean>(),
  fulfillmentDelayDays: input<string>(),
});

type CheckoutOutboundRatesForm = InputProvider.Form<
  typeof checkoutOutboundRatesForm
>;

type CheckoutOutboundRatesValue = InputProvider.Value<
  typeof checkoutOutboundRatesForm
>;

export const checkoutOutboundRatesDefault: CheckoutOutboundRatesValue = {
  checkoutOutboundRatesEnabled: false,
  fulfillmentDelayDays: "0",
};

const fulfillmentDelayDaysOptions = [
  { id: "0", value: "0 days" },
  { id: "1", value: "1 day" },
  { id: "2", value: "2 days" },
  { id: "3", value: "3 days" },
  { id: "4", value: "4 days" },
  { id: "5", value: "5 days" },
  { id: "6", value: "6 days" },
  { id: "7", value: "7 days" },
];

export const CheckoutOutboundRatesCard = memo(
  function CheckoutOutboundRatesCard({
    input,
  }: {
    input: CheckoutOutboundRatesForm;
  }) {
    const [dropdownButtonRef, setDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);
    const [selectedOption, setSelectedOption] = useState<
      RedoListItem<string> | undefined
    >(
      input.value.fulfillmentDelayDays
        ? fulfillmentDelayDaysOptions.find(
            (option) => option.id === input.value.fulfillmentDelayDays,
          )
        : fulfillmentDelayDaysOptions[0],
    );

    useEffect(() => {
      setSelectedOption(
        fulfillmentDelayDaysOptions.find(
          (option) => option.id === input.value.fulfillmentDelayDays,
        ) ?? fulfillmentDelayDaysOptions[0],
      );
    }, [input.value.fulfillmentDelayDays]);

    const { checkoutOutboundRatesEnabled, fulfillmentDelayDays } = input.inputs;

    return (
      <Card title="Checkout outbound rates">
        <Flex dir="column" gap="xl">
          <FormSwitch input={checkoutOutboundRatesEnabled} label="Enabled" />
          <RedoSingleSelectDropdown
            dropdownButtonRef={dropdownButtonRef}
            options={fulfillmentDelayDaysOptions}
            optionSelected={(value) => {
              setSelectedOption(value);
              fulfillmentDelayDays.setValue(value.id!);
            }}
            selectedItem={selectedOption}
          >
            {(item) => (
              <Flex flex={1}>
                <Text
                  fontSize="xs"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {item.value}
                </Text>
              </Flex>
            )}
          </RedoSingleSelectDropdown>
          <LabeledInput
            description="The duration of time before fulfillment to account for in delivery estimations"
            label="Fulfillment delay"
            size={LabelSize.MEDIUM}
          >
            <Flex>
              <RedoButton
                hierarchy={RedoButtonHierarchy.SECONDARY}
                IconTrailing={ChevronDownIcon}
                onClick={(event) => event.stopPropagation()}
                ref={setDropdownButtonRef}
                size={RedoButtonSize.REGULAR}
                text={selectedOption?.value ?? "Select fulfillment delay"}
              />
            </Flex>
          </LabeledInput>
        </Flex>
      </Card>
    );
  },
);
