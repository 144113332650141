import { OutletFade } from "@redotech/react-animation/outlet-fade";
import { UNSAFE_useRouteId, useMatches, useOutlet } from "react-router-dom";

export function RouterOutletFade({
  element,
  elementRef,
  childClassName,
  containerClassName,
}: {
  element?: keyof Pick<JSX.IntrinsicElements, "main" | "div">;
  elementRef?: React.RefObject<HTMLDivElement>;
  childClassName?: string;
  containerClassName?: string;
}) {
  const matches = useMatches();
  const routeId = UNSAFE_useRouteId();
  const i = matches.findIndex((match) => match.id === routeId);
  const match = matches[i + 1];
  const key = match?.id;
  const outlet = useOutlet()!;

  return (
    <OutletFade
      childClassName={childClassName}
      containerClassName={containerClassName}
      element={element}
      elementRef={elementRef}
      key_={key}
    >
      {outlet}
    </OutletFade>
  );
}
