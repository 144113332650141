import { Popper, PopperPlacementType } from "@mui/base/Popper";
import useMuiTheme from "@mui/material/styles/useTheme";
import { useAnimateHeight } from "@redotech/react-animation/transition";
import { useSize } from "@redotech/react-util/size";
import * as classnames from "classnames";
import { ForwardedRef, forwardRef, memo, ReactNode, useState } from "react";
import { useDebounce } from "usehooks-ts";
import * as dropdownCss from "./dropdown.module.css";
import { Flex, FlexProps } from "./flex";
import { Tooltip } from "./tooltip/tooltip";

export const Dropdown = memo(
  forwardRef(function Dropdown(
    {
      anchor,
      children,
      open,
      fitToAnchor = true,
      flip = true,
      placement = undefined,
      darkMode = false,
      constrainHeight = true,
      flexProps,
      className,
    }: {
      anchor: HTMLElement | null;
      children: ReactNode | ReactNode[];
      open: boolean;
      fitToAnchor?: boolean;
      flip?: boolean;
      placement?: PopperPlacementType;
      darkMode?: boolean;
      constrainHeight?: boolean;
      flexProps?: FlexProps;
      className?: string;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const muiTheme = useMuiTheme();
    const [dropdown, setDropdown] = useState<HTMLElement | null>(null);

    const [animateStyles, animating] = useAnimateHeight(dropdown);

    const debounceOpen = useDebounce(open, 1);

    const size = useSize(anchor);

    const width = fitToAnchor ? size && `${size.width}px` : undefined;

    return (
      <Popper
        anchorEl={anchor}
        className={dropdownCss.root}
        container={
          muiTheme.components?.MuiPopper?.defaultProps?.container ?? undefined
        }
        modifiers={[{ name: "flip", enabled: flip }]}
        open={open || debounceOpen || animating}
        placement={placement} // Avoid cursed type check error when trying to import the PopupPlacement type
        ref={ref}
      >
        <Flex
          borderColor="secondary"
          borderStyle="solid"
          borderWidth="1px"
          className={classnames(
            dropdownCss.dropdown,
            {
              [dropdownCss.constrainHeight]: constrainHeight,
              [dropdownCss.dark]: darkMode,
              [dropdownCss.leave]: !open || !debounceOpen,
              [dropdownCss.animating]: animating,
            },
            className,
          )}
          dir="column"
          id="dropdown"
          p="md"
          radius="md"
          ref={setDropdown}
          style={{ ...animateStyles, width }}
          {...flexProps}
        >
          {(animating || (open && debounceOpen)) && children}
        </Flex>
      </Popper>
    );
  }),
);

export const DropdownOption = memo<{
  action?: () => void;
  children: ReactNode | ReactNode[];
  fontFamily?: string;
  disabled?: boolean;
  stopPropagation?: boolean;
  darkMode?: boolean;
  tooltip?: string;
}>(function DropdownOption({
  action,
  children,
  fontFamily,
  disabled,
  stopPropagation,
  darkMode,
  tooltip,
}) {
  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      <button
        className={classnames(dropdownCss.option, {
          [dropdownCss.dark]: darkMode,
        })}
        disabled={disabled}
        onClick={(event) => {
          stopPropagation && event.stopPropagation();
          action && action();
        }}
        style={{ fontFamily: fontFamily }}
        type="button"
      >
        {children}
      </button>
    </Tooltip>
  ) : (
    <button
      className={classnames(dropdownCss.option, {
        [dropdownCss.dark]: darkMode,
      })}
      disabled={disabled}
      onClick={(event) => {
        stopPropagation && event.stopPropagation();
        action && action();
      }}
      style={{ fontFamily: fontFamily }}
      type="button"
    >
      {children}
    </button>
  );
});
