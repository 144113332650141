import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { memo, SyntheticEvent, useContext, useMemo, useState } from "react";
import { RedoAdminClientContext } from "../../../client/context";
import { getCollections } from "../../../client/team";
import { TeamContext } from "../../team";

export type Collection = { id: string; handle: string; title: string };

export const CollectionAutocomplete = memo(function CollectionAutocomplete({
  value,
  setValue,
  setValueCollections,
  keyFn,
}: {
  value: string[];
  setValue?: (value: string[]) => void;
  setValueCollections?: (value: Collection[]) => void;
  keyFn: (value: Collection) => string;
}) {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, [team, client]);

  const keyMap: Map<string, Collection> = useMemo(
    () =>
      new Map(
        collectionsLoad.value?.collections.map((collection) => [
          keyFn(collection),
          collection,
        ]),
      ),
    [collectionsLoad.value],
  );
  const searchMap: Map<string, Collection> = useMemo(
    () =>
      new Map(
        collectionsLoad.value?.collections.map((collection) => [
          collection.title,
          collection,
        ]),
      ),
    [collectionsLoad.value],
  );

  const searchedCollections = useMemo(
    () =>
      searchString
        ? Array.from(searchMap.entries())
            .filter(([title, _]) =>
              title.toLowerCase().includes(searchString.toLowerCase()),
            )
            .map(([_, collection]) => keyFn(collection))
        : Array.from(keyMap.keys()),
    [searchString, searchMap, keyMap],
  );

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  const handleSetValue = (value: string[]) => {
    if (setValueCollections) {
      setValueCollections(value.map((key) => keyMap.get(key)!));
    }
    if (setValue) {
      setValue(value);
    }
  };

  const getTitle = (key: string) => {
    return keyMap.get(key)?.title;
  };

  return (
    <Autocomplete
      getLabel={(handle) => getTitle(handle) || handle}
      keyFn={(handle) => handle}
      multiple
      noOptionsText={collectionsLoading ? "Loading..." : "No options"}
      onInputChange={handleInputChange}
      options={searchedCollections}
      value={value}
      valueChange={handleSetValue}
    >
      {(handle) => getTitle(handle) || handle}
    </Autocomplete>
  );
});
