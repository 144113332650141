// extracted by mini-css-extract-plugin
export var bubble = "_13-0";
export var container = "_13-3";
export var contrastBorder = "_13-4";
export var greyOut = "_13-2";
export var initialsWrapper = "_13-1";
export var large = "_13-9";
export var medium = "_13-8";
export var mediumSmall = "_13-7";
export var micro = "_13-5";
export var small = "_13-6";
export var tiny = "_13-b";
export var xTiny = "_13-a";