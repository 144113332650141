// extracted by mini-css-extract-plugin
export var brand = "_m-9";
export var button = "_m-0";
export var chevron = "_m-v";
export var chevronButton = "_m-w";
export var content = "_m-n";
export var danger = "_m-g";
export var dangerOutlined = "_m-h";
export var dark = "_m-l";
export var darkTheme = "_m-c";
export var extraSmall = "_m-4";
export var fullWidth = "_m-8";
export var ghost = "_m-d";
export var gray = "_m-t";
export var icon = "_m-p";
export var iconButton = "_m-s";
export var iconButtonBorder = "_m-x";
export var iconButtonBorderDark = "_m-y";
export var iconButtonBorderLight = "_m-z";
export var iconLeft = "_m-q";
export var iconRight = "_m-r";
export var large = "_m-1";
export var light = "_m-m";
export var medium = "_m-2";
export var micro = "_m-5";
export var nano = "_m-7";
export var outlined = "_m-k";
export var pending = "_m-a";
export var primary = "_m-i";
export var shadow = "_m-o";
export var small = "_m-3";
export var solidLight = "_m-e";
export var solidLightBrand = "_m-f";
export var spinner = "_m-u";
export var transparent = "_m-j";
export var warning = "_m-b";
export var wideNano = "_m-6";