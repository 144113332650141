import { countries } from "@redotech/locale/countries";
import { PricingRuleSet } from "@redotech/redo-model/team";
import {
  InputProvider,
  ValidationError,
  Validator,
  groupInput,
  input,
  listInput,
  numberValidator,
} from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import * as classNames from "classnames";
import * as round from "lodash/round";
import { memo, useEffect, useState } from "react";
import { Button, ButtonBorder, ButtonTheme, IconButton } from "../button";
import { Divider } from "../divider";
import * as gridCss from "../grid.module.css";
import PlusIcon from "../icon-old/plus.svg";
import RightArrowIcon from "../icon-old/right-arrow.svg";
import TrashIcon from "../icon-old/trash.svg";
import { LabelTheme } from "../labeled-input";
import {
  FormMultiSelectDropdown,
  FormSelectDropdown,
} from "../select-dropdown";
import { FormSwitch } from "../switch";
import { Tabs } from "../tab";
import { FormTextInput } from "../text-input";
import * as pricingCss from "./pricing.module.css";

const priceBracketForm = groupInput({
  value: input<string>({ validator: numberValidator({ min: 0.01 }) }),
  pricePoint: input<string>(),
});

const priceRuleDefault = (
  index: number,
): InputProvider.Value<typeof priceBracketForm> => ({
  value: "2",
  pricePoint: "0",
});

export const ascendingValuesValidator: Validator<PricingBracketValue[]> = (
  value,
) => {
  const errors: ValidationError[] = [];

  for (let i = 0; i < value.length; i++) {
    if (i === value.length - 1) {
      break;
    }
    if (Number(value[i].pricePoint) > Number(value[i + 1].pricePoint)) {
      errors.push(`${i}`);
    }
  }
  return errors;
};

export const percentageValiator: Validator<any> = (
  value: PricingRuleSetValue,
) => {
  const errors: ValidationError[] = [];

  if (value.type === "percentage") {
    for (let i = 0; i < value.priceBrackets.length; i++) {
      if (+value.priceBrackets[i].value > 100) {
        errors.push(`${i}`);
      }
    }
  }

  return errors;
};

export const pricingRuleSetForm = groupInput(
  {
    type: input<"fixed" | "percentage">(),
    countries: input<string[]>({ equal: arrayEqual(stringEqual) }),
    priceBrackets: listInput(
      () => priceBracketForm,
      priceRuleDefault,
      (_, index) => index,
      { validator: ascendingValuesValidator },
    ),
  },
  { validator: percentageValiator },
);

export const pricingRuleSetDefault = (
  index: number,
): InputProvider.Value<typeof pricingRuleSetForm> => ({
  type: "percentage",
  countries: [],
  priceBrackets: [{ value: "2", pricePoint: "0" }],
});

export const minMaxValidator: Validator<any> = (value: PricingFormValue) => {
  const errors: ValidationError[] = [];
  for (let i = 0; i < value.pricingRuleSet.length; i++) {
    if (value.pricingRuleSet[i].type === "percentage") continue;

    for (let j = 0; j < value.pricingRuleSet[i].priceBrackets.length; j++) {
      if (!value.pricingRuleSet[i].priceBrackets[j].value.length) continue;

      if (
        Number(value.pricingRuleSet[i].priceBrackets[j].value) <
          Number(value.minPrice) ||
        Number(value.pricingRuleSet[i].priceBrackets[j].value) >
          Number(value.maxPrice)
      ) {
        errors.push(`${i},${j}`);
      }
    }
  }
  return errors;
};

export const pricingFormValues = {
  minPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
  maxPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
  percentage: input<string>({
    validator: numberValidator({ min: 0.01, max: 100 }),
  }),
  internationalPricingEnabled: input<boolean>(),
  pricingRuleSet: listInput(
    () => pricingRuleSetForm,
    pricingRuleSetDefault,
    (_, index) => index,
  ),
};

export const pricingForm = groupInput(pricingFormValues, {
  validator: minMaxValidator,
});

export const pricingDefault: InputProvider.Value<typeof pricingForm> = {
  minPrice: "1",
  maxPrice: "15",
  percentage: "2",
  internationalPricingEnabled: false,
  pricingRuleSet: [
    {
      type: "percentage",
      countries: [],
      priceBrackets: [{ value: "2", pricePoint: "0" }],
    },
  ],
};

export type PricingForm = InputProvider.Form<typeof pricingForm>;
export type PricingRuleSetForm = InputProvider.Form<typeof pricingRuleSetForm>;
type PricingFormValue = InputProvider.Value<typeof pricingForm>;
type PricingRuleSetValue = InputProvider.Value<typeof pricingRuleSetForm>;
type PricingBracketValue = InputProvider.Value<typeof priceBracketForm>;

export const PriceBracketControls = memo(function PriceBracketControls({
  pricingRuleSet,
  minMaxErrors,
  index,
  canEditSettings = true,
  adminApp = false,
  currencyDisplay,
}: {
  pricingRuleSet: PricingRuleSetForm;
  minMaxErrors: string[];
  index: number;
  canEditSettings: boolean;
  adminApp?: boolean;
  currencyDisplay?: string;
}) {
  const priceBrackets = pricingRuleSet.inputs.priceBrackets;
  const elements = [];
  let isLast = false;

  for (let i = 0; i < priceBrackets.inputs.length; i++) {
    const fromValue = priceBrackets.inputs[i].value.pricePoint;
    let toInput;

    if (i === priceBrackets.inputs.length - 1) {
      toInput = priceBrackets.inputs[i].inputs.pricePoint;
      isLast = true;
    } else {
      toInput = priceBrackets.inputs[i + 1].inputs.pricePoint;
    }

    const minMaxError = !!minMaxErrors.find((e) => e === `${index},${i}`);
    const highPercentError = !!pricingRuleSet.errors?.find((e) => e === `${i}`);
    const priceBracketError = !!priceBrackets.errors?.find(
      (e) => e === `${i + 1}`,
    );

    elements.push(
      <div className={gridCss.grid} key={i}>
        <span
          className={classNames(
            pricingCss.monospace,
            gridCss.span3,
            pricingCss.centered,
          )}
        >{`$ ${
          i === 0
            ? Number(fromValue).toFixed(2)
            : (Number(fromValue) + 0.01).toFixed(2)
        }`}</span>

        <span
          className={classNames(
            pricingCss.monospace,
            gridCss.span1,
            pricingCss.centered,
          )}
        >
          -
        </span>
        {isLast && (
          <span
            className={classNames(
              pricingCss.monospace,
              gridCss.span3,
              pricingCss.centered,
            )}
          >
            No limit
          </span>
        )}
        {!isLast && (
          <div
            className={classNames(
              pricingCss.monospace,
              gridCss.span3,
              pricingCss.errorOutline,
            )}
          >
            <FormTextInput
              description={
                priceBracketError ? (
                  <span className={pricingCss.errorText}>
                    Must be less than next row
                  </span>
                ) : (
                  ""
                )
              }
              errorOverride={priceBracketError}
              input={toInput}
              label=""
              labelTheme={LabelTheme.THIN}
              min={0.01}
              prefix={currencyDisplay}
              readonly={!canEditSettings}
              step={0.01}
            />
          </div>
        )}

        <RightArrowIcon
          className={classNames(pricingCss.icon, pricingCss.centered)}
        />

        <div className={classNames(pricingCss.monospace, gridCss.span3)}>
          <FormTextInput
            description={
              minMaxError ? (
                <span className={pricingCss.errorText}>
                  Value is outside of {adminApp ? <>your</> : <>the</>} min or
                  max
                </span>
              ) : highPercentError ? (
                <span className={pricingCss.errorText}>
                  Percentage can't exceed 100
                </span>
              ) : (
                ""
              )
            }
            errorOverride={minMaxError || highPercentError}
            input={priceBrackets.inputs[i].inputs.value}
            label=""
            labelTheme={LabelTheme.THIN}
            max={100}
            min={0.01}
            prefix={
              pricingRuleSet.value.type === "percentage" ? "" : currencyDisplay
            }
            readonly={!canEditSettings}
            step={0.01}
            suffix={pricingRuleSet.value.type === "percentage" ? "%" : ""}
          />
        </div>

        {!isLast && canEditSettings && (
          <IconButton
            onClick={() => {
              pricingRuleSet.inputs.priceBrackets.value[i].value =
                pricingRuleSet.inputs.priceBrackets.value[i + 1].value;
              pricingRuleSet.inputs.priceBrackets.value.splice(i + 1, 1);

              pricingRuleSet.inputs.priceBrackets.setValue(
                pricingRuleSet.inputs.priceBrackets.value,
              );
            }}
          >
            <div className={pricingCss.iconButtonContent}>
              <TrashIcon />
            </div>
          </IconButton>
        )}
        {isLast && canEditSettings && (
          <IconButton
            onClick={() =>
              pricingRuleSet.inputs.priceBrackets.setValue([
                ...pricingRuleSet.inputs.priceBrackets.value,
                {
                  value: String(
                    Number(
                      pricingRuleSet.inputs.priceBrackets.value[
                        pricingRuleSet.inputs.priceBrackets.value.length - 1
                      ].value,
                    ) + 1,
                  ),
                  pricePoint: String(
                    Number(
                      pricingRuleSet.inputs.priceBrackets.value[
                        pricingRuleSet.inputs.priceBrackets.value.length - 1
                      ].pricePoint,
                    ) + 100,
                  ),
                },
              ])
            }
          >
            <div className={pricingCss.iconButtonContent}>
              <PlusIcon />
            </div>
          </IconButton>
        )}
      </div>,
    );
  }

  return (
    <div>
      <div className={gridCss.grid}>
        <span className={classNames(gridCss.span3, pricingCss.centered)}>
          From
        </span>
        <span className={classNames(gridCss.span5, pricingCss.centered)}>
          To
        </span>
        <div className={gridCss.span4}>
          <FormSelectDropdown
            description=""
            disabled={!canEditSettings}
            input={pricingRuleSet.inputs.type}
            label=""
            options={["percentage", "fixed"]}
            placeholder="Default"
          >
            {pricingTypeLabel}
          </FormSelectDropdown>
        </div>
      </div>

      {elements}

      {canEditSettings && (
        <div
          className={classNames(
            gridCss.span12,
            pricingCss.centeredFlex,
            pricingCss.padded,
          )}
        >
          <Button
            border={ButtonBorder.LIGHT}
            className={classNames(pricingCss.centeredFlex)}
            onClick={() =>
              pricingRuleSet.inputs.priceBrackets.setValue([
                ...pricingRuleSet.inputs.priceBrackets.value,
                {
                  value: String(
                    Number(
                      pricingRuleSet.inputs.priceBrackets.value[
                        pricingRuleSet.inputs.priceBrackets.value.length - 1
                      ].value,
                    ) + 1,
                  ),
                  pricePoint: String(
                    Number(
                      pricingRuleSet.inputs.priceBrackets.value[
                        pricingRuleSet.inputs.priceBrackets.value.length - 1
                      ].pricePoint,
                    ) + 100,
                  ),
                },
              ])
            }
            theme={ButtonTheme.OUTLINED}
          >
            <div className={pricingCss.buttonContent}>
              <PlusIcon className={pricingCss.icon} />
              Add new tier
            </div>
          </Button>
        </div>
      )}
    </div>
  );
});

export const Pricing = memo(function Pricing({
  input,
  canEditSettings,
  adminApp = false,
  currencyDisplay,
}: {
  input: PricingForm;
  canEditSettings: boolean;
  adminApp?: boolean;
  currencyDisplay?: string;
}) {
  const {
    minPrice,
    maxPrice,
    pricingRuleSet,
    percentage,
    internationalPricingEnabled,
  } = input.inputs;

  useEffect(() => {
    if (
      pricingRuleSet.value.length === 2 &&
      !internationalPricingEnabled.value
    ) {
      pricingRuleSet.value.pop();
      pricingRuleSet.setValue(pricingRuleSet.value);
    } else if (
      internationalPricingEnabled.value &&
      pricingRuleSet.value.length === 1
    ) {
      pricingRuleSet.value.push({
        type: "percentage",
        countries: [],
        priceBrackets: [
          {
            value: percentage.value
              ? String(round(Number(percentage.value), 3))
              : "2",
            pricePoint: "0",
          },
        ],
      });
      pricingRuleSet.setValue(pricingRuleSet.value);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internationalPricingEnabled.value]);

  const [property, setProperty] = useState(CountrySet.DOMESTIC);

  return (
    <div className={gridCss.grid}>
      <div className={classNames(gridCss.span6L, pricingCss.monospace)}>
        <FormTextInput
          disabled={!canEditSettings}
          input={minPrice}
          label="Min price"
          prefix={currencyDisplay}
          type="number"
        />
      </div>
      <div className={classNames(gridCss.span6L, pricingCss.monospace)}>
        <FormTextInput
          disabled={!canEditSettings}
          input={maxPrice}
          label="Max price"
          prefix={currencyDisplay}
          type="number"
        />
      </div>

      <div className={classNames(gridCss.span12)}>
        <Tabs<CountrySet>
          keyFn={(option) => option}
          options={[CountrySet.DOMESTIC, CountrySet.INTERNATIONAL]}
          tab={(option) => CountrySetLables[option]}
          value={property}
          valueChange={setProperty}
        >
          {property === CountrySet.DOMESTIC ? (
            <div className={classNames(gridCss.span12)}>
              <FormMultiSelectDropdown
                disabled={!canEditSettings}
                display={(value) => {
                  if (value.length === 0) {
                    return "All";
                  }
                  if (value.length === countries.length) {
                    return "All";
                  }
                  const names = value.map(
                    (code) =>
                      countries.find((country) => country.code === code)?.name,
                  );
                  return names.join(", ");
                }}
                input={pricingRuleSet.inputs[0].inputs.countries}
                label={`Select ${adminApp ? "" : "your "}domestic countries`}
                options={countries.map((country: any) => country.code)}
              >
                {(option) =>
                  countries.find((country) => country.code === option)?.name
                }
              </FormMultiSelectDropdown>
              <div className={classNames(gridCss.span12, pricingCss.padded)}>
                <Divider />
              </div>

              <PriceBracketControls
                adminApp={adminApp}
                canEditSettings={canEditSettings}
                currencyDisplay={currencyDisplay}
                index={0}
                minMaxErrors={input.errors}
                pricingRuleSet={pricingRuleSet.inputs[0]}
              />
            </div>
          ) : (
            <div className={gridCss.span12}>
              <FormSwitch
                disabled={!canEditSettings}
                input={internationalPricingEnabled}
                label="Enable coverage internationally"
              >
                {`Enables coverage for all countries outside of ${
                  adminApp ? "the merchant's" : "your"
                } domestic countries`}
              </FormSwitch>
              {pricingRuleSet.inputs.length > 1 &&
                internationalPricingEnabled.value && (
                  <>
                    <div
                      className={classNames(gridCss.span12, pricingCss.padded)}
                    >
                      <Divider />
                    </div>

                    <div className={classNames(gridCss.span12)}>
                      <PriceBracketControls
                        adminApp={adminApp}
                        canEditSettings={canEditSettings}
                        index={1}
                        minMaxErrors={input.errors}
                        pricingRuleSet={pricingRuleSet.inputs[1]}
                      />
                    </div>
                  </>
                )}
            </div>
          )}
        </Tabs>
      </div>
    </div>
  );
});

export enum CountrySet {
  DOMESTIC = "Domestic",
  INTERNATIONAL = "International",
}

export const CountrySetLables = {
  [CountrySet.DOMESTIC]: <>Domestic</>,
  [CountrySet.INTERNATIONAL]: <>International</>,
};

export function pricingTypeLabel(input: "percentage" | "fixed") {
  switch (input) {
    case "percentage":
      return "Percentage";
    case "fixed":
      return "Fixed amount";
  }
}

export function pricingRulesConvertTeamToValue(
  pricingRuleSet: PricingRuleSet[] | undefined,
  defaultCountries: string[] = [],
  defaultPercentage?: number,
): PricingRuleSetValue[] {
  let pricingRuleSetReturn: PricingRuleSetValue[] = [];
  if (pricingRuleSet?.length) {
    pricingRuleSetReturn = pricingRuleSet.map((x: PricingRuleSet) => {
      return {
        type: x.type,
        countries: x.countries,
        priceBrackets: x.priceBrackets.map((pb) => {
          let value = pb.value;
          if (x.type === "percentage") {
            value = round(pb.value * 100, 3);
          }
          return { value: String(value), pricePoint: String(pb.pricePoint) };
        }),
      };
    });
  } else if (!pricingRuleSetReturn.length) {
    pricingRuleSetReturn.push({
      type: "percentage",
      countries: defaultCountries,
      priceBrackets: [
        {
          value: defaultPercentage
            ? String(round(defaultPercentage * 100, 3))
            : "2",
          pricePoint: "0",
        },
      ],
    });
  }

  return pricingRuleSetReturn;
}

export function pricingRulesConvertValueToTeam(
  pricingRuleSets: PricingRuleSetValue[] | undefined,
) {
  let teamReturn;
  if (pricingRuleSets) {
    teamReturn = pricingRuleSets.map((x) => {
      return {
        type: x.type,
        countries: x.countries,
        priceBrackets: x.priceBrackets.map((pb) => {
          let newValue = Number(pb.value);
          if (x.type === "percentage") {
            newValue = round(newValue / 100.0, 3);
          }
          return { value: newValue, pricePoint: Number(pb.pricePoint) };
        }),
      };
    });

    return teamReturn;
  } else {
    return undefined;
  }
}
