import { z } from "zod";

export enum PaymentSchedule {
  PREPAID = "prepay",
  POSTPAID = "postpay",
}

export enum AlertStatus {
  ALERTED = "alerted",
  UNALERTED = "unalerted",
}

export const BaseBalanceSchema = z.object({
  amount: z.string(),
  paymentSchedule: z.nativeEnum(PaymentSchedule),
  alertThreshold: z.string(),
  alertStatus: z.nativeEnum(AlertStatus),
});

// Map settings types to a Mongoose model and a Typescript interface
export const balanceSchemas = { "outbound-labels": BaseBalanceSchema };

export enum BalanceTypes {
  OUTBOUND_LABELS = "outbound-labels",
}

export type balanceTypes = {
  [key in keyof typeof balanceSchemas]: z.infer<(typeof balanceSchemas)[key]>;
};
