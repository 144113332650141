import { Type } from ".";

/**
 * application
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-4.5 RFC 2046 §4.5 Application Media Type}
 */
export const ApplicationType = new Type("application");

/**
 * application/octet-stream
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-4.5.1 RFC 2046 §4.5.1 Octet-Stream Subtype}
 */
export const ApplicationOctetStreamType =
  ApplicationType.subtype("octet-stream");

/**
 * application/postscript
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-4.5.2 RFC 2046 §4.5.2 PostScript Subtype}
 */
export const ApplicationPostscriptType = ApplicationType.subtype("postscript");
