import { useHover } from "@redotech/react-util/hover";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import ThreeDotsHorizontalIcon from "../../arbiter-icon/dots-horizontal.svg";
import { Flex } from "../../flex";
import CheckIcon from "../../icon-old/check.svg";
import { RedoButton, RedoButtonSize } from "../buttons/redo-button";
import { RedoCheckbox, RedoCheckboxSize } from "../checkbox/redo-checkbox";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../command-menu/redo-command-menu";
import * as redoListItemCss from "./redo-list-item.module.css";

export enum RedoListItemSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum RedoListItemVariant {
  CHECKMARK = "checkmark",
  CHECKBOX = "checkbox",
  NONE = "none",
}

export type RedoListItemMenu = { onClick(): void } | RedoCommandMenuItem[];

export interface RedoListItemProps {
  children: ReactNode;
  size: RedoListItemSize;
  selectionVariant?: RedoListItemVariant;
  selected?: boolean;
  menu?: RedoListItemMenu;
  focused?: boolean;
  itemClicked(): void;
  onItemHovered(hovered: boolean): void;
  disabled?: boolean;
  suffix?: ReactNode;
}

export const RedoListItem = memo(
  forwardRef(function RedoListItem(
    {
      size,
      children,
      selectionVariant = RedoListItemVariant.CHECKMARK,
      selected,
      menu,
      focused: externallyFocused,
      itemClicked,
      onItemHovered,
      disabled,
      suffix,
    }: RedoListItemProps,
    ref: ForwardedRef<HTMLElement>,
  ) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const isHovered = useHover(anchor);

    useEffect(() => {
      onItemHovered(isHovered);
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHovered]);

    if (Array.isArray(menu) && menu.length === 0) {
      menu = undefined;
    }

    function setAnchorRef(anchorRef: HTMLElement | null) {
      setAnchor(anchorRef);
      if (typeof ref === "function") {
        ref(anchorRef);
      } else if (ref) {
        ref.current = anchorRef;
      }
    }

    const [menuOpen, setMenuOpen] = useState(false);

    const focused = menuOpen || externallyFocused;

    const sizeMapping: Record<RedoListItemSize, RedoButtonSize> = {
      [RedoListItemSize.SMALL]: RedoButtonSize.EXTRA_SMALL,
      [RedoListItemSize.MEDIUM]: RedoButtonSize.SMALL,
      [RedoListItemSize.LARGE]: RedoButtonSize.REGULAR,
    };

    const listItemMenu = menu && focused && (
      <RedoListItemMenu
        disabled={disabled}
        menu={menu}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        size={sizeMapping[size]}
      />
    );

    const rightArea =
      focused && listItemMenu ? (
        listItemMenu
      ) : selected && selectionVariant === RedoListItemVariant.CHECKMARK ? (
        <Flex p="md">
          <CheckIcon />
        </Flex>
      ) : null;

    const leftArea =
      selectionVariant === RedoListItemVariant.CHECKBOX ? (
        <div>
          <RedoCheckbox
            setValue={() => itemClicked()}
            size={RedoCheckboxSize.SMALL}
            value={!!selected}
          />
        </div>
      ) : null;

    return (
      <Flex
        align="center"
        className={classNames(
          redoListItemCss.listItemContainer,
          (focused || selected) && redoListItemCss.focused,
          redoListItemSizeCss[size],
          disabled && redoListItemCss.disabled,
        )}
        gap="xs"
        justify="space-between"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (!disabled) {
            itemClicked();
          }
        }}
        p="xs"
        radius={size === RedoListItemSize.SMALL ? "xs" : "md"}
        ref={setAnchorRef}
      >
        {leftArea}
        <Flex
          as="button"
          className={classNames(
            redoListItemCss.childrenContainer,
            disabled && redoListItemCss.disabledChildren,
          )}
        >
          {children}
        </Flex>
        {rightArea}
        <Flex w="fit-content">{suffix}</Flex>
      </Flex>
    );
  }),
);

const RedoListItemMenu = memo(function RedoListItemMenu({
  menu,
  menuOpen,
  setMenuOpen,
  size,
  disabled = false,
}: {
  menu: RedoListItemMenu;
  setMenuOpen(value: boolean): void;
  menuOpen: boolean;
  size: RedoButtonSize;
  disabled?: boolean;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  if (Array.isArray(menu)) {
    return (
      <>
        <RedoButton
          disabled={disabled}
          IconLeading={ThreeDotsHorizontalIcon}
          onClick={(event) => {
            if (!disabled) {
              event.stopPropagation();
              setMenuOpen(!menuOpen);
            }
          }}
          ref={setAnchor}
          size={RedoButtonSize.REGULAR}
        />
        <RedoCommandMenu
          anchor={anchor}
          items={menu}
          open={menuOpen}
          setOpen={setMenuOpen}
          size={size}
        />
      </>
    );
  } else {
    return (
      <RedoButton
        disabled={disabled}
        IconLeading={ThreeDotsHorizontalIcon}
        onClick={() => {
          if (!disabled) {
            menu.onClick();
          }
        }}
        ref={setAnchor}
        size={size}
      />
    );
  }
});

const redoListItemSizeCss: Record<RedoListItemSize, string> = {
  [RedoListItemSize.SMALL]: redoListItemCss.small,
  [RedoListItemSize.MEDIUM]: redoListItemCss.medium,
  [RedoListItemSize.LARGE]: redoListItemCss.large,
};
