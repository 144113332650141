import { z } from "zod";

export const supportViewFolderSchema = z.object({
  _id: z.string(),
  name: z.string(),
  viewIds: z.array(z.string()),
  defaultViews: z.array(z.string()),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
});

export type FolderSchema = z.infer<typeof supportViewFolderSchema>;
