import { LoadState, useTriggerLoad } from "@redotech/react-util/load";
import { fn } from "@redotech/util/function";
import {
  Context,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export class MissingContextError<T> extends Error {
  constructor(readonly context: Context<T>) {
    super(`No value provided for ${context.displayName || "context"}`);
  }
}

/**
 * useContext, but throws error if undefined
 * @param context Context
 */
export function useRequiredContext<T>(context: Context<T | undefined>): T {
  const value = useContext(context);
  if (value === undefined) {
    throw new MissingContextError(context);
  }
  return value;
}

export interface LazyContext<T> {
  internalContext: Context<LazyLoadState<T>>;
}

export function createLazyContext<T>(): LazyContext<T> {
  const context = createContext<LazyLoadState<T>>({
    init: fn,
    invalidate: fn,
    pending: false,
  });
  return { internalContext: context };
}

export interface LazyLoadState<T> extends LoadState<T> {
  init(): void;
  invalidate(): void;
}

export function useLazyContext<T>({
  internalContext: context,
}: LazyContext<T>): [LoadState<T>, () => void] {
  const loadState = useContext(context);
  useEffect(() => {
    loadState.init();
  }, [loadState]);
  return [loadState, loadState.invalidate];
}

export function useLazyContextProvider<T>(
  fetcher: (signal: AbortSignal) => Promise<T>,
): LazyLoadState<T> {
  const [data, loadData] = useTriggerLoad(async (signal) => fetcher(signal));
  const [firstLoadTriggered, setFirstLoadTriggered] = useState(false);

  useEffect(() => {
    if (firstLoadTriggered) {
      loadData();
    }
  }, [firstLoadTriggered, loadData]);

  const loadState = useMemo(() => {
    const loadState = data || { pending: false };

    return {
      ...loadState,
      init: () => {
        if (!firstLoadTriggered) {
          setFirstLoadTriggered(true);
        }
      },
      invalidate: () => {
        loadData();
      },
    };
  }, [data, loadData, firstLoadTriggered]);

  return loadState;
}
