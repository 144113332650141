import { OutletFade } from "@redotech/react-animation/outlet-fade";
import { IterableMap, genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { getKey } from "@redotech/react-util/key";
import { useKeyedRef } from "@redotech/react-util/ref";
import * as classnames from "classnames";
import {
  CSSProperties,
  Key,
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as tabCss from "./tab.module.css";

function defaultDisabled() {
  return false;
}

export enum TabSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

// we need this component to avoid breaking hook rules with the useHandler hook
function TabItem<T>({
  option,
  isActive,
  isDisabled,
  tabSize,
  tab,
  valueChange,
  refElement,
  variableWidthTabs,
}: {
  option: T;
  isActive: boolean;
  isDisabled: boolean;
  tabSize: TabSize;
  tab(option: T): ReactNode;
  valueChange(value: T): void;
  refElement: (el: HTMLButtonElement | null) => void;
  variableWidthTabs?: boolean;
}) {
  const action = useHandler(() => {
    valueChange(option);
  });
  return (
    <Tab
      action={action}
      active={isActive}
      disabled={isDisabled}
      ref={refElement}
      tabSize={tabSize}
      variableWidthTabs={variableWidthTabs}
    >
      {tab(option)}
    </Tab>
  );
}

export const Tabs = genericMemo(function Tabs<T>({
  children,
  keyFn = getKey,
  options,
  disabled = defaultDisabled,
  tab,
  value,
  valueChange,
  tabWidth,
  tabSize = TabSize.MEDIUM,
  customTabCss,
  customContainerCss,
  customOutletCss,
  variableWidthTabs = false,
}: {
  children?: ReactNode;
  keyFn?(option: T, index: number): Key;
  disabled?(option: T): boolean;
  options: Iterable<T>;
  tab(option: T): ReactNode;
  value: T;
  valueChange(value: T): void;
  tabWidth?: string;
  tabSize?: TabSize;
  customTabCss?: CSSProperties;
  customContainerCss?: CSSProperties;
  customOutletCss?: CSSProperties;
  variableWidthTabs?: boolean;
}) {
  const optionArray = useMemo(() => [...options], [options]);
  const index = optionArray.indexOf(value);

  const [getRef, setRef] = useKeyedRef<Key, HTMLElement>();

  const [activeTabRef, setActiveTabRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const ref = getRef(keyFn(value, index));
    setActiveTabRef(ref);
  }, [keyFn, index, value, getRef]);

  const onKeyDown: KeyboardEventHandler = (event) => {
    let newIndex: number;
    switch (event.key) {
      case "ArrowLeft":
        newIndex = index ? index - 1 : optionArray.length - 1;
        break;
      case "ArrowRight":
        newIndex = index < optionArray.length - 1 ? index + 1 : 0;
        break;
      default:
        return;
    }
    const option = optionArray[newIndex];
    valueChange(option);
    getRef(keyFn(option, index))?.focus();
  };

  const [tabsRef, setTabsRef] = useState<HTMLDivElement | null>(null);

  const leftMargin = useMemo(() => {
    if (!tabsRef || !activeTabRef) {
      return 0;
    }

    const parentX = tabsRef.getBoundingClientRect().x;
    const activeTabX = activeTabRef.getBoundingClientRect().x;
    return activeTabX - parentX;
  }, [tabsRef, activeTabRef]);

  const sliderWidth = useMemo(() => {
    if (!activeTabRef) {
      return 0;
    }

    return activeTabRef.getBoundingClientRect().width;
  }, [activeTabRef]);

  return (
    <div className={tabCss.container} style={customContainerCss}>
      <div
        className={tabCss.tabs}
        onKeyDown={onKeyDown}
        ref={setTabsRef}
        style={{ width: tabWidth || "auto", ...customTabCss }}
      >
        <IterableMap items={options} keyFn={keyFn}>
          {(option, i) => (
            <TabItem
              isActive={value === option}
              isDisabled={disabled(option)}
              option={option}
              refElement={setRef(keyFn(option, i))}
              tab={tab}
              tabSize={tabSize}
              valueChange={valueChange}
              variableWidthTabs={variableWidthTabs}
            />
          )}
        </IterableMap>
        <div
          className={tabCss.slide}
          style={{ left: `${leftMargin}px`, width: `${sliderWidth}px` }}
        />
      </div>
      {children && (
        <OutletFade
          containerClassName={tabCss.outlet}
          key_={keyFn(value, -1)}
          style={customOutletCss}
        >
          {children}
        </OutletFade>
      )}
    </div>
  );
});

const Tab = memo(
  forwardRef<
    HTMLButtonElement,
    {
      active: boolean;
      action(): void;
      children: ReactNode;
      disabled: boolean;
      tabSize: TabSize;
      variableWidthTabs?: boolean;
    }
  >(function Tab(
    { active, children, action, disabled = false, tabSize, variableWidthTabs },
    ref,
  ) {
    return (
      <button
        aria-selected={active}
        className={classnames(
          tabCss.tab,
          { [tabCss.active]: active },
          { [tabCss.large]: tabSize === TabSize.LARGE },
          { [tabCss.small]: tabSize === TabSize.SMALL },
          { [tabCss.evenWidth]: !variableWidthTabs },
        )}
        disabled={disabled}
        onClick={action}
        ref={ref}
        type="button"
      >
        {children}
      </button>
    );
  }),
);
