import { CountryCode } from "@redotech/locale/countries";
import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import {
  CART_ATTRIBUTE_KEY,
  DEFAULT_TEXT,
  ExtendedWarrantyDataTarget,
  ExtendedWarrantyShopMetafield,
  ExtendedWarrantyShopMetafieldStyles,
  NO_COVERAGE_TIME_ID,
  Offering,
  SHOP_METAFIELD_KEY,
  SHOP_METAFIELD_NAMESPACE,
  SHOP_METAFIELD_STYLES_KEY,
  getCheapestOffering,
} from "@redotech/redo-model/extended-warranty";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import {
  ExtendedWarrantyDefaultSelection,
  ExtendedWarrantyPdpExperience,
} from "@redotech/redo-model/team";
import { calculateOfferings } from "@redotech/redo-shopify-utils/calculate-offerings";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import htmlReactParser from "html-react-parser";
import { memo, useCallback, useEffect, useState } from "react";
import { ExtendedWarrantyOptionsProps } from ".";
import { ShopifyExtensionClientContext } from "../../client/client-context";
import { getCollections } from "../../client/shopify-extension-client";
import { ModalContentV2ImageWrapper, ModalLogo } from "../../modal";
import { ExtensionShopifyClientContext } from "../../shopify";
import { getVariantIdFromUrl } from "../../utils";
import * as warrantyStyles from "./extended-warranty-options.module.css";

export const ExtendedWarrantyOptions = memo(function ExtendedWarrantyOptions({
  settings,
}: {
  settings: ExtendedWarrantyOptionsProps;
}) {
  const [variant, setVariant] = useState<any>(getVariant());
  const [offerings, setOfferings] = useState<Offering[]>([]);
  const [cheapestOffering, setCheapestOffering] = useState<
    Offering | undefined
  >(undefined);
  const [selectedOffering, setSelectedOffering] = useState<
    Offering | undefined
  >(undefined);

  const shopifyClient = useRequiredContext(ExtensionShopifyClientContext);
  const redoShopifyExtensionClient = useRequiredContext(
    ShopifyExtensionClientContext,
  );

  useEffect(() => {
    overrideWindowHistory();
    resetVariant();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overrideWindowHistory = () => {
    const originalReplaceState = window.history.replaceState;
    window.history.replaceState = function (
      ...args: Parameters<typeof window.history.replaceState>
    ) {
      originalReplaceState.apply(window.history, args);
      resetVariant();
    };
  };

  const resetVariant = () => {
    setVariant(getVariant());
  };

  const settingsLoad = useLoad(async (signal) => {
    const productId = (window as any).ShopifyAnalytics?.meta?.product?.id;
    if (!productId) {
      return { metafieldValue: null, collections: [] };
    }
    const [baseShop, stylesShop, collections] = await Promise.all([
      shopifyClient.getShopMetafield({
        key: SHOP_METAFIELD_KEY,
        namespace: SHOP_METAFIELD_NAMESPACE,
      }),
      shopifyClient.getShopMetafield({
        key: SHOP_METAFIELD_STYLES_KEY,
        namespace: SHOP_METAFIELD_NAMESPACE,
      }),
      getCollections(redoShopifyExtensionClient, {
        signal,
        productId: String(productId),
      }),
    ]);

    const baseMetafieldValue = baseShop?.metafield?.value;
    if (!baseMetafieldValue) {
      return { collections: [] };
    }

    const baseSettings = JSON.parse(
      baseMetafieldValue,
    ) as ExtendedWarrantyShopMetafield;

    const stylesMetafieldValue = stylesShop?.metafield?.value;
    let stylesSettings: ExtendedWarrantyShopMetafieldStyles | {} = {};
    if (stylesMetafieldValue) {
      stylesSettings = JSON.parse(
        stylesMetafieldValue,
      ) as ExtendedWarrantyShopMetafieldStyles;
    }
    return { collections, settings: { ...baseSettings, ...stylesSettings } };
  }, []);

  useEffect(() => {
    if (
      !settingsLoad.value ||
      !settingsLoad.value.settings ||
      !settingsLoad.value.collections ||
      settingsLoad.value.collections.length === 0 ||
      !variant
    ) {
      return;
    }
    const { collections, settings } = settingsLoad.value;

    if (!settings?.enabled) {
      setOfferings([]);
      return;
    }

    const offerings = calculateOfferings({
      collectionOfferings: settings.collectionSettings,
      variant: {
        price: Number(variant.price),
        variantId: String(variant.id),
        collections,
      },
      currency: String(
        (window as any).ShopifyAnalytics?.meta?.currency,
      ) as Currency,
      countryCode: window.Shopify?.country as CountryCode,
    });

    if (offerings && offerings.length > 0) {
      setOfferings(offerings);
      setCheapestOffering(getCheapestOffering(offerings));
    }
  }, [settingsLoad.value, variant]);

  const updateCart = useCallback(
    async ({ offering }: { offering?: Offering | undefined }) => {
      const cart = await shopifyClient.cartGet();
      const existingAttribute = JSON.parse(
        cart?.attributes?.[CART_ATTRIBUTE_KEY] || "{}",
      );

      const existingOffering = offerings.find(
        (o) => o.id === existingAttribute[variant.id]?.timeId,
      );

      if (!offering && existingOffering) {
        offering = existingOffering;
      } else if (!offering) {
        if (settings.experience === "upsell_modal") {
          offering = offerings.find(
            (offering) => offering.id === NO_COVERAGE_TIME_ID,
          );
        } else {
          switch (settings.defaultSelection) {
            case ExtendedWarrantyDefaultSelection.CHEAPEST:
              offering = getCheapestOffering(offerings);
              break;
            case ExtendedWarrantyDefaultSelection.NO_COVERAGE:
              offering = offerings.find(
                (offering) => offering.id === NO_COVERAGE_TIME_ID,
              );
              break;
          }
        }
      }

      if (!offering) {
        return;
      }

      existingAttribute[variant.id] = {
        collectionHandle: offering.collectionHandle,
        timeId: offering.id,
      };

      void shopifyClient.updateCartAttribute({
        attributes: [
          { key: CART_ATTRIBUTE_KEY, value: JSON.stringify(existingAttribute) },
        ],
      });
      setSelectedOffering(offering);
    },
    [offerings, shopifyClient, variant, settings],
  );

  useEffect(() => {
    if (offerings.length > 0) {
      void updateCart({});
    }
  }, [offerings, updateCart]);

  const onTileClick = (offering: Offering) => {
    if (offering.title === selectedOffering?.title) {
      return;
    }
    void updateCart({ offering });
  };

  const [showModal, setShowModal] = useState(false);

  const handleAddClick = useHandler(() => {
    setShowModal(true);
  });

  const handleLearnMoreClick = useHandler(() => {
    setShowModal(true);
  });

  const handleModalClose = useHandler(() => {
    setShowModal(false);
  });

  if (offerings.length === 0) {
    return null;
  }

  return (
    <>
      <ExtendedWarrantyOptionsExperience
        cheapestOffering={cheapestOffering}
        customCss={settingsLoad.value?.settings?.customPdpCss}
        experience={
          settingsLoad.value?.settings?.pdpExperience ?? settings.experience
        }
        offerings={offerings}
        onAddClick={handleAddClick}
        onLearnMoreClick={handleLearnMoreClick}
        onTileClick={onTileClick}
        selectedOffering={selectedOffering}
        text={{
          pdpTitle: settingsLoad.value?.settings?.pdpTitle,
          pdpLabel: settingsLoad.value?.settings?.pdpLabel,
          pdpDescription: settingsLoad.value?.settings?.pdpDescription,
          pdpAddToCartDescription:
            settingsLoad.value?.settings?.pdpAddToCartDescription,
          pdpAddedToCartTitle:
            settingsLoad.value?.settings?.pdpAddedToCartTitle,
          pdpAddedToCartDescription:
            settingsLoad.value?.settings?.pdpAddedToCartDescription,
        }}
      />
      <ExtendedWarrantyModal
        customCss={settingsLoad.value?.settings?.customPdpCss}
        logo={settingsLoad.value?.settings?.logo ?? settings.logo}
        offerings={offerings}
        onClose={handleModalClose}
        onTileClick={onTileClick}
        open={showModal}
        selectedOffering={selectedOffering}
        sideImage={
          settingsLoad.value?.settings?.sideImage ?? settings.modalImage
        }
        text={{
          modalTitle: settingsLoad.value?.settings?.modalTitle,
          modalCoverageHeader:
            settingsLoad.value?.settings?.modalCoverageHeader,
          modalDescription: settingsLoad.value?.settings?.modalDescription,
          modalBulletPoints: settingsLoad.value?.settings?.modalBulletPoints,
          modalPlanHeader: settingsLoad.value?.settings?.modalPlanHeader,
        }}
      />
    </>
  );
});

export const ExtendedWarrantyOptionsExperience = memo(
  function ExtendedWarrantyOptionsExperience({
    customCss,
    experience,
    offerings,
    selectedOffering,
    cheapestOffering,
    onTileClick,
    onAddClick,
    onLearnMoreClick,
    text,
  }: {
    customCss?: string;
    experience: ExtendedWarrantyPdpExperience;
    offerings: Offering[];
    selectedOffering?: Offering;
    cheapestOffering?: Offering;
    onTileClick: (offering: Offering) => void;
    onAddClick: () => void;
    onLearnMoreClick: () => void;
    text?: {
      pdpTitle?: string;
      pdpLabel?: string;
      pdpDescription?: string;
      pdpAddToCartDescription?: string;
      pdpAddedToCartTitle?: string;
      pdpAddedToCartDescription?: string;
    };
  }) {
    const {
      pdpTitle,
      pdpDescription,
      pdpAddToCartDescription,
      pdpAddedToCartTitle,
      pdpAddedToCartDescription,
    } = {
      pdpTitle: text?.pdpTitle || DEFAULT_TEXT.pdpTitle,
      pdpDescription: text?.pdpDescription || DEFAULT_TEXT.pdpDescription,
      pdpAddToCartDescription:
        text?.pdpAddToCartDescription || DEFAULT_TEXT.pdpAddToCartDescription,
      pdpAddedToCartTitle:
        text?.pdpAddedToCartTitle || DEFAULT_TEXT.pdpAddedToCartTitle,
      pdpAddedToCartDescription:
        text?.pdpAddedToCartDescription ||
        DEFAULT_TEXT.pdpAddedToCartDescription,
    };

    return (
      <>
        {customCss ? <style>{customCss}</style> : null}
        <Flex
          align="center"
          data-target={ExtendedWarrantyDataTarget.PDP_CONTAINER}
          gap="xs"
          justify="space-between"
        >
          <Flex
            align="flex-start"
            data-target={ExtendedWarrantyDataTarget.PDP_CONTENT}
            gap="xs"
          >
            {experience === ExtendedWarrantyPdpExperience.UPSELL_MODAL &&
            selectedOffering &&
            selectedOffering.id !== NO_COVERAGE_TIME_ID ? (
              <>
                <ShieldTickIcon
                  className={warrantyStyles.shieldIcon}
                  data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
                />
                <div>
                  <div
                    className={warrantyStyles.title}
                    data-target={ExtendedWarrantyDataTarget.PDP_TITLE}
                  >
                    {htmlReactParser(pdpAddedToCartTitle)}
                  </div>
                  <div
                    className={warrantyStyles.subtitle}
                    data-target={ExtendedWarrantyDataTarget.PDP_SUBTITLE}
                  >
                    {htmlReactParser(
                      pdpAddedToCartDescription.replace(
                        "%plan_title%",
                        selectedOffering.title,
                      ),
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <ShieldTickIcon
                  className={warrantyStyles.shieldIcon}
                  data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
                />
                <div>
                  <div
                    className={warrantyStyles.title}
                    data-target={ExtendedWarrantyDataTarget.PDP_TITLE}
                  >
                    {htmlReactParser(pdpTitle)}
                  </div>
                  <div
                    className={warrantyStyles.subtitle}
                    data-target={ExtendedWarrantyDataTarget.PDP_SUBTITLE}
                  >
                    {experience ===
                      ExtendedWarrantyPdpExperience.UPSELL_MODAL &&
                    !!cheapestOffering?.price?.amount
                      ? htmlReactParser(
                          pdpAddToCartDescription.replace(
                            "%price%",
                            CURRENCY_FORMAT(
                              cheapestOffering?.price?.currency,
                            ).format(cheapestOffering?.price?.amount),
                          ),
                        )
                      : htmlReactParser(pdpDescription)}
                  </div>
                </div>
              </>
            )}
          </Flex>
          {experience === ExtendedWarrantyPdpExperience.UPSELL_MODAL && (
            <ToggleAddButton
              dataTarget={ExtendedWarrantyDataTarget.PDP_ADD_BUTTON}
              onAddClick={onAddClick}
              selectedOffering={selectedOffering}
            />
          )}
        </Flex>

        {experience === ExtendedWarrantyPdpExperience.TILES_MODAL && (
          <>
            <OfferingTiles
              offerings={offerings}
              onTileClick={onTileClick}
              selectedOffering={selectedOffering}
              text={text}
            />

            <Flex
              data-target={ExtendedWarrantyDataTarget.PDP_LEARN_MORE_CONTAINER}
              pl="xxs"
            >
              <LearnMoreLink onClick={onLearnMoreClick} />
            </Flex>
          </>
        )}
      </>
    );
  },
);

export const LearnMoreLink = memo(function LearnMoreLink({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <a
      className={warrantyStyles.learnMore}
      data-target={ExtendedWarrantyDataTarget.LEARN_MORE_LINK}
      onClick={onClick}
    >
      Learn more
    </a>
  );
});

export const ToggleAddButton = memo(function ToggleAddButton({
  selectedOffering,
  onAddClick,
  dataTarget,
}: {
  selectedOffering?: Offering;
  onAddClick: () => void;
  dataTarget?: string;
}) {
  return (
    <button
      className={warrantyStyles.addButton}
      data-target={dataTarget}
      onClick={onAddClick}
      type="button"
    >
      <Flex
        align="center"
        data-target={ExtendedWarrantyDataTarget.ADD_BUTTON_CONTAINER}
        gap="xs"
        justify="center"
        px="md"
        py="xs"
      >
        <Text
          fontSize="xs"
          fontWeight="medium"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {!!selectedOffering && selectedOffering?.id !== NO_COVERAGE_TIME_ID
            ? "MODIFY"
            : "ADD"}
        </Text>
      </Flex>
    </button>
  );
});

export const OfferingTiles = memo(function OfferingTiles({
  offerings,
  selectedOffering,
  onTileClick,
  excludeNoCoverage = false,
  text,
}: {
  offerings: Offering[];
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  excludeNoCoverage?: boolean;
  text?: { pdpLabel?: string };
}) {
  const offeringsToRender = excludeNoCoverage
    ? offerings.filter((offering) => offering.id !== NO_COVERAGE_TIME_ID)
    : offerings;

  const { pdpLabel } = { pdpLabel: text?.pdpLabel || DEFAULT_TEXT.pdpLabel };

  return (
    <div
      className={warrantyStyles.offeringsContainer}
      data-target={ExtendedWarrantyDataTarget.OFFERING_TILES_CONTAINER}
    >
      {offeringsToRender.map((offering) => (
        <div
          className={classNames(
            warrantyStyles.offeringOption,
            offering.title === selectedOffering?.title
              ? warrantyStyles.selectedOption
              : warrantyStyles.unselectedOption,
          )}
          data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_CONTAINER}
          key={offering.title}
          onClick={() => onTileClick(offering)}
        >
          <div>
            <div
              className={warrantyStyles.offeringTitle}
              data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_TITLE}
            >
              {offering.title}
            </div>
            {!!(offering.price && offering.price.amount) && (
              <div
                className={warrantyStyles.offeringSubtitle}
                data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_SUBTITLE}
              >
                {htmlReactParser(pdpLabel)}
              </div>
            )}
          </div>
          <div
            className={warrantyStyles.offeringPrice}
            data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_PRICE}
          >
            {!!(offering.price && offering.price.amount) &&
              CURRENCY_FORMAT(offering.price.currency).format(
                offering.price.amount,
              )}
          </div>
        </div>
      ))}
    </div>
  );
});

// TODO: Make this not any typed
function getVariant() {
  const variantId = getVariantIdFromUrl(location.href);
  const variants =
    (window as any).ShopifyAnalytics?.meta?.product?.variants || [];
  if (!variantId && variants.length > 0) {
    return variants[0];
  }
  const variant = variants.find((v) => String(v.id) === variantId);
  return variant;
}

export const ExtendedWarrantyModal = memo(function ExtendedWarrantyModal({
  open,
  onClose,
  sideImage,
  logo,
  offerings,
  selectedOffering,
  onTileClick,
  customCss,
  isPreview,
  text,
}: {
  open: boolean;
  onClose?(): void;
  sideImage?: string;
  logo?: string;
  offerings: Offering[];
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  customCss?: string;
  isPreview?: boolean;
  text?: {
    modalTitle?: string;
    modalSubtitle?: string;
    modalDescription?: string;
    modalCoverageHeader?: string;
    modalBulletPoints?: string[];
    modalPlanHeader?: string;
  };
}) {
  const {
    modalTitle,
    modalCoverageHeader,
    modalDescription,
    modalBulletPoints,
    modalPlanHeader,
  } = {
    modalTitle: text?.modalTitle || DEFAULT_TEXT.modalTitle,
    modalCoverageHeader:
      text?.modalCoverageHeader || DEFAULT_TEXT.modalCoverageHeader,
    modalDescription: text?.modalDescription || DEFAULT_TEXT.modalDescription,
    modalBulletPoints: text?.modalBulletPoints?.some(Boolean)
      ? text?.modalBulletPoints
      : DEFAULT_TEXT.modalBulletPoints,
    modalPlanHeader: text?.modalPlanHeader || DEFAULT_TEXT.modalPlanHeader,
  };

  const [modalSelectedOffering, setModalSelectedOffering] = useState<
    Offering | undefined
  >(selectedOffering);

  const handleTileClick = useHandler((offering: Offering) => {
    setModalSelectedOffering(offering);
  });

  const handleAccept = useHandler(() => {
    if (modalSelectedOffering) {
      onTileClick(modalSelectedOffering);
    }
    onClose?.();
  });

  const handleDecline = useHandler(() => {
    const noCoverageOffering = offerings.find(
      (offering) => offering.id === NO_COVERAGE_TIME_ID,
    );
    if (noCoverageOffering) {
      onTileClick(noCoverageOffering);
    }
    onClose?.();
  });

  useEffect(() => {
    if (selectedOffering && selectedOffering.id !== NO_COVERAGE_TIME_ID) {
      setModalSelectedOffering(selectedOffering);
    } else {
      setModalSelectedOffering(offerings[0]);
    }
  }, [selectedOffering, offerings]);

  return (
    <ModalContentV2ImageWrapper
      customCss={customCss}
      isExtendedWarranty
      isPreview={isPreview}
      onClose={onClose}
      open={open}
      sideImage={sideImage}
    >
      <Flex
        className={warrantyStyles.extendedWarrantyModal}
        data-target={ExtendedWarrantyDataTarget.MODAL_CONTAINER}
        flexDirection="column"
        gap="2xl"
      >
        <ModalLogo logo={logo} />
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_1}
          flexDirection="column"
          gap="sm"
        >
          <Text fontSize="xl" fontWeight="semibold">
            {htmlReactParser(modalTitle)}
          </Text>
          <Text fontSize="sm" fontWeight="thin">
            {htmlReactParser(modalDescription)}
          </Text>
        </Flex>
        {modalBulletPoints.length > 0 && (
          <Flex
            data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_2}
            flexDirection="column"
            gap="sm"
          >
            <Text fontSize="md" fontWeight="semibold">
              {htmlReactParser(modalCoverageHeader)}
            </Text>
            <ul
              className={classNames(
                warrantyStyles.bullets,
                warrantyStyles.subtitle,
              )}
              data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_2_LIST}
            >
              {modalBulletPoints.map((bulletPoint, index) => (
                <li
                  data-target={
                    ExtendedWarrantyDataTarget.MODAL_CONTENT_2_LIST_ITEM
                  }
                  key={index}
                >
                  <Text fontSize="sm" fontWeight="thin">
                    {htmlReactParser(bulletPoint)}
                  </Text>
                </li>
              ))}
            </ul>
          </Flex>
        )}
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_3}
          flexDirection="column"
          gap="xs"
        >
          <Text fontSize="md" fontWeight="semibold">
            {htmlReactParser(modalPlanHeader)}
          </Text>
          <OfferingTiles
            excludeNoCoverage
            offerings={offerings}
            onTileClick={handleTileClick}
            selectedOffering={modalSelectedOffering}
          />
        </Flex>
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_BUTTON_CONTAINER}
          gap="xs"
          justify="flex-end"
        >
          <RedoButton
            hierarchy={RedoButtonHierarchy.LINK_GRAY}
            onClick={handleDecline}
            size={RedoButtonSize.REGULAR}
            text="Decline coverage"
          />
          <RedoButton
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={handleAccept}
            size={RedoButtonSize.REGULAR}
            text="Add protection"
          />
        </Flex>
      </Flex>
    </ModalContentV2ImageWrapper>
  );
});
