var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Years from './DecadeView/Years.js';
import { tileGroupProps } from './shared/propTypes.js';
var DecadeView = function DecadeView(props) {
    function renderYears() {
        return React.createElement(Years, __assign({}, props));
    }
    return React.createElement("div", { className: "react-calendar__decade-view" }, renderYears());
};
DecadeView.propTypes = __assign({}, tileGroupProps);
export default DecadeView;
