// extracted by mini-css-extract-plugin
export var brand = "_1q-4";
export var compact = "_1q-7";
export var extraSmall = "_1q-8";
export var icon = "_1q-0";
export var large = "_1q-1";
export var medium = "_1q-2";
export var outline = "_1q-9";
export var primaryDark = "_1q-5";
export var primaryLight = "_1q-3";
export var primaryModern = "_1q-6";