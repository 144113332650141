import { SortDirection, TableSort } from "./table";

/** Columns in the conversations table that the user can sort the table by */
export const sortableConversationsTableColumns = [
  "customer",
  "summary",
  "assignee",
  "platform",
  "lastResponse",
  "lastMerchantResponse",
] as const;

export const defaultConversationsTableSort: TableSort<SortableConversationTableColumn> =
  { key: "lastResponse", direction: SortDirection.DESC };

export type SortableConversationTableColumn =
  (typeof sortableConversationsTableColumns)[number];

export const sortableConversationsTableColumnTitlesByKey: Record<
  SortableConversationTableColumn,
  string
> = {
  customer: "Customer",
  summary: "Summary",
  assignee: "Assignee",
  platform: "Platform",
  lastResponse: "Last response",
  lastMerchantResponse: "Last merchant response",
};
