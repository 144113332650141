export enum REDO_OUTBOUND_CARRIER_IDS {
  FEDEX_SMARTPOST = "ca_f996de1247154d2b84c4e51f3df913f6",
  FEDEX = "ca_19dd1198bb3d462ea25612b34f04d6a7",
  EASYPOST_USPS = "ca_fa25281c95a445939738a4725bd9c2b5",
  REDO_UPS = "ca_e5268da42e9f4886848ee597a8c467e3",
}

export function isRedoOutboundCarrierId(
  id: string,
): id is REDO_OUTBOUND_CARRIER_IDS {
  const ids: string[] = Object.values(REDO_OUTBOUND_CARRIER_IDS) as string[];
  return ids.includes(id);
}
