import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { Tabs } from "@redotech/redo-web/tab";
import { Children, ReactElement, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { RedoHorizontalTabs } from "./arbiter-components/tabs/redo-horizontal-tabs";
import { TabContentChangedEventType, TabsPortalContext } from "./page";

/**
 * @param tabCount - If specified, the number of tabs in the portal. Will be used to dynamically determine if the portal has tab content.
 * @returns
 */
export function TabsPortal({
  children,
  tabCount,
}: {
  children: ReactElement;
  tabCount?: number;
}) {
  const { portalNode, triggerTabContentChanged } =
    useContext(TabsPortalContext);

  const transitionState = useContext(OverlapTransitionStateContext);

  useEffect(() => {
    if (tabCount === 0) {
      triggerTabContentChanged(
        Symbol(TabContentChangedEventType.EXPLICITLY_TOLD_ZERO_CHILDREN),
      );
    } else {
      triggerTabContentChanged(
        Symbol(TabContentChangedEventType.CHECK_CHILDREN),
      );
    }
    return () =>
      triggerTabContentChanged(
        Symbol(TabContentChangedEventType.CHECK_CHILDREN),
      );
  }, [triggerTabContentChanged, transitionState, tabCount]);

  if (!portalNode) return null;

  Children.only(children);
  if (!(children.type === Tabs || children.type === RedoHorizontalTabs)) {
    throw new Error(
      "TabsPortal can only render a <Tabs> or <RedoHorizontalTabs> component.",
    );
  }

  if (transitionState === OverlapTransitionState.EXIT) {
    return null;
  }

  return createPortal(children, portalNode);
}
