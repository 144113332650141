import { z } from "zod";
import { zExt } from "./common/zod-util";
import { supportViewFolderSchema } from "./support-folder-parser";
import { FolderData } from "./support/folder-types";
import {
  UserNotificationSettingsZodSchema,
  type UserNotificationSettings,
} from "./user/user-notification-settings";

export interface PermissionEntry {
  name: string;
  action: PermissionAction;
}

export enum PermissionAction {
  ALLOW = "allow",
  DENY = "deny",
}

export const PermissionEntryZodSchema = z.object({
  name: z.string(),
  action: z.nativeEnum(PermissionAction),
});

export const GetUserZodSchema = z.object({
  roles: z.array(z.string()),
  _id: zExt.objectId(),
  email: z.string().email(),
  createdAt: z.date(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  name: z.string().nullish(),
  updatedAt: z.date(),
  image: z.array(z.any()).nullish(),
  team: z.string().nullish(),
  permissions: z.array(PermissionEntryZodSchema).nullish(),
  notifications: UserNotificationSettingsZodSchema,
  customViewOrdering: z
    .array(
      z.object({
        viewName: z.string().optional(),
        view: z.string().optional(),
      }),
    )
    .optional(),
  emailSignature: z.string().optional(),
  usePersonalSignature: z.boolean().optional(),
  viewFolders: supportViewFolderSchema,
});
export interface GetUser {
  roles: string[];
  _id: string;
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  name: string;
  updatedAt: string;
  image: any[];
  team: string;
  permissions: PermissionEntry[];
  notifications: UserNotificationSettings;
  customViewOrdering?: {
    viewName?: string; // View name for default views
    view?: string; // View _id for custom views
  }[];
  emailSignature?: string;
  usePersonalSignature?: boolean;
  viewFolders?: FolderData[];
}

export enum Permission {
  EDIT_SETTINGS = "Edit Settings",
  MANAGE_RETURN = "Manage Returns",
  MANAGE_CLAIM = "Manage Claims",
  MANAGE_ORDER = "Manage Orders",
  CLOSE_CONVERSATION = "Support: Close tickets",
  ASSIGN_TAG = "Support: Add tags to tickets",
  MANAGE_TAG = "Support: Create tags",
  CREATE_REPLY = "Support: Reply to customers",
  USE_MACRO = "Support: Use templates",
  CREATE_MACRO = "Support: Create templates",
  ARCHIVE_CONVERSATION = "Support: Archive tickets",
  // This is CREATE_TEAM_VIEW because we plan to add user-specific views at some point, with their own permission
  CREATE_TEAM_VIEW = "Support: Create views",
  EDIT_ASSIGNEE = "Support: Set assignee",
  EDIT_CUSTOMER = "Support: Set customer",
  CREATE_RULE = "Support: Create rule",
  DELETE_RULE = "Support: Delete rule",
}

export const permitted = (
  grantedPermissions: PermissionEntry[] | undefined,
  permissionName: Permission,
) => {
  if (!grantedPermissions || grantedPermissions.length === 0) {
    return false;
  }
  const permissionEntry = grantedPermissions.find(
    (permission) => permission.name === permissionName,
  );
  return !!permissionEntry && permissionEntry.action === PermissionAction.ALLOW;
};
