import { hexEncoding } from "./encoding";

/**
 * Generate random hexadecimal string
 */
export function randomHexString(random: Random, length: number) {
  const bytes = random.bytes(Math.ceil(length / 2));
  return hexEncoding.write(bytes).slice(0, length);
}

/**
 * Generate random string of numbers
 */
export function randomNumberString(random: Random, length: number) {
  const string = random
    .float(1)
    .toString(36)
    .slice(2, length + 2);
  return string.slice(0, length);
}

/**
 * Random generator
 */
export interface Random {
  /**
   * Generate random bytes
   */
  bytes(length: number): ArrayBuffer;
  /**
   * Generate random floating point, 0 <= result < max
   * @param max Exclusive maximum
   */
  float(max: number): number;
  /**
   * Generate random integer, 0 <= result < max
   * @param max Exclusive maximum
   */
  integer(max: number): number;
}

abstract class RandomBase implements Random {
  bytes(length: number): ArrayBuffer {
    const base = Math.pow(2, 32);
    const buffer = new Uint32Array(
      Math.ceil(length / Uint32Array.BYTES_PER_ELEMENT),
    );
    for (let i = 0; i < buffer.length; i++) {
      buffer[i] = this.integer(base);
    }
    return buffer.buffer.slice(0, length);
  }

  abstract float(max: number): number;

  integer(max: number): number {
    return Math.floor(this.float(max));
  }
}

/**
 * Pseudo-random generator, based on Math.random.
 */
export const nativeRandom: Random = new (class extends RandomBase {
  float(max: number): number {
    return Math.random() * max;
  }
})();

/**
 * Seeded pseudo-random generator, for deterministic results.
 */
export class SeededRandom extends RandomBase {
  private seed: number;
  constructor(seed: number) {
    super();
    this.seed = seed;
  }
  float(max: number): number {
    const x = Math.sin(this.seed++) * 10_000;
    return (x - Math.floor(x)) * max;
  }
}
