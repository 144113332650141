// extracted by mini-css-extract-plugin
export var chip = "_1u-9";
export var chipLabel = "_1u-a";
export var close = "_1u-7";
export var closeIcon = "_1u-8";
export var error = "_1u-6";
export var infoTooltip = "_1u-2";
export var innerContainer = "_1u-1";
export var input = "_1u-b";
export var medium = "_1u-5";
export var outerContainer = "_1u-0";
export var small = "_1u-4";
export var xSmall = "_1u-3";