import { RedoAdminClient } from ".";

export interface AuthTeam {
  authToken: string;
  user: any;
}

/**
 * POST /teams/:teamId/auth
 */
export async function updateCoverage(
  client: RedoAdminClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<AuthTeam> {
  const response = await client.client.post(
    `/orders/${encodeURIComponent(teamId)}/update_coverage`,
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /teams/:teamId/notify
 */
export async function notifySlack(
  client: RedoAdminClient,
  {
    teamId,
    teamName,
    signal,
  }: { teamId: string; teamName: string; signal?: AbortSignal },
): Promise<boolean> {
  const response = await client.client.post(
    `/teams/${encodeURIComponent(teamId)}/notify`,
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /teams/:teamId/sync-trackers
 */
export async function syncTrackers(
  client: RedoAdminClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<AuthTeam> {
  const response = await client.client.post(
    `/teams/${encodeURIComponent(teamId)}/sync-trackers`,
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /team/:teamId/sync-trackers
 */
export async function isSyncInProgress(
  client: RedoAdminClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<boolean> {
  const response = await client.client.get(
    `/teams/${encodeURIComponent(teamId)}/sync-trackers`,
    { headers: client.authorization(), signal },
  );
  return response.data.syncInProgress;
}
