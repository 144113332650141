import { useHandler } from "@redotech/react-util/hook";
import { getMaskedOutput, InputMask } from "@redotech/react-util/input-mask";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  memo,
  useState,
} from "react";
import { TextMeasureBox } from "../../common/text-measure-box";
import { SpacingValue } from "../../theme/box";
import { textClasses, TextProps, TextSizeValue } from "../../theme/typography";
import * as redoTextInputCss from "./base-redo-input.module.css";

export enum RedoInputState {
  DEFAULT = "Default",
  DISABLED = "Disabled",
  ERROR = "Error",
  READONLY = "Readonly",
}

export enum RedoInputSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface BaseRedoInputProps {
  value: string;
  setValue(value: string): void;
  placeholder?: string;
  size?: RedoInputSize;
  state?: RedoInputState;
  inputTypeHint?: HTMLInputTypeAttribute;
  required?: boolean;
  maxLength?: number;
  htmlPattern?: string;
  constrainToInputLength?: boolean;
  name?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  mask?: InputMask;
  htmlId?: string;
  autoCompleteDisabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
}

export const BaseRedoInput = memo(
  forwardRef(function BaseRedoInput(
    {
      size = RedoInputSize.SMALL,
      value,
      placeholder,
      setValue,
      inputTypeHint,
      state = RedoInputState.DEFAULT,
      required,
      maxLength,
      name,
      constrainToInputLength,
      htmlPattern,
      onFocus,
      onBlur,
      mask,
      htmlId,
      autoCompleteDisabled,
      onKeyDown,
      onClick,
      autoFocus,
    }: BaseRedoInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    // Needed for readonly styles
    const [textWidth, setTextWidth] = useState<number>(0);
    const [lastMask, setLastMask] = useState<InputMask | undefined>(mask);

    const disabled = state === RedoInputState.DISABLED;
    const readonly = state === RedoInputState.READONLY;

    const handleInputContentChange = useHandler(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const realValue = getMaskedOutput({
          mask,
          newInput: event.target.value,
        });
        setValue(realValue);
      },
    );

    if (mask !== lastMask) {
      setLastMask(mask);
      setValue(getMaskedOutput({ mask, newInput: value }));
    }

    return (
      <>
        <input
          autoComplete={autoCompleteDisabled ? "off" : undefined}
          autoFocus={autoFocus}
          className={classNames(
            textClasses(sizeToInputTextProps[size]),
            redoTextInputCss.input,
          )}
          disabled={disabled || readonly}
          id={htmlId}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={handleInputContentChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          pattern={htmlPattern}
          placeholder={placeholder}
          ref={ref}
          required={required}
          style={
            readonly || constrainToInputLength
              ? { width: textWidth + "px" }
              : {}
          }
          type={inputTypeHint}
          value={value}
        />
        <TextMeasureBox
          text={value || placeholder || ""}
          {...sizeToInputTextProps[size]}
          setTextWidth={setTextWidth}
          textWidth={textWidth}
        />
      </>
    );
  }),
);

export const sizeToInputTextProps: Record<RedoInputSize, TextProps> = {
  [RedoInputSize.SMALL]: { fontSize: "xs" },
  [RedoInputSize.MEDIUM]: { fontSize: "md" },
  [RedoInputSize.LARGE]: { fontSize: "md" },
};

export const sizeStyles: Record<RedoInputSize, string> = {
  [RedoInputSize.SMALL]: redoTextInputCss.small,
  [RedoInputSize.MEDIUM]: redoTextInputCss.medium,
  [RedoInputSize.LARGE]: redoTextInputCss.large,
};

export const sizeToDescriptorTextProps: Record<RedoInputSize, TextSizeValue> = {
  [RedoInputSize.SMALL]: "xs",
  [RedoInputSize.MEDIUM]: "sm",
  [RedoInputSize.LARGE]: "sm",
};

export const sizeToPx: Record<RedoInputSize, SpacingValue> = {
  [RedoInputSize.SMALL]: "md",
  [RedoInputSize.MEDIUM]: "lg",
  [RedoInputSize.LARGE]: "xl",
};
