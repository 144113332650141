import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { getKey } from "@redotech/react-util/key";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  AxisKind,
  isOutboundWeightByZoneRateTable,
  OutboundWeightByZoneRateTable,
  RateTable,
  RateTableType,
  RateTableValueMode,
  WeightUnit,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  isDefaultServiceLevel,
  RateTableCarrier,
  RateTableServiceLevelsMapping,
} from "@redotech/redo-model/rate-table/rate-table-mapping";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { memo, useEffect, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../../team";
import { LabelRateTableForm } from "../../common/rate-tables/rate-table";
import {
  rateCardForm,
  RateCardKeyForm,
  rateCardKeyForm,
  rateCardKeyFormDefault,
  RateCardValue,
  rateTableToTableData,
  tableDataToMinMaxValues,
  tableDataToRateData,
  tableDataToZones,
} from "../../common/rate-tables/rate-table-utils";
import { defaultNewOutboundRateTable } from "./default-new-rate-table";
import * as styles from "./rate-table-card.module.css";

export const OutboundLabelRateCardCard = memo(
  function OutboundLabelRateCardCard() {
    const team = useRequiredContext(TeamContext);
    const client = useRequiredContext(RedoAdminRpcClientContext);
    const [rateCardLoad, triggerRateCardLoad] = useTriggerLoad<
      OutboundWeightByZoneRateTable[]
    >(async () => {
      const tables = await client.getRateTables({ teamId: team._id });
      const filtered: OutboundWeightByZoneRateTable[] =
        tables.rateTables.filter((table: RateTable) =>
          isOutboundWeightByZoneRateTable(table),
        );
      return filtered;
    });

    const keyFormInput = useInput(rateCardKeyForm, rateCardKeyFormDefault);

    const rateTableInDB = useMemo<RateCardValue | undefined>(() => {
      const rateTable = rateCardLoad.value?.find(
        (rateTable: OutboundWeightByZoneRateTable) =>
          rateTable.carrier === keyFormInput.value.selectedCarrier &&
          rateTable.serviceLevel === keyFormInput.value.selectedServiceLevel &&
          rateTable.valueMode === keyFormInput.value.valueMode,
      );
      return rateTable
        ? { ...rateTable, data: rateTableToTableData(rateTable) }
        : undefined;
    }, [
      rateCardLoad.value,
      keyFormInput.value.selectedCarrier,
      keyFormInput.value.selectedServiceLevel,
      keyFormInput.value.valueMode,
    ]);

    // Service levels depends on the carrier
    const serviceLevelOptions = useMemo(() => {
      return RateTableServiceLevelsMapping[keyFormInput.value.selectedCarrier];
    }, [keyFormInput.value.selectedCarrier]);

    useEffect(() => {
      // Default service levels should always be spread rate tables
      if (isDefaultServiceLevel(keyFormInput.value.selectedServiceLevel)) {
        keyFormInput.inputs.valueMode.setValue(RateTableValueMode.Spread);
      }
    }, [keyFormInput.value.selectedServiceLevel]);

    // Select the first service level for that carrier when the service level options change
    useEffect(() => {
      keyFormInput.setValue({
        ...keyFormInput.value,
        selectedServiceLevel: serviceLevelOptions[0],
      });
    }, [serviceLevelOptions]);

    useEffect(() => {
      triggerRateCardLoad();
    }, [team, client]);

    return (
      <OutboundLabelRateCardCardInternal
        key={getKey(rateTableInDB)}
        keyFormInput={keyFormInput}
        rateTableInDB={rateTableInDB}
        serviceLevelOptions={serviceLevelOptions}
        triggerReload={triggerRateCardLoad}
      />
    );
  },
);

const OutboundLabelRateCardCardInternal = memo(
  function OutboundLabelRateCardCardInternal({
    rateTableInDB,
    keyFormInput,
    triggerReload,
    serviceLevelOptions,
  }: {
    rateTableInDB: RateCardValue | undefined;
    keyFormInput: RateCardKeyForm;
    triggerReload: () => void;
    serviceLevelOptions: string[];
  }) {
    const client = useRequiredContext(RedoAdminRpcClientContext);
    const team = useRequiredContext(TeamContext);
    const [savePending, setSavePending] = useState(false);

    const rateTableDataInput = useInput(
      rateCardForm,
      rateTableInDB ??
        defaultNewOutboundRateTable(
          keyFormInput.value.selectedCarrier,
          keyFormInput.value.selectedServiceLevel,
          keyFormInput.value.valueMode,
        ),
    );

    const saveDisabled = useMemo(
      () => savePending || rateTableDataInput.allErrors.length > 0,
      [savePending, rateTableDataInput.allErrors.length],
    );

    const saveRateCard = async () => {
      setSavePending(true);
      const updatedRateTable: RateTable = {
        rateTableType: RateTableType.OUTBOUND_WEIGHT_BY_ZONE,
        valueMode: keyFormInput.value.valueMode,
        carrier: keyFormInput.value.selectedCarrier,
        serviceLevel: keyFormInput.value.selectedServiceLevel,
        data: tableDataToRateData(rateTableDataInput.value.data),
        xAxis: {
          values: tableDataToZones(rateTableDataInput.value.data),
          kind: AxisKind.ZONE,
        },
        yAxis: {
          values: tableDataToMinMaxValues(rateTableDataInput.value.data),
          kind: AxisKind.WEIGHT,
          units: WeightUnit.LB,
        },
        defaultValue: rateTableDataInput.value.defaultValue,
      };

      try {
        await client.saveRateTable({
          rateTable: updatedRateTable,
          teamId: team._id,
        });
      } catch (error) {
        toast("Failed to save rate table", { variant: "error" });
        console.error(error);
      } finally {
        setSavePending(false);
        triggerReload();
      }
    };

    const addBottomRow = () => {
      const bottomRow =
        rateTableDataInput.value.data.at(-1)?.map((_) => "0") ?? [];
      rateTableDataInput.inputs.data.setValue([
        ...rateTableDataInput.value.data,
        bottomRow,
      ]);
    };

    const addRightColumn = () => {
      const newColumn =
        rateTableDataInput.value.data
          .map((row) => row.at(-1))
          .map((_) => "0") ?? [];
      const newCharges = rateTableDataInput.value.data.map((row, index) => [
        ...row,
        newColumn.at(index) ?? "0",
      ]);
      rateTableDataInput.inputs.data.setValue(newCharges);
    };

    const deleteRow = (index: number) => {
      rateTableDataInput.inputs.data.setValue(
        rateTableDataInput.value.data.filter((_, i) => i !== index),
      );
    };

    const deleteColumn = (columnIndex: number) => {
      rateTableDataInput.inputs.data.setValue(
        rateTableDataInput.value.data.map((row) =>
          row.filter((_, i) => i !== columnIndex),
        ),
      );
    };

    const addRateTable = async () => {
      await client.saveRateTable({
        rateTable: defaultNewOutboundRateTable(
          keyFormInput.value.selectedCarrier,
          keyFormInput.value.selectedServiceLevel,
          keyFormInput.value.valueMode,
        ),
        teamId: team._id,
      });
      triggerReload();
    };

    const copyTable = () => {
      const data = rateTableDataInput.value.data.map((row) => [...row]);
      // Make sure we copy a blank cell for the upper left cell, it's useless
      data[0][0] = "";
      const excelData = data.map((row) => row.join("\t")).join("\n");
      void navigator.clipboard.writeText(excelData);
    };

    return (
      <Card title="Outbound rate tables">
        <Flex dir="row" justify="space-between">
          <Flex dir="row" justify="space-between" w="full">
            <Flex dir="row">
              <FormSelectDropdown
                className={styles.rateTableCarrierDropdown}
                input={keyFormInput.inputs.selectedCarrier}
                label=""
                options={Object.values(RateTableCarrier)}
              >
                {(s) => s}
              </FormSelectDropdown>
              <FormSelectDropdown
                className={styles.rateTableServiceLevelDropdown}
                input={keyFormInput.inputs.selectedServiceLevel}
                label=""
                options={serviceLevelOptions}
              >
                {(s) => s}
              </FormSelectDropdown>
              {!isDefaultServiceLevel(
                keyFormInput.value.selectedServiceLevel,
              ) && (
                <FormSelectDropdown
                  className={styles.rateTableValueModeDropdown}
                  input={keyFormInput.inputs.valueMode}
                  label=""
                  options={Object.values(RateTableValueMode)}
                >
                  {(s) => s}
                </FormSelectDropdown>
              )}
              {!rateTableInDB ? (
                <RedoButton
                  hierarchy={RedoButtonHierarchy.PRIMARY}
                  onClick={addRateTable}
                  size={RedoButtonSize.LARGE}
                  text="Create"
                  theme={RedoButtonTheme.NORMAL}
                />
              ) : (
                rateTableDataInput.changed && (
                  <RedoButton
                    disabled={saveDisabled}
                    hierarchy={RedoButtonHierarchy.PRIMARY}
                    onClick={saveRateCard}
                    pending={savePending}
                    size={RedoButtonSize.LARGE}
                    text="Save table"
                    theme={RedoButtonTheme.NORMAL}
                  />
                )
              )}
            </Flex>
            {rateTableInDB && (
              <Flex dir="row">
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={CopyIcon}
                  onClick={copyTable}
                  size={RedoButtonSize.LARGE}
                  text="Copy table"
                  theme={RedoButtonTheme.NORMAL}
                />
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={PlusIcon}
                  onClick={addRightColumn}
                  size={RedoButtonSize.LARGE}
                  text="Add column"
                  theme={RedoButtonTheme.NORMAL}
                />
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={PlusIcon}
                  onClick={addBottomRow}
                  size={RedoButtonSize.LARGE}
                  text="Add row"
                  theme={RedoButtonTheme.NORMAL}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        {rateTableInDB && (
          <Flex dir="column">
            <Flex dir="row">
              <LabelRateTableForm
                deleteColumn={deleteColumn}
                deleteRow={deleteRow}
                input={rateTableDataInput}
              />
            </Flex>
            <Flex dir="column" pt="md" w="xs">
              <FormTextInput
                input={rateTableDataInput.inputs.defaultValue}
                label="Default spread (used if no match is found in the table)"
                type="number"
              />
            </Flex>
          </Flex>
        )}
      </Card>
    );
  },
);
