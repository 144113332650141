// extracted by mini-css-extract-plugin
export var checkbox = "_1a-0";
export var circle = "_1a-3";
export var horizontalContainer = "_1a-7";
export var sm = "_1a-5";
export var switch0 = "_1a-2";
export var switchDisabled = "_1a-8";
export var switchEnabled = "_1a-1";
export var textSwitchContainer = "_1a-9";
export var textSwitchOption = "_1a-a";
export var textSwitchOptionSelected = "_1a-b";
export var verticalContainer = "_1a-6";
export var xs = "_1a-4";