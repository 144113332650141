import { useAnimateHeight } from "@redotech/react-animation/transition";
import { useHandler } from "@redotech/react-util/hook";
import ChevronDown from "@redotech/redo-web/icon-old/chevron-down.svg";
import * as classnames from "classnames";
import { ReactNode, memo, useState } from "react";
import * as cardCss from "./card.module.css";
import { Flex } from "./flex";
import ChevronDownIcon from "./icon-old/chevron-down.svg";
import { StyleProps } from "./styles";
import { boxClasses } from "./theme/box";

export type CardSize = typeof CardSize.LARGE | typeof CardSize.MEDIUM;

export namespace CardSize {
  export const MEDIUM = Symbol("Medium");
  export const LARGE = Symbol("Large");
}

const cardSizeClasses = {
  [CardSize.MEDIUM]: cardCss.medium,
  [CardSize.LARGE]: cardCss.large,
};

export type CardTheme = typeof CardTheme.DARK | typeof CardTheme.LIGHT;

export namespace CardTheme {
  export const LIGHT = Symbol("Light");
  export const DARK = Symbol("Dark");
}

const cardThemeClasses = {
  [CardTheme.LIGHT]: undefined,
  [CardTheme.DARK]: cardCss.dark,
};

interface CardProps extends StyleProps {
  className?: string;
  headerExtra?: ReactNode | ReactNode[];
  scrollable?: boolean;
  theme?: CardTheme;
  size?: CardSize;
  title?: ReactNode | ReactNode[];
  titleBadge?: ReactNode;
  subtitle?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  bgImage?: URL | string;
  bgImageAlt?: string;
  noPadding?: boolean;
  customBackgroundColor?: string;
  customBorderColor?: string;
  customBorderRadius?: number;
  onClick?: () => void;
  onMouseDown?: (e?: React.MouseEvent) => void;
}

export const Card = memo(function Card(props: CardProps) {
  const paddingSet = [
    props.p,
    props.pt,
    props.pr,
    props.pb,
    props.pl,
    props.px,
    props.py,
  ].some((value) => !!value);
  return (
    <section
      className={classnames(
        props.className,
        cardCss.card,
        cardSizeClasses[props.size ?? CardSize.LARGE],
        cardThemeClasses[props.theme ?? CardTheme.LIGHT],
        {
          [cardCss.scrollable]: props.scrollable,
          [cardCss.noPadding]: props.noPadding,
        },
        ...boxClasses({
          ...props,
          bgColor: props.bgColor ?? "primary",
          radius: props.radius ?? "2xl",
          display: props.display ?? "flex",
          flexDirection: props.flexDirection ?? "column",
          position: props.position ?? "relative",
          p: (props.p ?? !paddingSet) ? "3xl" : undefined,
          gap: props.gap ?? "xl",
        }),
      )}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      style={{
        backgroundColor: props.customBackgroundColor
          ? props.customBackgroundColor
          : undefined,
        borderColor: props.customBorderColor
          ? props.customBorderColor
          : undefined,
        borderRadius:
          props.customBorderRadius !== undefined
            ? `${props.customBorderRadius}px`
            : undefined,
      }}
    >
      {props.bgImage && (
        <img
          alt={props.bgImageAlt}
          className={cardCss.bgImage}
          src={props.bgImage.toString()}
        />
      )}
      {props.title && (
        <div className={cardCss.headerContainer}>
          <header className={cardCss.header}>
            <Flex>
              <h2 className={classnames(cardCss.headerTitle, cardCss.large)}>
                {props.title}
              </h2>
              {props.titleBadge && props.titleBadge}
            </Flex>
            {props.subtitle && (
              <div className={cardCss.headerSubtitle}>{props.subtitle}</div>
            )}
          </header>
          {props.headerExtra}
        </div>
      )}
      {props.children}
    </section>
  );
});

interface ExpandableCardProps extends CardProps {
  actions?: ReactNode | ReactNode[];
}

export const ExpandableCard = memo(function ExpandableCard(
  props: ExpandableCardProps,
) {
  const [expanded, setExpanded] = useState(false);
  const title = (
    <Flex
      align="center"
      className={cardCss.expandableCardTitle}
      grow={1}
      justify="space-between"
      onClick={() => setExpanded((value) => !value)}
      pb={props.py}
      px={props.px}
    >
      <Flex align="center" gap="xl">
        <ChevronDown
          className={cardCss.chevron}
          style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
        />
        <div>{props.title}</div>
      </Flex>
      <Flex align="center">{props.actions}</Flex>
    </Flex>
  );
  console.log("props.py", props.py);
  return (
    <Card
      {...{ ...props, px: undefined, pt: props.py, py: undefined }}
      gap="none"
      title={title}
    >
      <div
        className={cardCss.expandableCard}
        style={{
          maxHeight: expanded ? undefined : "0",
          display: expanded ? undefined : "none",
        }}
      >
        {props.children}
      </div>
    </Card>
  );
});

export const Section = memo(function Section({
  children,
  className,
  expanded = true,
  header,
}: {
  children: ReactNode | ReactNode[];
  className?: string;
  expanded?: boolean;
  header: ReactNode | ReactNode[];
}) {
  const [display, setDisplay] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useAnimateHeight(display);

  return (
    <section
      className={classnames(className, cardCss.section, cardCss.outlined)}
      ref={setDisplay}
      style={animateStyles}
    >
      <header className={cardCss.sectionHeader}>{header}</header>
      {(expanded || animating) && (
        <>
          <hr className={cardCss.separator} />
          {children}
        </>
      )}
    </section>
  );
});

export const CollapseSubsection = memo(function CollapseSubsection({
  children,
  title,
  defaultCollapsed = true,
  inheritColor = false,
  className,
}: {
  children: ReactNode | ReactNode[];
  title: ReactNode;
  defaultCollapsed?: boolean;
  inheritColor?: boolean;
  className?: string;
}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useAnimateHeight(element);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const onClick = useHandler(() => setCollapsed((value) => !value));

  return (
    <section
      className={classnames(
        cardCss.section,
        cardCss.collapseSection,
        className,
        { [cardCss.collapsed]: collapsed },
      )}
      ref={setElement}
      style={animateStyles}
    >
      <div
        className={classnames(cardCss.collapseTrigger, {
          [cardCss.inherit]: inheritColor,
        })}
        onClick={onClick}
      >
        <ChevronDownIcon className={cardCss.collapseIcon} />
        <div className={cardCss.collapseTitle}>{title}</div>
      </div>
      {(animating || !collapsed) && <>{children}</>}
    </section>
  );
});

export const CollapseSection = memo(function CollapseSection({
  children,
  title,
  defaultCollapsed = true,
}: {
  children: ReactNode | ReactNode[];
  title: ReactNode;
  defaultCollapsed?: boolean;
}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useAnimateHeight(element);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const onClick = useHandler(() => setCollapsed((value) => !value));

  return (
    <section
      className={classnames(
        cardCss.section,
        cardCss.collapseSection,
        cardCss.outlined,
        { [cardCss.collapsed]: collapsed },
      )}
      ref={setElement}
      style={animateStyles}
    >
      <header className={cardCss.collapseHeader} onClick={onClick}>
        <ChevronDownIcon className={cardCss.collapseIcon} />
        <h4 className={cardCss.collapseTitle}>{title}</h4>
      </header>
      {(animating || !collapsed) && (
        <>
          <hr className={cardCss.separator} />
          {children}
        </>
      )}
    </section>
  );
});

/**
 * Return value >         $100.00
 * subtotals...           $....
 */
export const SubtotalCollapseSection = memo(function SubtotalCollapseSection({
  header,
  subtotals,
  defaultCollapsed = true,
  titleTextClassName = undefined,
  subtitleTextClassName = undefined,
  breakdownEntryTextClassName = undefined,
}: {
  header: { title: string; total: string | number };
  subtotals: { title: string; total: string | number }[];
  defaultCollapsed?: boolean;
  titleTextClassName?: string;
  subtitleTextClassName?: string;
  breakdownEntryTextClassName?: string;
}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useAnimateHeight(element);
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  const onClick = useHandler(() => setCollapsed((value) => !value));

  return (
    <section
      className={classnames(cardCss.section, cardCss.collapseSection, {
        [cardCss.collapsed]: collapsed,
      })}
      ref={setElement}
      style={animateStyles}
    >
      <div
        className={classnames(
          cardCss.subtotalItem,
          cardCss.subtotalCollapseHeader,
        )}
        onClick={onClick}
      >
        <div
          className={classnames(
            titleTextClassName || cardCss.collapseTitle,
            cardCss.subtotalCollapseHeaderTitle,
          )}
        >
          {header.title}
          <ChevronDownIcon className={cardCss.collapseIcon} />
        </div>
        <div
          className={breakdownEntryTextClassName || cardCss.breakdownEntryText}
        >
          {header.total}
        </div>
      </div>
      {(animating || !collapsed) &&
        subtotals.map((subtotal, index) => (
          <div className={cardCss.subtotalItem} key={index}>
            <div className={subtitleTextClassName}>{subtotal.title}</div>
            <div className={subtitleTextClassName}>{subtotal.total}</div>
          </div>
        ))}
    </section>
  );
});
