import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { DeliveryZone } from "../shopify/delivery-profile";

export enum DeliveryFilterType {
  // Filter out all redo options except the cheapest
  ONLY_CHEAPEST_REDO = "only-cheapest-redo",
  // Filter out all non-redo options
  ONLY_REDO = "only-redo",
  // Filter out all redo options (only useful for tests)
  NO_REDO = "no-redo",
  // Filter out all non-redo options except the cheapest one
  ONLY_CHEAPEST_NON_REDO = "only-cheapest-non-redo",
}

export enum DeliverySortType {
  // Cheapest Redo option, then sort all others by price
  REDO_CHEAPEST_FIRST = "redo-cheapest-first",
  // Show all Redo options before any other shipping options
  REDO_FIRST = "redo-first",
  // Sort all shipping options by price, cheapest first
  // Is essentially "toggle off"
  CHEAPEST_FIRST = "cheapest-first",
}

export enum CoverageProductEnum {
  SHIPPING = "Shipping",
  CHECKOUT = "Checkout",
}

export enum RateTableType {
  FIXED = "fixed",
  DYNAMIC = "dynamic",
}

export enum ConditionalPricingCondition {
  WEIGHT = "weight",
  PRICE = "price",
}

export const maxValue = 1000000000;

const DeliveryFilterTypeEnum = z.nativeEnum(DeliveryFilterType);

const DeliverySortTypeEnum = z.nativeEnum(DeliverySortType);

const WeightConditionSchema = z.object({
  type: z.literal(ConditionalPricingCondition.WEIGHT),
  minGrams: z.number(),
  maxGrams: z.number(),
});
export type WeightCondition = z.infer<typeof WeightConditionSchema>;

const PriceConditionSchema = z.object({
  type: z.literal(ConditionalPricingCondition.PRICE),
  minPrice: z.number(),
  maxPrice: z.number(),
  currency: z.string(),
});
export type PriceCondition = z.infer<typeof PriceConditionSchema>;

const RateConditionSchema = z.union([
  WeightConditionSchema,
  PriceConditionSchema,
]);
export type RateCondition = z.infer<typeof RateConditionSchema>;

export const ShippingRateLocationSchema = z.object({
  country: z.string(),
  provinces: z.array(z.string()),
});
export type ShippingRateLocation = z.infer<typeof ShippingRateLocationSchema>;

const BaseRateSchema = z.object({
  enabled: z.boolean().optional(),
  conditions: z.array(RateConditionSchema),
  currency: z.string(),
  merchantPaidCoverageTypes: z
    .object({
      return: z.boolean().optional(),
      packageProtection: z.boolean().optional(),
    })
    .optional(),
});

const FixedRateSchema = BaseRateSchema.extend({ price: z.string() });
export type FixedRate = z.infer<typeof FixedRateSchema>;

const DynamicRateSchema = BaseRateSchema.extend({
  markup: z
    .object({
      amount: z.string().optional(),
      percentage: z.string().optional(),
    })
    .optional(),
});
export type DynamicRate = z.infer<typeof DynamicRateSchema>;

export type Rate = FixedRate | DynamicRate;

const BaseShippingRateTableSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  description: z.string(),
  code: z.string(),
  type: z.nativeEnum(RateTableType),
  originLocations: z.array(ShippingRateLocationSchema),
  destinationLocations: z.array(ShippingRateLocationSchema),
  coverageTypes: z
    .object({
      return: z.boolean().optional(),
      packageProtection: z.boolean().optional(),
    })
    .optional(),
});

export const FixedShippingRateTableSchema = BaseShippingRateTableSchema.extend({
  type: z.literal(RateTableType.FIXED),
  rates: z.array(FixedRateSchema),
});
type FixedShippingRateTable = z.infer<typeof FixedShippingRateTableSchema>;

export const DynamicShippingRateTableSchema =
  BaseShippingRateTableSchema.extend({
    type: z.literal(RateTableType.DYNAMIC),
    rates: z.array(DynamicRateSchema),
  });

export const ShippingRateTableSchema = z.discriminatedUnion("type", [
  FixedShippingRateTableSchema,
  DynamicShippingRateTableSchema,
]);
export type ShippingRateTable = z.infer<typeof ShippingRateTableSchema>;

export const ShippingRateTableArraySchema = z.array(ShippingRateTableSchema);

const FilterSort = z.object({
  filterType: DeliveryFilterTypeEnum.optional(),
  sortType: DeliverySortTypeEnum.optional(),
});

export const ShippingCoverageZodSchema = z.object({
  coverage: z.nativeEnum(CoverageProductEnum).nullish(), //I don't think coverage product should live here, but I need this out quickly
  enabled: z.boolean(),
  checkoutOutboundRatesEnabled: z.boolean().optional(),
  rateTables: ShippingRateTableArraySchema,
  deliverySortType: DeliverySortTypeEnum.optional(),
  deliveryFilterType: DeliveryFilterTypeEnum.optional(),
  optIn: FilterSort.optional(),
  optOut: FilterSort.optional(),
});

export type ShippingCoverage = z.infer<typeof ShippingCoverageZodSchema>;

export function isFixedRateTable(
  rateTable: ShippingRateTable,
): rateTable is FixedShippingRateTable {
  return rateTable.type === RateTableType.FIXED;
}

export function isFixedRate(
  rate: ShippingRateTable["rates"][number],
): rate is FixedRate {
  return "price" in rate;
}

export function isWeightCondition(
  condition: RateCondition,
): condition is WeightCondition {
  return condition.type === ConditionalPricingCondition.WEIGHT;
}

export function isPriceCondition(
  condition: RateCondition,
): condition is PriceCondition {
  return condition.type === ConditionalPricingCondition.PRICE;
}

export function deliveryZoneToShippingRateLocations(
  deliveryZone: DeliveryZone,
): ShippingRateLocation[] {
  return deliveryZone.countries.map((country) => ({
    country: country.code.countryCode ?? country.name,
    provinces: country.provinces.map((province) => province.code),
  }));
}

export function deliveryZoneToOriginLocations(
  deliveryZone: DeliveryZone,
): ShippingRateLocation[] {
  return deliveryZone.countries.map((country) => ({
    country: country.code.countryCode ?? country.name,
    provinces: country.provinces.map((province) => province.code),
  }));
}
