import { RouterOutletFade } from "@redotech/react-router-util/outlet-fade";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { PopulatedSplit } from "@redotech/redo-model/split";
import { Team } from "@redotech/redo-model/team";
import { RedoHorizontalTabs } from "@redotech/redo-web/arbiter-components/tabs/redo-horizontal-tabs";
import { BreadcrumbSlot, useBreadcrumb } from "@redotech/redo-web/breadcrumb";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import ExternalLinkIcon from "@redotech/redo-web/icon-old/external-link.svg";
import { Action0PortalContext, Actions } from "@redotech/redo-web/page";
import { TabsPortal } from "@redotech/redo-web/tabs-portal";
import { createContext, memo, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { RedoAdminClientContext } from "../client/context";
import { getSplits, getTeam } from "../client/team";
import { useMerchantAppLogin } from "./merchant-app";
import * as teamCss from "./team.module.css";

const enum TeamTab {
  CUSTOMER_SUPPORT = "customer-support",
  GENERAL = "general",
  RETURNS_PACKAGE_PROTECTION = "returns-package-protection",
  ORDERS = "orders",
  OUTBOUND_LABELS = "outbound-labels",
  CUSTOMER_ACCOUNTS = "customer-accounts",
  MARKETING = "marketing",
  WARRANTIES = "warranties",
  CONVERSION = "conversion",
}

const teamTabs = [
  TeamTab.GENERAL,
  TeamTab.CUSTOMER_SUPPORT,
  TeamTab.ORDERS,
  TeamTab.OUTBOUND_LABELS,
  TeamTab.RETURNS_PACKAGE_PROTECTION,
  TeamTab.WARRANTIES,
  TeamTab.CUSTOMER_ACCOUNTS,
  TeamTab.MARKETING,
  TeamTab.CONVERSION,
];

const teamTabLabel: Record<TeamTab, string> = {
  [TeamTab.CUSTOMER_SUPPORT]: "Customer Support",
  [TeamTab.GENERAL]: "General",
  [TeamTab.ORDERS]: "Orders",
  [TeamTab.OUTBOUND_LABELS]: "Outbound Labels",
  [TeamTab.RETURNS_PACKAGE_PROTECTION]: "Returns / Package Protection",
  [TeamTab.CUSTOMER_ACCOUNTS]: "Customer Accounts",
  [TeamTab.MARKETING]: "Marketing",
  [TeamTab.WARRANTIES]: "Warranties",
  [TeamTab.CONVERSION]: "Conversion",
};

export const TeamContext = createContext<Team | undefined>(undefined);
export const LoadTeamContext = createContext<(() => void) | undefined>(
  undefined,
);

export const TeamPage = memo(function TeamPage({
  breadcrumb,
}: {
  breadcrumb: BreadcrumbSlot;
}) {
  const client = useRequiredContext(RedoAdminClientContext);

  const path = useResolvedPath("");
  const match = useMatch({ path: `${path.pathname}/:child`, end: false });

  const params = useParams();
  const teamId = params.teamId!;

  const [teamLoad, loadTeam] = useTriggerLoad((signal) =>
    getTeam(client, { teamId, signal }),
  );

  const [splitLoad, loadSplit] = useTriggerLoad((signal) =>
    getSplits(client, { teamId, signal }),
  );

  useBreadcrumb(breadcrumb, teamLoad.value?.name || "");

  useEffect(() => {
    loadTeam();
    loadSplit();
  }, [teamId]);

  const navigate = useNavigate();

  const actions0 = useContext(Action0PortalContext);

  if (!match || !teamLoad.value) {
    return null;
  }

  return (
    <>
      {actions0 &&
        teamLoad.value &&
        createPortal(
          <Actions show>
            <TreatmentsAction splits={splitLoad.value} />
            <MerchantAppAction team={teamLoad.value} />
          </Actions>,
          actions0,
        )}

      <div className={teamCss.container}>
        <TabsPortal>
          <RedoHorizontalTabs
            drawBottomLine={false}
            pl="none"
            selectedTab={match.params.child as TeamTab}
            setSelectedTab={(option) => navigate(option)}
            tabs={teamTabs.map((tab) => ({
              label: teamTabLabel[tab],
              value: tab,
              key: tab,
            }))}
          />
        </TabsPortal>
        <TeamContext.Provider value={teamLoad.value}>
          <div className={teamCss.container}>
            <LoadTeamContext.Provider value={loadTeam}>
              <RouterOutletFade />
            </LoadTeamContext.Provider>
          </div>
        </TeamContext.Provider>
      </div>
    </>
  );
});

const MerchantAppAction = memo(function MerchantAppAction({
  team,
}: {
  team: Team;
}) {
  const merchantAppLogin = useMerchantAppLogin();

  const handleClick = useHandler(() => {
    merchantAppLogin(team._id);
  });

  return (
    <Button
      className={teamCss.actionButton}
      onClick={handleClick}
      theme={ButtonTheme.OUTLINED}
    >
      <span>Merchant Dashboard</span>{" "}
      <span className={teamCss.icon}>
        <ExternalLinkIcon />
      </span>
    </Button>
  );
});

const TreatmentsAction = memo(function TreatmentsAction({
  splits,
}: {
  splits: PopulatedSplit[] | undefined;
}) {
  const navigate = useNavigate();

  const hasActiveSplit = splits?.some((split) => split.active);

  return (
    <Button
      className={teamCss.actionButton}
      onClick={() => navigate("split")}
      theme={ButtonTheme.OUTLINED}
    >
      {hasActiveSplit && <CheckCircle className={teamCss.splitStatus} />}
      <span>A/B Testing</span>
    </Button>
  );
});
